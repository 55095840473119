import React, { useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Paper, Select, Stack, Typography } from "@mui/material";
import { useLocale } from "../../context/LocaleContext";
import { Link } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";

export default function StatisticItem({ data, link, linkName }) {
	const [duration, setDuration] = useState('daily');
	const { themeObj } = useTheme();

	return (
		<Paper elevation={2} sx={{ padding: "10px 10px", position: "relative", backgroundColor: themeObj.secondary }}>
			<Grid container alignItems="center" flexDirection="row" justifyContent="space-between">
				<Grid item xs={6}>
					<Grid container>
						{data.map((d, i) =>
							<Grid key={i} item xs={6}>
								<Stack alignItems="center">
									<Typography fontWeight={500} fontSize="0.9rem" color={themeObj.title}>{d.value}</Typography>
									<Typography fontSize="0.9rem" color={themeObj.subtitle}>{d.name}</Typography>
								</Stack>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid item xs={1}></Grid>
				<Grid item xs={5}>
					<Stack alignItems="center" gap={1}>
						<Select
							value={duration}
							onChange={(e) => setDuration(e.target.value)}
							size="small"
							fullWidth
							sx={{
								fontSize: "0.7rem",
								color: themeObj.title,
								'& fieldset': { borderColor: themeObj.subtitle },
								'& .Mui-focused fieldset': { borderColor: themeObj.primary },
								'& .MuiSelect-icon': {
									color: themeObj.title
								}
							}}
							MenuProps={{
								sx: {
									'& .MuiMenu-paper': {
										backgroundColor: themeObj.card,
									},
									'& .MuiMenu-list': {
										p: 0
									}
								}
							}}
						>
							<MenuItem sx={{ fontSize: "0.7rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="daily">Daily</MenuItem>
							<MenuItem sx={{ fontSize: "0.7rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="weekly">Weekly</MenuItem>
							<MenuItem sx={{ fontSize: "0.7rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} value="monthly">Monthly</MenuItem>
						</Select>
						<Link style={{ textDecoration: "none", color: themeObj.primary }} to={link}>{linkName} &gt;</Link>
					</Stack>
				</Grid>
			</Grid>
		</Paper>
	);
}