import React, { useEffect } from "react";
import { useState, useRef } from "react";
import { useLocale } from "../../context/LocaleContext";
import { useRedirection } from "../../context/RedirectionContext";
import TransactionSearch from "./TransactionSearch";
import { useTheme } from "../../context/ThemeContext";
import { viewTransactions } from "../../apis/transactionApi";

const PAGESIZE = 30;

function groupByDate(transactions) {
	if (!Array.isArray(transactions)) {
		return {};
	}
	const sortedTransactions = transactions?.sort((a, b) => new Date(b.transaction_date) - new Date(a.transaction_date));
	return sortedTransactions?.reduce((groups, transaction) => {
		const date = new Date(transaction.transaction_date).toLocaleDateString('en-IN');
		if (!groups[date]) {
			groups[date] = [];
		}
		groups[date].push(transaction);
		return groups;
	}, {});
}

// function stringToUnixTime(dateStr, addOne = false) {
// 	// add one for mitigating timezone differences
// 	if (dateStr.toLocaleLowerCase() === 'dd/mm/yyyy') return null;
// 	const arr = dateStr.split('/');
// 	return new Date(arr[2], arr[1] - 1, String(Number(arr[0]) + (addOne ? 1 : 0))).getTime();
// }

export default function TransactionsPage() {
	const { redirectionData, setRedirection } = useRedirection();
	const start = useRef(null);
	const end = useRef(null);
	const [search, setSearch] = useState('');
	const [types, setTypes] = useState('');

	const [renderedData, setRenderedData] = useState();
	const [loaded, setLoaded] = useState(false);
	const [groupedTransactions, setGroupedTransactions] = useState({});

	const [page, setPage] = useState(1);
	const [hasNext, setHasNext] = useState(false);
	const [noData, setNoData] = useState(false);

	const resetStates = () => {
		// setSearch('');
		// setTypes('');
		// start.current.value = null;
		// end.current.value = null;
	}

	const redirectionSearch = (date, type) => {
		setPage(1);
		const getData = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/transaction/search_transaction/MUM1957MH/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					body: JSON.stringify({
						page: 1,
						page_size: PAGESIZE,
						text: search,
						transaction_type: type[0],
						transaction_date_start: date || undefined,
						transaction_date_end: end.current?.value || undefined,
					})
				}
			)
			if (response.ok) {
				const d = await response.json();
				if (d.status === "success") {
					const data = d.data.map(x => { return { ...x, risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok' } })
					setRenderedData(data);
					setGroupedTransactions(groupByDate(data));
					setNoData(false);
					setHasNext(d.page.has_next);
				}
				else {
					setRenderedData([]);
					setGroupedTransactions(groupByDate([]));
					setNoData(true);
					setHasNext(false);
				}
			}
			setLoaded(true);
			resetStates();
		}
		setLoaded(false);
		getData();
		setRedirection({});
	}

	const voiceSearch = (searchStr) => {
		setPage(1);
		const getData = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/transaction/search_transaction/MUM1957MH/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					body: JSON.stringify({
						page: 1,
						page_size: PAGESIZE,
						text: searchStr,
						transaction_type: types,
						transaction_date_start: start.current?.value || undefined,
						transaction_date_end: end.current?.value || undefined,
						audio: true
					})
				}
			)
			if (response.ok) {
				const d = await response.json();
				if (d.status === "success") {
					const data = d.data.map(x => { return { ...x, risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok' } })
					setRenderedData(data);
					setGroupedTransactions(groupByDate(data));
					setNoData(false);
					setHasNext(d.page.has_next);
				}
				else {
					setRenderedData([]);
					setGroupedTransactions(groupByDate([]));
					setNoData(true);
					setHasNext(false);
				}
			}
			setLoaded(true);
			resetStates();
		}
		setLoaded(false);
		getData();
		setRedirection({});
	}

	const doApiSearch = (type = types) => {
		setPage(1);
		console.log(type);
		const getData = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/transaction/search_transaction/MUM1957MH/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					body: JSON.stringify({
						page: 1,
						page_size: PAGESIZE,
						text: search,
						transaction_type: type,
						transaction_date_start: start.current?.value || undefined,
						transaction_date_end: end.current?.value || undefined,
					})
				}
			)
			if (response.ok) {
				const d = await response.json();
				if (d.status === "success") {
					const data = d.data.map(x => { return { ...x, risk: x.quantity < x.understock_qt ? 'understock' : x.quantity > x.overstock_qt ? 'overstock' : 'ok' } })
					setRenderedData(data);
					setGroupedTransactions(groupByDate(data));
					setNoData(false);
					setHasNext(d.page.has_next);
				}
				else {
					setRenderedData([]);
					setGroupedTransactions(groupByDate([]));
					setNoData(true);
					setHasNext(false);
				}
			}
			setLoaded(true);
			resetStates();
		}
		setLoaded(false);
		getData();
		setRedirection({});
	}

	const doSearch = (type = types) => {
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
				type: type,
			};
			if (end.current?.value) {
				const endDate = end.current?.value.split('/')
				options.endDate = new Date(endDate[2], endDate[1], endDate[0]).toISOString();
			}
			if (start.current?.value) {
				const startDate = start.current?.value.split('/')
				options.startDate = new Date(startDate[2], startDate[1], startDate[0]).toISOString();
			}
			console.log(options);
			const response = await viewTransactions(options)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
				setRenderedData(data.transactions);
				setGroupedTransactions(groupByDate(data.transactions));
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setGroupedTransactions(groupByDate([]));
				setHasNext(false);
				setNoData(true);
			}
		}
		getData();
	}

	const pageSearch = (page) => {
		const getData = async () => {
			const options = {
				page: page,
				pageSize: PAGESIZE,
			};
			const response = await viewTransactions(options)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
				setRenderedData(data.transactions);
				setGroupedTransactions(groupByDate(data.transactions));
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setGroupedTransactions(groupByDate([]));
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		setLoaded(false);
		getData();
		setRedirection({});
		window.scrollTo(0, 0);
	}

	const handleTypeChange = (newType) => {
		if (types === newType) {
			setTypes('');
		}
		else {
			setTypes(newType);
		}
	}

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			setPage(1);
			const options = {
				page: 1,
				pageSize: PAGESIZE,
			};
			const response = await viewTransactions(options)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
				setRenderedData(data.transactions);
				setGroupedTransactions(groupByDate(data.transactions));
				setNoData(false);
				setHasNext(data.pagination.has_next);
			}
			else {
				console.error(response.message);
				setRenderedData([]);
				setGroupedTransactions(groupByDate([]));
				setHasNext(false);
				setNoData(true);
			}
			setLoaded(true);
		}
		console.log(redirectionData);
		if (redirectionData && redirectionData.path === "/transactions") {
			const date = redirectionData.data.duration ? (new Date(Date.now() - Number(redirectionData.data.duration * 1000 * 60 * 60 * 24)).toLocaleString("en-IN")).split(",")[0] : null;
			console.log(date);
			setTypes(redirectionData.data.filters[0]);
			redirectionSearch(date, redirectionData.data.filters);
		}
		else {
			getData();
		}
	}, [])

	useEffect(() => {
		if (redirectionData && redirectionData.path === "/transactions") {
			const date = redirectionData.data.duration ? (new Date(Date.now() - Number(redirectionData.data.duration * 1000 * 60 * 60 * 24)).toLocaleString("en-IN")).split(",")[0] : null;
			console.log(redirectionData.data.filters)
			setTypes(redirectionData.data.filters[0]);
			redirectionSearch(date, redirectionData.data.filters);
		}
	}, [redirectionData]);

	useEffect(() => {
		if (loaded) {
			console.log(page)
			setLoaded(false);
			pageSearch(page);
		}
	}, [page]);

	return (
		<>
			<TransactionSearch
				loaded={loaded}
				page={page}
				setPage={setPage}
				groupedTransactions={groupedTransactions}
				renderedData={renderedData}
				doSearch={doSearch}
				searchCallback={doApiSearch}
				hasNext={hasNext}
				onClick={(e) => console.log(e)}
				types={types}
				setTypes={handleTypeChange}
				noData={noData}
			/>
		</>
	)
}