import React, { useEffect, useReducer, useState, useRef } from "react";
import { Scanner } from "../components/Barcode/Scanner";
import Selector from "../components/Barcode/Selector";
import TransactionSelector from "../components/Barcode/TransactionSelector";
import ItemForm from "../components/Barcode/ItemForm";
import { TransitionGroup } from "react-transition-group";
import { Collapse, Typography, Box, Divider } from "@mui/material";
import TransactionForm from "../components/Barcode/TransactionForm";
import PaymentDialog from "../components/Barcode/PaymentDialog";
import { useRedirection } from "../context/RedirectionContext";
import SaveDialog from "../components/Barcode/SaveDialog";

// Reducer function
const formArrayReducer = (state, action) => {
	switch (action.type) {
		case "ADD_ENTRY":
			return [{ id: action.payload.id, values: { barcode_id: action.payload.id }, validated: false }, ...state];
		case "UPDATE_ENTRY":
			return state.map((form) =>
				form.id === action.payload.id ? { ...form, values: { ...form.values, ...action.payload.values }, validated: true } : form
			);
		case "UPDATE_PARTIAL_ENTRY":
			return state.map((form) =>
				form.id === action.payload.id ? { ...form, values: { ...form.values, ...action.payload.values } } : form
			);
		case "DELETE_ENTRY":
			return state.filter((form) => form.id !== action.payload.id);
		case "RETRY_SCAN":
			return state.slice(1);
		case "RESET":
			return [];
		default:
			return state;
	}
};

function BarcodePage() {
	const [formArray, dispatch] = useReducer(formArrayReducer, []);
	const formItemRefs = useRef([]); // ref for the forms as an array of objects

	const [total, setTotal] = useState(0);
	const [barcodeId, setBarcodeId] = useState("");
	const [capture, setCapture] = useState();
	const [action, setAction] = useState("transaction");
	const [transaction, setTransaction] = useState("sale");
	const [itemAction, setItemAction] = useState("add");
	const [feedback, setFeedback] = useState({});
	const [paymentMethod, setPaymentMethod] = useState(false);

	const { redirectionData, setRedirection } = useRedirection();
	const [txnId, setTxnId] = useState("");

	const onResult = (value, autoComplete) => {
		if (!autoComplete) {
			setCapture(false);
		}
		dispatch({ type: "ADD_ENTRY", payload: { id: value } });
	};

	const handleFormSubmit = (id, values) => {
		console.log("Form submitted:", values);
		dispatch({ type: "UPDATE_ENTRY", payload: { id, values } });
		setCapture(true);
	};

	const retryScan = () => {
		dispatch({ type: 'RETRY_SCAN' });
		setCapture(true);
	};

	const handleSubmit = () => {
		const urlMap = {
			add: '/items/add_item',
			edit: '/items/update_item',
		};

		const submitData = async (formData) => {
			const data = formData.map(form => form.values);
			let response;
			if (action === "transaction") {
				response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}/transaction/add_transaction/MUM1957MH/`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							"Access-Control-Allow-Origin": "*",
						},
						body: JSON.stringify({
							items: data,
							transaction_type: transaction,
							payment_method: paymentMethod ? 'online' : 'cash'
						}),
					}
				);
			}
			else {
				response = await fetch(
					`${process.env.REACT_APP_BACKEND_URL}${urlMap[itemAction]}/MUM1957MH/`,
					{
						method: itemAction === 'add' ? 'POST' : 'PUT',
						headers: {
							'Content-Type': 'application/json',
							"Access-Control-Allow-Origin": "*",
						},
						body: JSON.stringify(data),
					}
				);
			}
			if (response.ok) {
				const res = await response.json();
				if (res.status === "success") {
					if (action === 'transaction') {
						setTxnId(res.transaction_id);
					}
					console.log("success ig... give proper message ;-;");
					setFeedback({ success: true, message: 'Successfully Updated' });
					// dispatch({ type: 'RESET' });
				}
				else {
					setFeedback({ success: false, message: 'Something Went Wrong' });
					console.log("something went wrong, please try again");
				}
			}
		};
		if (formArray.every(item => item.validated)) {
			console.log("Success");
			console.log(formArray);
			submitData(formArray);
		}
		else {
			console.log("Error");
		}
	};

	const createPayment = (callback) => {
		formItemRefs.current.forEach(({ ref }) => ref?.requestSubmit());
		console.log(formArray, formItemRefs);
		setTimeout(() => {
			if (formArray.every(item => item.validated)) {
				callback();
			}
		}, 100); // Delay to ensure form validation state is updated
	};

	useEffect(() => {
		// initial page load
		setCapture(true);
		return () => {
			setCapture(false);
		};
	}, []);

	useEffect(() => {
		// this is for when action is changed
		dispatch({ type: 'RESET' });
		setCapture(true);
	}, [action]);

	const handleDelete = (id) => {
		formItemRefs.current = formItemRefs.current.filter(item => item.id !== id); // Remove the reference object from array
		dispatch({ type: "DELETE_ENTRY", payload: { id } });
	};

	const handleSave = (callback) => {
		formItemRefs.current.forEach(({ ref }) => ref?.requestSubmit());
		setTimeout(() => {
			if (formArray.every(item => item.validated)) {
				handleSubmit();
				callback();
			}
		}, 100)
	}

	const resetForm = () => {
		dispatch({ type: "RESET" });
		setTotal(0);
		setCapture(true);
	}

	useEffect(() => {
		if (redirectionData && redirectionData.path === "/barcode") {
			console.log(redirectionData);
			if (redirectionData.data.transactionType) {
				setTransaction(redirectionData.data.transactionType);
			}
			if (redirectionData.data.itemList.length !== 0) {
				redirectionData.data.itemList.forEach(item => {
					dispatch({ type: "ADD_ENTRY", payload: { id: item.item_id } });
					dispatch({ type: "UPDATE_PARTIAL_ENTRY", payload: { id: item.item_id, values: { quantity: item.quantity } } });
				})
			}
		}
	}, [redirectionData])

	return (
		<>
			<Selector action={action} setAction={setAction} />
			<Scanner capture={capture} onResult={onResult} newScan={() => setCapture(true)} />

			<Collapse in={action === "transaction"}>
				<TransactionSelector transaction={transaction} setTransaction={setTransaction} />
			</Collapse>

			{formArray.length !== 0 &&
				<Box sx={{ padding: '10px', marginBottom: '20px' }}>
					<TransitionGroup>
						{formArray.map((form) => (
							<Collapse key={form.id}>
								{action === "item" ?
									<ItemForm
										barcode={form.id}
										ref={el => {
											formItemRefs.current = formItemRefs.current.filter(item => item.id !== form.id); // Remove existing reference if it exists
											formItemRefs.current.push({ id: form.id, ref: el }); // Add new reference
										}}
										onFormSubmit={(values) => handleFormSubmit(form.id, values)}
										onDelete={() => handleDelete(form.id)}
										setItemAction={setItemAction}
									/>
									:
									<TransactionForm
										barcode={form.id}
										ref={el => {
											formItemRefs.current = formItemRefs.current.filter(item => item.id !== form.id); // Remove existing reference if it exists
											formItemRefs.current.push({ id: form.id, ref: el }); // Add new reference
										}}
										onFormSubmit={(values) => handleFormSubmit(form.id, values)}
										onDelete={() => handleDelete(form.id)}
										type={transaction}
										subtotal={total}
										setTotal={setTotal}
										quantity={form.values.quantity}
									/>
								}
							</Collapse>
						))}
					</TransitionGroup>
					<Divider />
					{action === "transaction" &&
						<Typography>
							Total : {total}
						</Typography>
					}
				</Box>
			}

			{formArray.length !== 0 && (
				action === 'transaction' ?
					<PaymentDialog total={total} onOpen={createPayment} submit={handleSubmit} txnId={txnId} resetForm={resetForm} feedback={feedback}setPaymentMethod={setPaymentMethod} />
					:
					<SaveDialog fullWidth onOpen={handleSave} onClose={resetForm} feedback={feedback} />
			)}
		</>
	);
}

export default BarcodePage;
