import React, { createContext, useState, useContext, useEffect } from 'react';

const locales = {
	'en': require('../data/locales/en.json'),
	'bn': require('../data/locales/bn.json'),
	'hi': require('../data/locales/hi.json'),
	'mr': require('../data/locales/mr.json'),
	'te': require('../data/locales/te.json'),
	'ta': require('../data/locales/ta.json'),
	'gu': require('../data/locales/gu.json'),
};

const LocaleContext = createContext();

const useLocale = () => {
	const context = useContext(LocaleContext);
	if (!context) {
		throw new Error('useLocale must be used within a LocaleProvider');
	}
	return context;
};

const LocaleProvider = ({ children }) => {
	const [locale, setLocale] = useState('en'); // Default locale is English
	const [messages, setMessages] = useState(locales['en']); // Default messages for English

	useEffect(() => {
		setMessages(locales[locale]);
	}, [locale]);

	const changeLocale = (val) => {
		// currently set to toggle between the 3
		if (val) setLocale(val);
		else if (locale === "en") {
			setLocale("bn");
		}
		else if (locale === "bn") {
			setLocale("hi")
		} 
		else if (locale ==='hi') {
			setLocale("en")
		}
	};

	const value = {
		locale,
		changeLocale,
		messages
	};

	return (
		<LocaleContext.Provider value={value}>
			{children}
		</LocaleContext.Provider>
	);
};

export { LocaleProvider, useLocale };
