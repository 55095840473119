import React, { useRef, useState } from "react";
import { Button, Stack } from '@mui/material'
import TransactionDetails from "../components/Sales/TransactionDetails";
import CustomerDetails from "../components/Sales/CustomerDetails";
import PaymentDetails from "../components/Sales/PaymentDetails";
import ItemsTable from "../components/Sales/ItemsTable";
import { useTheme } from "../context/ThemeContext";
import { toast } from "react-toastify";
import { useAuth } from "../context/AuthContext";
import ItemAutocomplete from "../components/Common/ItemAutocomplete";
import { createSale } from "../apis/transactionApi";
import BarcodeScanner from "../components/Common/BarcodeScanner";

export default function SalesPage() {
	const paymentDetails = useRef({});
	const customerDetails = useRef({});
	const transactionDetails = useRef({});
	const itemsTableRef = useRef(null);
	const barcodeRef = useRef(null);
	const formRefs = useRef({ transaction: null, customer: null, payment: null });
	const [amount, setAmount] = useState({});
	const { themeObj } = useTheme();
	const { mongoUser, store} = useAuth();

	const addItem = (data) => {
		console.log(data);
		itemsTableRef.current.addItem(data);
	}

	const onFormSubmit = (form, values) => {
		switch (form) {
			case "payment":
				paymentDetails.current = values;
				break;
			case "customer":
				customerDetails.current = values;
				break;
			default:
				transactionDetails.current = values;
				break;
		}
	}

	const handleRefUpdate = (formName, ref) => {
		formRefs.current[formName] = ref;
	};

	const handleSubmit = async () => {
		// clearing the values
		let items = [];
		paymentDetails.current = {};
		transactionDetails.current = {};

		if (itemsTableRef.current && itemsTableRef.current.handleSubmit) {
			items = await itemsTableRef.current.handleSubmit();
			itemsTableRef.current.hide();
		}

		Object.values(formRefs.current).forEach(async (ref) => { ref.hide(); await ref.requestSubmit() });

		setTimeout(async () => {
			var flag = false;
			var error = []

			if (items.length === 0) {
				itemsTableRef.current.show();
				error = "Item List cannot be empty.";
				flag = true;
			}
			else if (!items.every(i => i)) {
				itemsTableRef.current.show();
				error = "Some items details are missing.";
				flag = true;
			}
			if (flag) {
				console.log("some error");
				toast.error(error);
				return;
			}

			// do transaction code here ig, after the tests
			const transactionsObj = {
				store_id: store.store_id,
				transaction_type: "sale",
				...transactionDetails.current,
				items,
				payments: {...paymentDetails.current},
				seller_details: {
					uid: mongoUser._id,
					store_gstin: store.store_gstin,
					store_name: store.store_name,
					name: mongoUser.name,
					phone: mongoUser.phone,
					email: mongoUser.email,
				},
				...amount,
				total_amount: amount.sub_total + amount.charges_amount + amount.cgst_amount + amount.sgst_amount - amount.discount_amount,
				customer_details: {...customerDetails.current},
				status: paymentDetails.current.status
			}

			console.log(transactionsObj);
			const response = await createSale(transactionsObj);
			if (!response.success) {
				toast.error(JSON.stringify(response.message));
				return;
			}
			toast.success("Transaction successful");
		}, 500) // add delay, or else it doesnt reflect value for some reason aaaaaa
	}


	return (
		<Stack gap={1}>
			<BarcodeScanner ref={barcodeRef} onResult={addItem}/>
			<ItemAutocomplete onResult={addItem} />
			<ItemsTable amount={amount} onAmountChange={(amount) => setAmount(amount)} ref={itemsTableRef} />
			<CustomerDetails ref={(ref) => handleRefUpdate("customer", ref)} onFormSubmit={onFormSubmit}/>
			<PaymentDetails updateCharges={(val) => setAmount(prev => {return { ...prev, charges_amount: val}})} amount={amount} ref={(ref) => handleRefUpdate("payment", ref)} onFormSubmit={onFormSubmit} />
			<TransactionDetails amount={amount} ref={(ref) => handleRefUpdate("transaction", ref)} onFormSubmit={onFormSubmit}/><Button
				onClick={handleSubmit}
				sx={{
					marginBottom: "10px",
					backgroundColor: themeObj.primary,
					color: themeObj.title,
					'&:hover': {
						backgroundColor: themeObj.primary_hover,
					}
				}}
			>
				Do transaction
			</Button>
		</Stack>
	)
}