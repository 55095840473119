import React from "react";
import { Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useLocale } from "../../context/LocaleContext";
import { CustomChip, config } from "./CustomChips";
import { useTheme } from "../../context/ThemeContext";

export default function StatisticDisplay({ action, setAction, data }) {
	const { messages } = useLocale();
	const { themeObj } = useTheme();

	const handleChange = (event, newAction) => {
		document.activeElement?.blur && document.activeElement.blur();
		if (newAction === null) {
			setAction('');
		}
		else {
			setAction(newAction);
		}
	}

	return (
		<ToggleButtonGroup
			value={action}
			exclusive
			onChange={handleChange}
			fullWidth
			sx={{ marginBottom: "10px" }}
		>
			<Paper sx={{ width: "100%", flexDirection: "row", display: "flex", backgroundColor: themeObj.card }}>
				<ToggleButton value="sale" sx={{ padding: "7px", width: "50%", textTransform: "none", '&.Mui-selected': { backgroundColor: themeObj.secondary }, '&.Mui-selected:hover': { backgroundColor: themeObj.secondary }}}>
					<Stack flexDirection="column" gap={0.5} width="100%">
						<CustomChip
							icon={React.cloneElement(config["sale"], { color: "inherit" })}
							label="Sale"
							c={themeObj.chip.green}
							bgc={themeObj.chip.green_background}
						/>
						<Typography fontSize="13px" color={themeObj.subtitle} fontWeight={500}>₹ 1600</Typography>
					</Stack>
				</ToggleButton>
				{/* <ToggleButton value="return" sx={{ padding: "7px", width: "33.33%", textTransform: "none", '&.Mui-selected': { backgroundColor: themeObj.secondary }, '&.Mui-selected:hover': { backgroundColor: themeObj.secondary }}}>
					<Stack flexDirection="column" gap={0.5} width="100%">
						<CustomChip
							icon={React.cloneElement(config["return"], { color: "inherit" })}
							label="Return"
							c={themeObj.chip.red}
							bgc={themeObj.chip.red_background}
						/>
						<Typography fontSize="13px" color={themeObj.subtitle} fontWeight={500}>₹ 1090</Typography>
					</Stack>
				</ToggleButton> */}
				<ToggleButton value="purchase" sx={{ padding: "7px", width: "50%", textTransform: "none", '&.Mui-selected': { backgroundColor: themeObj.secondary }, '&.Mui-selected:hover': { backgroundColor: themeObj.secondary }}}>
					<Stack flexDirection="column" gap={0.5} width="100%">
						<CustomChip
							icon={React.cloneElement(config["purchase"], { color: "inherit" })}
							label="Purchase"
							c={themeObj.chip.yellow}
							bgc={themeObj.chip.yellow_background}
						/>
						<Typography fontSize="13px" color={themeObj.subtitle} fontWeight={500}>₹ 540</Typography>
					</Stack>
				</ToggleButton>
			</Paper>
		</ToggleButtonGroup>
	);
}