import React from "react";
import { Paper, Stack, ToggleButton as Tb, styled, ToggleButtonGroup, Typography } from "@mui/material";
import { useLocale } from "../../context/LocaleContext";
import { useTheme } from "../../context/ThemeContext";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';

const ToggleButton = styled(Tb)(({theme, c, bgc, selc, selbgc}) => ({
	backgroundColor: bgc,
	color: c,
	'&.Mui-selected': {
		backgroundColor: selbgc,
		color: selc,
	},
	'&.Mui-selected:hover': {
		backgroundColor: selbgc,
		color: selc,
	},
}));

export default function Selector({ action, setAction }) {
	const { messages } = useLocale();
	const { themeObj } = useTheme();

	const handleChange = (event, newAction) => {
		if (newAction !== null) {
			setAction(newAction)
		}
	}

	const colorOpts = {
		c: themeObj.subtitle,
		selc: themeObj.primary,
		bgc: themeObj.card,
		selbgc: themeObj.secondary
	}

	return (
		<ToggleButtonGroup
			value={action}
			exclusive
			onChange={handleChange}
			fullWidth
			sx={{ marginBottom: "10px" }}
		>
		<Paper elevation={3} sx={{ width: "100%", flexDirection: "row", display: "flex", backgroundColor: themeObj.background }}>
			<ToggleButton {...colorOpts} value="transaction" sx={{ padding: "7px" }}>
				<Stack flexDirection="row" gap={1} justifyContent="center" alignItems="center">
					<CurrencyExchangeRoundedIcon fontSize="small"/>
					<Typography fontSize="0.7rem">{messages.barcode.transaction}</Typography>
				</Stack>
			</ToggleButton>
			<ToggleButton {...colorOpts} value="item" sx={{ padding: "7px" }}>
				<Stack flexDirection="row" gap={1} justifyContent="center" alignItems="center">
					<CategoryOutlinedIcon fontSize="small"/>
					<Typography fontSize="0.7rem">{messages.barcode.items}</Typography>
				</Stack>
			</ToggleButton>
		</Paper>
		</ToggleButtonGroup>
	);
}