import React from 'react';
import { Typography, Box, CircularProgress } from '@mui/material';
import { useTheme } from '../../context/ThemeContext';
import { useLocale } from '../../context/LocaleContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export default function Success({ success, processing }) {
	const { themeObj } = useTheme();
	const { messages } = useLocale();
	const naviagte = useNavigate();

	if (success) {
		setTimeout(() => naviagte('/profile'), 3000);
	}

	return (
		<Box sx={{ width: "100%" }}>
			<AnimatePresence>
				<motion.div
					initial={{ opacity: 0, y: 20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.5, delay: 0.3 }}
					exit={{ opacity: 0, y: -20 }}
					key="logo"
				>
					{processing ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: "column", gap: 2 }}>
							<CircularProgress sx={{ color: themeObj.primary }} size="4rem" />
							<Typography gutterBottom sx={{ color: themeObj.subtitle }} variant="h6" textAlign="center">
								{messages.onboarding.processing}
							</Typography>
						</Box>
					) : success ? (
						<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: "column", gap: 2 }}>
							<Typography sx={{ color: themeObj.title }} variant="h4" textAlign="center">
								{messages.onboarding.success}
							</Typography>
							<motion.div
								initial={{ opacity: 0, y: 20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ delay: 0.8, duration: 0.3 }}
							>
								<Typography gutterBottom sx={{ color: themeObj.subtitle }} variant="body" textAlign="center">
									{messages.onboarding.redirect}
								</Typography>
							</motion.div>

						</Box>
					) : (
						<Typography gutterBottom sx={{ color: themeObj.title }} variant="h4" textAlign="center">
							{messages.onboarding.failure}
						</Typography>
					)}
				</motion.div>
			</AnimatePresence>
		</Box>
	);
}