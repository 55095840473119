import React, { useState, useRef, useEffect } from 'react';
import { Button, styled, IconButton, Box, CircularProgress } from '@mui/material';
import { Player } from '@lordicon/react';
const PAUSEPLAYBTN = require('../../data/playpause.json');

const CustomButton = styled(Button)(() => ({
	backgroundColor: '#F5B60B',
	color: '#F5B60B',
	borderRadius: '50%',
	boxShadow: 'none',
	width: 'min-content',
	'&:hover': {
		backgroundColor: '#F6AE09',
	},
	'&.Mui-disabled': {
		backgroundColor: '#D1D1D1',
		color: '#212121',
	}
}));

export default function AnimatedPausePlay({ onClick, isPlaying, progress, size=50 }) {
	const pausePlayRef = useRef(null);
	const closeRefButton = useRef(null);
	const closeRef = useRef(null);

	useEffect(() => {
		pausePlayRef.current?.playFromBeginning();
	}, [isPlaying])

	return (
		<Box position="relative" sx={{ width: "min-content", display: "flex", justifyContent: "center" }}>
			<IconButton
				onClick={onClick}
				sx={{
					// position: 'absolute',
					// top: '50%',
					// left: '50%',
					// transform: 'translate(-50%, -50%)',
					zIndex: 1,
				}}
			>
				<Player
					ref={pausePlayRef}
					icon={PAUSEPLAYBTN}
					state={isPlaying ? 'morph-play-pause' : 'morph-pause-play'}
					size={size}
				/>
			</IconButton>
			<CircularProgress
				variant="determinate"
				value={progress}
				size={size*1.2}
				thickness={2}
				sx={{
					color: isPlaying ? '#F5B60B' : 'transparent',
					position: 'absolute',
					top: 4,
					
				}}
			/>
		</Box>
	);
}
