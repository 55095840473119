import { Stack, TextField, Typography, Button, MenuItem, Box } from "@mui/material";
import React, { forwardRef, useImperativeHandle } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from '../../context/ThemeContext';
import { useLocale } from '../../context/LocaleContext';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import krayaLogoWhite from '../../assets/krayaLogoWhite.jpg'

const categories = ['Grocery', 'Electronics', 'Fashion', 'Home Decor', 'Other'];

const ShopDetails = forwardRef(({ onFormSubmit, initial, handleNext }, ref) => {
	const { themeObj } = useTheme();
	const { messages } = useLocale();

	const validationSchema = Yup.object({
		store_name: Yup.string().required(messages.input.validations.required),
		store_category: Yup.string().required(messages.input.validations.required),
		// store_gstin: Yup.string()
		// 	.matches(
		// 		/^[0-9]{2}[A-Za-z]{4}[0-9]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
		// 		messages.input.validations.invalid
		// 	)
		// 	.required(messages.input.validations.required),
		store_gstin: Yup.string(),
		upi_id: Yup.string(),
	});

	const formik = useFormik({
		initialValues: {
			store_name: initial?.store_name ?? '',
			store_category: initial?.store_category ?? '',
			store_gstin: initial?.store_gstin ?? '',
			upi_id: initial?.upi_id ?? '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit("shop", values);
		},
	});

	useImperativeHandle(ref, () => ({
		requestSubmit: async () => {
			await formik.submitForm();
		}
	}));

	return (
		<form
			component="form"
			onSubmit={formik.handleSubmit}
			style={{ flexGrow: 1, margin: 'auto' }}
		>
			<Stack gap={2}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem', flexDirection: "column" }}>
					<img src={krayaLogoWhite} width={100} alt="Kraya Logo" style={{ borderRadius: '50%' }} />
					<Typography gutterBottom sx={{ color: themeObj.title, fontSize: "1.5rem" }} variant="h5">
						{messages.onboarding.storeDetails}
					</Typography>
				</Box>
				<TextField
					fullWidth
					id="store_name"
					name="store_name"
					label={messages.input.fields.storeName + '*'}
					value={formik.values.store_name ?? ''}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.store_name && Boolean(formik.errors.store_name)}
					helperText={formik.touched.store_name && formik.errors.store_name}
					sx={{
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
				/>
				<TextField
					fullWidth
					id="store_category"
					name="store_category"
					label={messages.input.fields.storeCategory + '*'}
					value={formik.values.store_category ?? ''}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.store_category && Boolean(formik.errors.store_category)}
					helperText={formik.touched.store_category && formik.errors.store_category}
					sx={{
						fontSize: "0.8rem",
						color: themeObj.title,
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						},
						'& .MuiSelect-icon': {
							color: themeObj.title
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
					SelectProps={{
						MenuProps: {
							sx: {
								'& .MuiMenu-paper': {
									backgroundColor: themeObj.card,
								},
								'& .MuiMenu-list': {
									p: 0
								}
							}
						}
					}}
					select
				>
					{categories.map(category => (
						<MenuItem key={category} value={category} sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} >
							{category}
						</MenuItem>
					))}
				</TextField>
				<TextField
					fullWidth
					id="store_gstin"
					name="store_gstin"
					label={messages.input.fields.gstin}
					value={formik.values.store_gstin ?? ''}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.store_gstin && Boolean(formik.errors.store_gstin)}
					helperText={formik.touched.store_gstin && formik.errors.store_gstin}
					sx={{
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
				/>
				<TextField
					fullWidth
					id="upi_id"
					name="upi_id"
					label={messages.input.fields.upi}
					value={formik.values.upi_id ?? ''}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.upi_id && Boolean(formik.errors.upi_id)}
					helperText={formik.touched.upi_id && formik.errors.upi_id}
					sx={{
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
				/>
				<Button
					fullWidth
					variant="text"
					sx={{
						p: 1.5,
						color: themeObj.primary,
						boxShadow: 'none',
						'&:hover': {
							backgroundColor: "transparent",
						},
					}}
					onClick={handleNext}
					endIcon={<ArrowForwardRoundedIcon htmlColor={themeObj.primary} />}
				>
					{messages.common.next}
				</Button>
			</Stack>
		</form>
	)
});

export default ShopDetails;
