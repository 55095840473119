import React, { useEffect, useRef, useState } from "react";
import { InputBase, IconButton, Box, Paper, Button } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
// import { useAudioRecorder } from 'react-audio-voice-recorder';
import useAudioRecorder from "../../hooks/useAudioRecorder";
import { Player } from '@lordicon/react';
import { useTheme } from "../../context/ThemeContext";
const MICROPHONE = require('../../data/microphone.json');
const groqApi = require('../../apis/groqApi');

export default function SearchBar({ placeholder, searchString, setSearchString, doApiSearch, voiceSearch }) {
	const [state, setState] = useState('in-reveal');
	const [loaded, setLoaded] = useState(false);
	const { isRecording, startRecording, stopRecording, recordingBlob } = useAudioRecorder();
	const { themeObj } = useTheme();

	const playerRef = useRef(null);
	useEffect(() => {
		playerRef.current?.playFromBeginning();
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (state === 'in-reveal' && loaded) {
			setState('loop-recording');
			playerRef.current?.playFromBeginning();
		}
		if (state === 'loop-recording' && loaded) {
			setState('in-reveal');
			playerRef.current?.play();
		}
	}, [isRecording]);

	useEffect(() => {
		if (recordingBlob) {
			groqApi.transcribe(recordingBlob)
				.then(data => {
					if (data) {
						console.log(data.trim());
						setSearchString(data.trim());
						voiceSearch(data.trim());
					}
				})
				.catch(err => {
					console.log(`HTTP error: ${err}`);
				});
		}
	}, [recordingBlob]);

	return (
		<Paper
			elevation={3}
			sx={{
				display: "flex",
				borderRadius: "5px",
				justifyContent: "center",
				alignItems: "center",
				background: themeObj.card,
				color: themeObj.title,
			}}
		>
			<InputBase
				sx={{ flex: 1, ml: 2, color: themeObj.title }}
				placeholder={placeholder}
				inputProps={{ 'aria-label': 'search' }}
				value={searchString}
				onChange={(e) => setSearchString(e.target.value.trim())}
				onKeyUp={(e) => {
					if (e.key.toLowerCase() === 'enter') {
						doApiSearch()
					}
				}}
			/>
			<IconButton onClick={()=> doApiSearch()}>
				<SearchRoundedIcon fontSize="large" htmlColor={themeObj.primary} />
			</IconButton>
			<Button
				onClick={isRecording ? stopRecording : startRecording}
				sx={{
					color: "black",
					borderRadius: '50%',
					width: 'min-content'
				}}
			>
				<Player
					ref={playerRef}
					icon={MICROPHONE}
					state={state}
					size={40}
					colorize={themeObj.primary}
					onComplete={() => { if (state === 'loop-recording') playerRef.current?.playFromBeginning() }}
				/>
			</Button>
			{/* <MicIcon fontSize="large" sx={{ ml: 2 }}/>
				<CameraAltOutlinedIcon fontSize="large" sx={{ ml: 2 }}/> */}
		</Paper>
	)
}