import React, { useEffect, useState, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { Button, Paper, IconButton, Divider, Autocomplete, TextField, CircularProgress, useMediaQuery, Typography } from "@mui/material";
import AUDIOURL from "../../assets/sounds/scanner.mp3";
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import useAudioRecorder from '../../hooks/useAudioRecorder'
import { Player } from "@lordicon/react";
import { useTheme } from "../../context/ThemeContext";
import { BorderColor } from "@mui/icons-material";

const MICROPHONE = require('../../data/microphone.json');
const langaugeApi = require('../../apis/languageApi');

let html5QrCode;
let typingTimeout;

export const Scanner = ({ onResult, capture, newScan }) => {
	const match = useMediaQuery('(min-width: 850px')
	const [barcodeId, setBarcodeId] = useState("");
	const [options, setOptions] = useState([]);
	const [audio] = useState(new Audio(AUDIOURL));
	const [loaded, setLoaded] = useState(false);
	const [permissionDenied, setPermissionDenied] = useState(false);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const { isRecording, startRecording, stopRecording, recordingBlob } = useAudioRecorder();
	const [state, setState] = useState('in-reveal');
	const { themeObj } = useTheme();

	const playerRef = useRef(null);

	const handleNewScan = () => {
		setInputValue('');
		newScan();
	}

	useEffect(() => {
		playerRef.current?.playFromBeginning();
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (state === 'in-reveal' && loaded) {
			setState('loop-recording');
			playerRef.current?.playFromBeginning();
		}
		if (state === 'loop-recording' && loaded) {
			setState('in-reveal');
			playerRef.current?.play();
		}
	}, [isRecording]);

	useEffect(() => {
		if (recordingBlob) {
			const reader = new FileReader();
			reader.readAsDataURL(recordingBlob);
			reader.onloadend = async function () {
				let base64String = reader.result;
				langaugeApi.synthesizeAudioTranslate(base64String.slice(base64String.indexOf(',') + 1), 'en', 'en')
					.then(response => {
						response.final_output?.replace(/[.,/#!$%^&*;:{}=_`~()]/g, "");
						console.log(response);
						setInputValue(response.final_output);
						handleInputChange(null, response.final_output);
						setOpen(true);
					})
					.catch(err => {
						console.log(`HTTP error: ${err}`);
					});
			}
		}
	}, [recordingBlob]);

	useEffect(() => {
		// html5QrCode = {}
		html5QrCode = new Html5Qrcode(
			"reader",
		);
		const oldRegion = document.getElementById("qr-shaded-region");
		oldRegion && oldRegion.remove();

		setLoaded(true);
		return () => {
			if (html5QrCode.getState() !== 1) {
				html5QrCode.stop().catch(err => { console.log(err.message) });
			}
		}
	}, []);

	useEffect(() => {
		if (capture) {
			if (html5QrCode.getState() === 3) {
				html5QrCode.resume();
			}
			else {
				handleClickAdvanced();
			}
		}
	}, [loaded, capture]);

	const qrCodeSuccessCallback = (decodedText, decodedResult, autoComplete = false) => {
		audio.play();
		document.activeElement?.blur && document.activeElement.blur();
		if (!autoComplete) {
			html5QrCode.pause()
		}
		onResult(decodedText, autoComplete);
	};

	const handleClickAdvanced = () => {
		html5QrCode
			.start(
				{ facingMode: "environment" },
				{ fps: 30, qrbox: { width: 250, height: 100 }, aspectRatio: 0.3 },
				qrCodeSuccessCallback
			)
			.catch((err) => {
				console.error(err);
				setPermissionDenied(true);
			});
	};

	const handleStop = () => {
		try {
			html5QrCode.pause().catch((err) => {
				console.log(err.message);
			});
		} catch (err) {
			console.log(err);
		}
	};

	const handleAskPermission = () => {
		setPermissionDenied(false);
		Html5Qrcode.getCameras()
			.then(cameraId => {
				handleClickAdvanced();
			})
			.catch((err) => {
				setPermissionDenied(true);
			});
	};

	const fetchOptions = (input) => {
		setLoading(true);
		fetch(
			`${process.env.REACT_APP_BACKEND_URL}/stocks/search_inventory/MUM1957MH/`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"Access-Control-Allow-Origin": "*",
				},
				body: JSON.stringify({
					page: 1,
					page_size: 10,
					text: input
				})
			}
		)
			.then(response => response.json())
			.then(data => {
				if (data.status === 'success') {
					setOptions(data.data);
				}
				else {
					setOptions([]);
				}
				setLoading(false);
			})
			.catch(err => {
				console.error(err);
				setOptions([]);
				setLoading(false);
			});
	};

	const handleInputChange = (event, newInputValue) => {
		setInputValue(newInputValue);
		setBarcodeId(newInputValue);
		clearTimeout(typingTimeout);
		typingTimeout = setTimeout(() => {
			if (newInputValue) {
				fetchOptions(newInputValue);
			}
		}, 500); // 1 second delay
	};

	return (
		<>
			<div style={{ position: "sticky" }}>
				<div style={{ width: "100%", height: "100%", zIndex: 10, backgroundColor: "rgba(0, 0, 0, 0.8)", position: "absolute", display: capture ? 'none' : 'flex', justifyContent: "center", alignItems: "center" }}>
					<Button style={{ width: "100%", height: "100%", color: "#F5B60B", fontWeight: 400, fontSize: "1.2rem" }} onClick={handleNewScan} variant="text" disableElevation disableFocusRipple disableRipple >New Scan</Button>
				</div>
				{permissionDenied &&
					<div style={{ width: "100%", height: "100%", zIndex: 10, display: 'flex', justifyContent: "center", alignItems: "center", fontSize: "1.5rem", fontWeight: 500, textWrap: "wrap" }}>
						Permission Denied, Please allow camera to use this feature
					</div>
				}
				<div id="reader"></div>
			</div>
			<Divider sx={{ marginTop: "5px", marginBottom: "5px" }}><Typography fontSize="0.8rem" fontWeight={400}>OR</Typography></Divider>
			<Paper
				elevation={3}
				sx={{
					display: "flex",
					borderRadius: "5px",
					padding: "5px",
					justifyContent: "center",
					alignItems: "center",
					marginBottom: "5px",
					background: themeObj.card,
					color: themeObj.title,
				}}
			>
				<Autocomplete
					freeSolo
					size="small"
					fullWidth
					options={options}
					getOptionLabel={(option) => option.item_name}
					isOptionEqualToValue={(option, value) => option.barcode_id === value.barcode_id}
					inputValue={inputValue}
					onInputChange={handleInputChange}
					onChange={(event, newValue) => {
						if (newValue) {
							qrCodeSuccessCallback(newValue.barcode_id, null, true);
						}
					}}
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => setOpen(false)}
					loading={loading}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Enter Item"
							fullWidth
							sx={{
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								}
							}}
							InputLabelProps={{
								sx: {
									color: themeObj.title,
									fontSize: '0.8rem',
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								...params.InputProps,
								sx: {
									color: themeObj.title,
									fontSize: '0.8rem',
								},
								endAdornment: (
									<>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
				/>
				<IconButton disabled={!capture} onClick={() => onResult(barcodeId)}>
					<ArrowForwardRoundedIcon fontSize="medium" htmlColor={themeObj.primary} />
				</IconButton>
				<Button
					onClick={isRecording ? stopRecording : startRecording}
					sx={{
						p: 0,
						pr: 1,
						color: "black",
						borderRadius: '50%',
						minWidth: "unset",
						width: 'min-content'
					}}
				>
					<Player
						ref={playerRef}
						icon={MICROPHONE}
						state={state}
						size={30}
						colorize={themeObj.primary}
						onComplete={() => { if (state === 'loop-recording') playerRef.current?.playFromBeginning() }}
					/>
				</Button>
			</Paper>
		</>
	);
};
