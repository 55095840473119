import React, { useReducer, useState, useEffect } from "react";
import { useLocale } from "../context/LocaleContext";
import { useRedirection } from "../context/RedirectionContext";
import { Collapse, Typography, Box } from "@mui/material";
import InstaSellItem from "../components/InstaSell/InstaSellItem";
import { TransitionGroup } from "react-transition-group";
import InventoryItemLoader from "../components/Inventory/InventoryItemLoader";
import SearchBar from "../components/Common/SearchBar";
import { viewInventoryBySales } from "../apis/inventoryApi";
import { addInstantSellTransaction } from "../apis/instantSaleApi";
import Cart from "../components/InstaSell/Cart";
import { searchItems } from "../apis/itemApi";
import { toast } from "react-toastify";

const PAGESIZE = 100;

const cartReducer = (state, action) => {
	switch (action.type) {
		case 'add':
			const existingItem = state.find(item => item.item_id === action.item.item_id);
			const { current_quantity, sales_metrics, ...itemData } = action.item;
			if (current_quantity <= 0) return state;
			if (existingItem) {
				return state.map(item =>
					item.item_id === action.item.item_id
						? { ...item, quantity: item.quantity + 1, total_price: item.total_price + item.selling_price }
						: item
				);
			} else {
				return [...state, { ...itemData, quantity: 1, total_price: action.item.selling_price }];
			}
		case 'remove':
			const existingItemToRemove = state.find(item => item.item_id === action.item.item_id);
			if (existingItemToRemove.quantity === 1) {
				return state.filter(item => item.item_id !== action.item.item_id);
			}
			return state.map(item =>
				item.item_id === action.item.item_id
					? { ...item, quantity: item.quantity - 1, total_price: item.total_price - item.selling_price }
					: item
			);
		case 'delete':
			return state.filter(item => item.item_id !== action.item.item_id);
		case 'clear':
			return [];
		default:
			return state;
	}
}

export default function InstaSellPage() {
	const { redirectionData, setRedirection } = useRedirection();
	const { messages } = useLocale();
	const [loaded, setLoaded] = useState(false);
	const [renderedData, setRenderedData] = useState();
	const [search, setSearch] = useState('');
	const [cart, dispatch] = useReducer(cartReducer, []);

	const addToCart = (item) => {
		if (renderedData.filter(i => i.item_id === item.item_id)[0].current_quantity <= 0) return;
		dispatch({ type: 'add', item });
		setRenderedData(curr => curr.map(i => i.item_id === item.item_id && i.current_quantity > 0 ? { ...i, current_quantity: i.current_quantity - 1 } : i));
	}

	const deleteFromCart = (item) => {
		dispatch({ type: 'delete', item });
		setRenderedData(curr => curr.map(i => i.item_id === item.item_id ? { ...i, current_quantity: i.current_quantity + item.quantity } : i));
	}

	const removeSingleItem = (item) => {
		dispatch({ type: 'remove', item });
		setRenderedData(curr => curr.map(i => i.item_id === item.item_id && i.current_quantity > 0 ? { ...i, current_quantity: i.current_quantity + 1 } : i));
	}

	const clearCart = () => {
		dispatch({ type: 'clear' });
		cart.forEach(item => {
			setRenderedData(curr => curr.map(i => i.item_id === item.item_id ? { ...i, current_quantity: i.current_quantity + item.quantity } : i));
		});
	}

	const makeTransaction = () => {
		console.log(cart);
		const getData = async () => {
			const response = await addInstantSellTransaction(cart)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
			}
			else {
				console.error(response.message);
			}
		}
		getData();
		clearCart();
	}


	const makeSingleTransaction = (itemId, callback) => {
		if (renderedData.filter(item => item.item_id === itemId)[0].current_quantity <= 0) return callback();
		const txnData = renderedData.filter(item => item.item_id === itemId)[0];
		console.log(txnData);
		const itemData = [{
			item_id: txnData.item_id,
			item_name: txnData.item_name,
			quantity: 1,
			total_price: txnData.selling_price
		}];
		const getData = async () => {
			const response = await addInstantSellTransaction(itemData)
			if (response.success) {
				const data = response.data.data;
				console.log(data);
				setRenderedData(curr => curr.map(item => item.item_id === itemId && item.current_quantity > 0 ? { ...item, current_quantity: item.current_quantity - 1 } : item));
				toast.success('Item Sold', { autoClose: 2000, theme: "dark" });
			}
			else {
				console.error(response.message);
				toast.error('Error Selling Item', { autoClose: 2000, theme: "dark" });
			}
			callback();
		}
		getData();
	}

	const redirectionSearch = (risk, page = 1) => {
		const getData = async () => {
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/stocks/search_inventory/MUM1957MH/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"Access-Control-Allow-Origin": "*",
					},
					body: JSON.stringify({
						page: page,
						page_size: PAGESIZE,
						text: search || undefined,
					})
				}
			)
			if (response.ok) {
				const d = await response.json();
				if (d.status === "success") {
					const data = d.data;
					console.log(data);
					setRenderedData(data);
				}
				else {
					setRenderedData([]);
				}
				console.log(d);
			}
		}
		getData();
		setRedirection({});
	}

	const voiceSearch = (searchStr, page = 1) => {
		const getData = async () => {
			const response = await searchItems({ query: searchStr, page: page, perPage: PAGESIZE });
			if (response.success) {
				console.log(response.data.data);
				setRenderedData(response.data.data.items.map(x => {
					const { item_id, item_name, quantity, cost_price, selling_price, barcode_id, item_category, qt_type, tags, image_url } = x;
					return { item_id, item_name, current_quantity: quantity, cost_price, selling_price, barcode_id, item_category, qt_type, tags, image_url };
				}));
			} else {
				console.error(response.message);
				setRenderedData([]);
			}
		}
		getData();
		setRedirection({});
	}

	const doApiSearch = (page = 1) => {
		console.log(search);
		const getData = async () => {
			const response = await searchItems({ query: search, page: page, perPage: PAGESIZE });
			if (response.success) {
				console.log(response.data.data);
				setRenderedData(response.data.data.items.map(x => {
					const { item_id, item_name, quantity, cost_price, selling_price, barcode_id, item_category, qt_type, tags, image_url } = x;
					return { item_id, item_name, current_quantity: quantity, cost_price, selling_price, barcode_id, item_category, qt_type, tags, image_url };
				}));
			} else {
				console.error(response.message);
				setRenderedData([]);
			}
		}
		getData();
		setRedirection({});
	}

	useEffect(() => {
		setLoaded(false);
		const getData = async () => {
			try {
				const options = {
					page: 1,
					pageSize: PAGESIZE,
				};

				const response = await viewInventoryBySales(options);

				if (response.success) {
					setRenderedData(response.data.data.items);
				} else {
					console.error(response.message);
				}
			} catch (e) {
				console.log(e);
				setRenderedData([]);
			}
			setLoaded(true);
		};
		console.log(redirectionData);
		if (redirectionData && redirectionData.path === "/insta-sell") {
			console.log(redirectionData.data.filters);
			redirectionSearch(redirectionData.data.filters);
		}
		else {
			console.log('here');
			getData();
		}
		setLoaded(true);
	}, [])

	useEffect(() => {
		if (redirectionData && redirectionData.path === "/insta-sell" && loaded) {
			console.log(redirectionData.data.filters);
			redirectionSearch(redirectionData.data.filters);
		}
	}, [redirectionData, loaded])

	return (
		<>
			<SearchBar searchString={search} setSearchString={setSearch} placeholder={messages.inventory.search.placeholder} doApiSearch={() => doApiSearch()} voiceSearch={(search) => voiceSearch(search)} />
			<Cart makeTransaction={makeTransaction} cart={cart} addItem={addToCart} deleteFromCart={deleteFromCart} removeSingleItem={removeSingleItem} clearCart={clearCart} />
			<Box sx={{ height: "10px" }}></Box>
			<TransitionGroup>
				{loaded ?
					(renderedData?.length !== 0 ?
						renderedData?.map(e => (
							<Collapse key={e.item_id}>
								<InstaSellItem {...e} makeTransaction={(callback) => makeSingleTransaction(e.item_id, callback)} addToCart={() => { addToCart(e); toast.success('Item Added to Cart', { autoClose: 1000, theme: "dark" });}} />
							</Collapse>
						))
						:
						<Typography fullWidth textAlign="center" variant="h5" style={{ marginTop: '20px' }}>Data Not Found</Typography>
					)
					:
					Array.from({ length: 20 }, (e, i) =>
						<Collapse key={i}>
							<InventoryItemLoader />
						</Collapse>
					)
				}
			</TransitionGroup>
			<Box sx={{ height: "56px" }}></Box>
		</>
	)
}