import React, { useState, forwardRef, useImperativeHandle } from "react";
import { TextField, Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { useTheme } from "../../context/ThemeContext";
import { customerSearchTypesense } from '../../apis/typesenseApi';

let typingTimeout;

const CustomerAutocomplete = forwardRef(({ onResult }, ref) => {
	const [options, setOptions] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const { themeObj } = useTheme();
	const [disabled, setDisabled] = useState(false);

	useImperativeHandle(ref, () => ({
		enable: () => setDisabled(false),
		disable: () => setDisabled(true),
		clear: () => setInputValue('')
	}));

	const handleInputChange = (event, newInputValue) => {
		onResult({ type: newInputValue === '' ? 'CLEAR' : 'TYPED', customer_name: newInputValue, customer: {} });
		setInputValue(newInputValue);
		setLoading(true);
		clearTimeout(typingTimeout);
		typingTimeout = setTimeout(async () => {
			if (newInputValue) {
				try {
					const searchResults = await customerSearchTypesense({ query: newInputValue });
					setOptions(searchResults.customers);
				} catch (error) {
					console.error("Search error:", error);
					setOptions([]);
				}
			} else {
				setOptions([]);
			}
			setLoading(false);
		}, 500);
	};

	return (
		<Autocomplete
			freeSolo
			fullWidth
			size="small"
			disabled={disabled}
			options={options}
			getOptionLabel={(option) => option.name}
			isOptionEqualToValue={(option, value) => option.id === value.id}
			inputValue={inputValue}
			onInputChange={handleInputChange}
			onChange={(event, newValue) => {
				if (newValue && typeof newValue !== 'string') {
					onResult({ type: 'TYPESENSE', customer: newValue });
				}
			}}
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			loading={loading}
			slotProps={{
				popper: {
					sx: { backgroundColor: themeObj.secondary, color: themeObj.title }
				},
				paper: {
					sx: { backgroundColor: themeObj.secondary, color: themeObj.title }
				},
				listbox: {
					sx: { color: themeObj.title }
				},
				clearIndicator: {
					sx: { color: themeObj.primary }
				}
			}}
			renderOption={(props, option) => {
				const { key, ...otherProps } = props;
				return (
					<li key={key} {...otherProps}>
						<div style={{ width: '100%' }}>
							<Typography variant="body1" style={{ color: themeObj.title }}>
								{option.name}
							</Typography>
						</div>
					</li>
				);
			}}
			sx={{
				'& .MuiAutocomplete-loading': {
					color: themeObj.title,
				}
			}}
			renderInput={(params) => (
				<TextField
					{...params}
					label="Enter Customer Name"
					fullWidth
					sx={{
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						...params.InputProps,
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
				/>
			)}
		/>
	);
});

export default CustomerAutocomplete;