import React, { useState, useEffect, useRef } from "react";
import { useRedirection } from "../../context/RedirectionContext";
import useAudioRecorder from "../../hooks/useAudioRecorder";
import AnimatedMic from "./AnimatedMic";
import AnimatedPausePlay from "./AnimatedPlayPause";
const langaugeApi = require('../../apis/languageApi');

export default function VoiceSearch ({ setData, processing, setProcessing }) {
	const { redirectionData } = useRedirection();

	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);

	const [loaded, setLoaded] = useState(false);
	const { isRecording, startRecording, stopRecording, recordingBlob } = useAudioRecorder();
	const audioRef = useRef();

	const togglePlayPause = () => {
		const audio = audioRef.current;
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play();
		}
		setIsPlaying(!isPlaying);
	};

	const handleRecord = () => {
		if (isRecording) {
			stopRecording();
		}
		else {
			startRecording();
		}
	}

	useEffect(() => {
		if (recordingBlob) {
			setProcessing((p) => true);
			console.log(isRecording, recordingBlob);

			audioRef.current = new Audio(URL.createObjectURL(recordingBlob));
			const audio = audioRef.current;

			const updateProgress = () => {
				setProgress((audio.currentTime / audio.duration) * 100);
			};

			audio.addEventListener('timeupdate', updateProgress);
			audio.addEventListener('ended', () => setIsPlaying(false));

			const reader = new FileReader();
			reader.readAsDataURL(recordingBlob);
			reader.onloadend = async function () {
				let base64String = reader.result;
				const before = Date.now();
				const res = await langaugeApi.synthesizeAudioTranslate(base64String.slice(base64String.indexOf(',') + 1), 'hi', 'en'); // can change
				const afterRes = Date.now();
				const en = await langaugeApi.synthesizeAudioTranslate(base64String.slice(base64String.indexOf(',') + 1), 'en', 'en');
				const afterEn = Date.now();
				console.log(`HI-EN: ${(afterRes - before) / 1000}\nEN-EN: ${(afterEn - afterRes) / 1000}`);
				console.log(res, en);
				fetch(
					`${process.env.REACT_APP_BACKEND_URL}/audio/all_in_one/MUM1957MH/hi`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							"Access-Control-Allow-Origin": "*",
						},
						body: JSON.stringify({ audio_content: { en: res.final_output, hi: en.final_output }, initial_page: redirectionData.initialPage })
					}
				).then(response => {
					if (!response.ok) {
						console.log(`HTTP error! status: ${response.status}`);
						setProcessing(false);
					}
					return response.json();
				}).then(data => {
					console.log(data);
					setData(data);
					setProcessing(false);
				}).catch(err => {
					console.log(err);
					setProcessing(false);
				})
			}
			return () => {
				audio.removeEventListener('timeupdate', updateProgress);
				audio.removeEventListener('ended', () => setIsPlaying(false));
			};
		}
	}, [recordingBlob]);

	return (
		processing ?
			<AnimatedPausePlay progress={progress} isPlaying={isPlaying} onClick={togglePlayPause}/>
			:
			<AnimatedMic isRecording={isRecording} onClick={handleRecord}/>
	);
}