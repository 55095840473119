import { Stack, Grid, TextField, Paper, Typography, Divider, InputAdornment, IconButton } from "@mui/material";
import React, { forwardRef, useEffect } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTheme } from "../../context/ThemeContext";
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";

const validationSchema = Yup.object({
	item_name: Yup.string().required('Item Name is required'),
	quantity: Yup.number().typeError('Quantity must be a number').positive('Quantity must be a positive number'),
	cost_price: Yup.number().typeError('Cost Price must be a number').required('Cost Price is required').positive('Cost Price must be a positive number'),
	gst_rate: Yup.number().typeError('GST must be a number'),
	discount_amount: Yup.number().typeError('Discount must be a number'),
});

const TableItem = forwardRef(({ deleteItem, onFormSubmit, onValuesChange, data }, ref) => {
	const { themeObj } = useTheme();
	const formik = useFormik({
		initialValues: {
			item_id: data?.item_id ?? '',
			item_name: data?.item_name ?? '',
			quantity: '',
			cost_price: data?.cost_price ?? '',
			selling_price: data?.selling_price ?? '',
			barcode_id: data?.barcode_id ?? '',
			hsn_sac_code: data?.hsn_sac_code ?? '',
			item_category: data?.item_category ?? '',
			qt_type: data?.qt_type ?? 'pieces',
			returned_quantity: data?.returned_quantity ?? 0,
			tags: data?.tags ?? [],
			image_url: data?.image_url ?? '',
			discount_amount: '0',
			gst_rate: '0',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit({
				...values,
				cost_price: Number(values.cost_price),
				quantity: Number(values.quantity || 0),
				gst_rate: Number(values.gst_rate),
				gst_amount: Number(values.gst_rate) * Number(values.quantity) * Number(values.cost_price) / 100,
				discount_amount: Number(values.discount_amount),
				subtotal: Number(values.quantity) * Number(values.cost_price),
				total_price: Number(values.quantity) * Number(values.cost_price) * (1 + Number(values.gst_rate) * 0.01) - Number(values.discount_amount)
			});
		},
	});
	useEffect(() => {
		if (!formik.errors.quantity) {
			onValuesChange({
				quantity: Number(formik.values.quantity || 0),
				cost_price: Number(formik.values.cost_price),
				discount_amount: Number(formik.values.discount_amount),
				gst_rate: Number(formik.values.gst_rate)
			});
		}
	}, [formik.values]);

	return (
		<form
			ref={ref}
			component="form"
			onSubmit={formik.handleSubmit}
			sx={{ flexGrow: 1, margin: 'auto' }}
		>
			<Paper sx={{ borderRadius: 1, padding: "10px", backgroundColor: "transparent" }}>
				{/* {!formik.isValid && JSON.stringify(formik.errors)} */}
				<Stack gap={1.5}>
					<Grid container>
						<Grid item xs>
							<TextField
								fullWidth
								size="small"
								id="item_name"
								name="item_name"
								label="Item Name"
								value={formik.values.item_name ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.item_name && Boolean(formik.errors.item_name)}
								helperText={formik.touched.item_name && formik.errors.item_name}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									readOnly: true,
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
						</Grid>
						<Grid item sx={{ width: "48px", boxSizing: "border-box", display: "flex", alignItems: "center", justifyContent: "center" }}>
							<IconButton onClick={deleteItem} sx={{ p: 0 }}>
								<ClearRoundedIcon fontSize="large" color="error" />
							</IconButton>
						</Grid>
					</Grid>

					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								id="quantity"
								name="quantity"
								label="Quantity*"
								value={formik.values.quantity ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.quantity && Boolean(formik.errors.quantity)}
								helperText={formik.touched.quantity && formik.errors.quantity}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								id="cost_price"
								name="cost_price"
								label="Cost Price*"
								value={formik.values.cost_price ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.cost_price && Boolean(formik.errors.cost_price)}
								helperText={formik.touched.cost_price && formik.errors.cost_price}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography sx={{ lineHeight: "unset" }} color={themeObj.subtitle}>₹</Typography></InputAdornment>,
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
						</Grid>
					</Grid>

					<Grid container spacing={1}>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								id="gst_rate"
								name="gst_rate"
								label="GST Rate"
								value={formik.values.gst_rate ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.gst_rate && Boolean(formik.errors.gst_rate)}
								helperText={formik.touched.gst_rate && formik.errors.gst_rate}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									startAdornment: <InputAdornment position="start"><PercentRoundedIcon fontSize="small" htmlColor={themeObj.primary} /></InputAdornment>,
									endAdornment:
										<InputAdornment position="end">
											<Stack flexDirection="row" height="20px" alignItems="center" gap={1}>
												<Divider sx={{ borderColor: themeObj.subtitle }} orientation="vertical" />
												<Typography noWrap color={themeObj.subtitle}>₹ {((Number(formik.values.gst_rate) * Number(formik.values.quantity) * Number(formik.values.cost_price) * 0.01 || 0)).toFixed(2) ?? ''}</Typography>
											</Stack>
										</InputAdornment>
									,
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								size="small"
								id="discount_amount"
								name="discount_amount"
								label="Discount"
								value={formik.values.discount_amount ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.discount_amount && Boolean(formik.errors.discount_amount)}
								helperText={formik.touched.discount_amount && formik.errors.discount_amount}
								sx={{
									'& .MuiOutlinedInput-root': {
										'&.Mui-focused fieldset': {
											borderColor: themeObj.primary,
										},
										'& fieldset': {
											borderColor: themeObj.subtitle,
										},
									}
								}}
								InputLabelProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.title,
										'&.Mui-focused': {
											color: themeObj.primary
										},
									}
								}}
								InputProps={{
									startAdornment: <InputAdornment position="start"><Typography color={themeObj.primary} fontWeight={600}>₹</Typography></InputAdornment>,
									sx: {
										fontSize: '1rem',
										color: themeObj.title
									},
								}}
							/>
						</Grid>
					</Grid>
				</Stack>
			</Paper>
		</form>
	)
});

export default TableItem;