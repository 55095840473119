import { Chip, styled } from '@mui/material';

import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

const CustomChip = styled(Chip)(({ theme, c, bgc }) => ({
	fontWeight: 600,
	fontSize: 14,
	minWidth: 120,
	color: c,
	border: '1px solid',
	borderColor: "transparent",
	backgroundColor: bgc,
}));

const CustomChipSelected = styled(Chip)(({ theme, c }) => ({
	fontWeight: 600,
	fontSize: 14,
	minWidth: 120,
	color: c,
	border: '1px solid',
	backgroundColor: 'transparent',
}));

const config = {
	ok: <CheckRoundedIcon fontSize="small" />,
	understock: <TrendingDownRoundedIcon fontSize="small" />,
	overstock: <TrendingUpRoundedIcon fontSize="small"/>,
}

export { CustomChip, CustomChipSelected, config }