import { Paper, Typography, TextField, Autocomplete, Chip, createFilterOptions } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "../../context/ThemeContext";

const filter = createFilterOptions();

export default function TagsEdit({ data, setVal }) {
	const { themeObj } = useTheme();
	const [opt, setOpt] = useState(['electronics', 'gadget', 'test']);
	return (
		<Autocomplete
			multiple
			size="small"
			options={opt}
			freeSolo
			onChange={(event, newValue) => {
				const temp = [];
				newValue.forEach(v => {
					if(!opt.includes(v)) temp.push(v);
				})
				setOpt([...opt, ...temp]);
				setVal(newValue);
			}}
			value={data}
			slotProps={{
				popper: {
					sx: { backgroundColor: themeObj.secondary, color: themeObj.title }
				},
				paper: {
					sx: { backgroundColor: themeObj.secondary, color: themeObj.title }
				},
				listbox: {
					sx: { color: themeObj.title }
				},
				clearIndicator: {
					sx: { color: themeObj.primary }
				}
			}}
			sx={{
				'& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
					p: 0
				},
				my: 0.5,
			}}
			filterOptions={(options, params) => {
				const filtered = filter(options, params);

				const { inputValue } = params;
				// Suggest the creation of a new value
				const isExisting = options.some((option) => inputValue === option);
				if (inputValue !== '' && !isExisting) {
					filtered.push(inputValue);
				}

				return filtered;
			}}
			renderTags={(value, getTagProps) =>
				value.map((option, index) => {
					const { key, ...tagProps } = getTagProps({ index });
					return (
						<Chip
							{...tagProps}
							color="warning"
							variant="outlined"
							size="small"
							key={key}
							label={option}
						/>
					);
				})
			}
			renderInput={(params) => (
				<TextField
					{...params}
					placeholder="Tags"
					InputProps={{
						...params.InputProps,
						sx: {
							color: themeObj.contrast,
							'& fieldset': {
								border: "none",
							},
							'& input': {
								p: 0
							}
						},
					}}
				/>
			)}
		/>
	);
}