import React, { useState } from "react";
import { Box, Paper, Typography, Stack, Collapse, Grid, Divider, Button, CircularProgress } from "@mui/material";
import { CustomChip, config } from './CustomChips'
import { useTheme } from "../../context/ThemeContext";
import { viewTransactionBill } from "../../apis/transactionApi";

function formatDate(dateString) {
	const date = new Date(dateString);

	const day = date.getDate().toString().padStart(2, '0');
	const month = date.toLocaleString('en-IN', { month: 'short' });
	const year = date.getFullYear();

	return `${day} ${month}, ${year}`;
}

export default function TransactionItem(props) {
	const { themeObj } = useTheme();
	const [processing, setProcessing] = useState(false);

	const handleOpenBill = async () => {
		const getData = async () => {
			const response = await viewTransactionBill(props.transaction_id)
			if (response.success) {
				console.log(response);
				window.open(response.data.bill_link, '_blank');
			}
			else {
				console.error(response.message);
			}
			setProcessing(false);
		}
		setProcessing(true);
		getData();
	};

	return (
		<Box sx={{ marginBottom: props.margin || "10px" }}>
			<Button variant="text" sx={{ color: 'revert', textTransform: 'none', width: '100%', padding: 0, borderRadius: '5px', border: 'none' }} onClick={props.onClick}>
				<Paper elevation={3} sx={{ width: '100%', padding: '10px 20px', backgroundColor: themeObj.secondary }}>
					<Stack flexDirection="row" alignContent="center" justifyContent="space-between" sx={{ height: '100%' }}gap={1}>
						<Stack flexDirection="column" justifyContent="space-between" sx={{ textOverflow: "ellipsis", overflow: "hidden" }}>
							<Typography noWrap textAlign="center" fontSize="1.2rem" color={themeObj.title}>{props.transaction_id}</Typography>
							<Typography textAlign="center" fontSize="0.9rem" color={themeObj.subtitle}>{formatDate(props.transaction_date)}</Typography>
						</Stack>
						<Stack flexDirection="column" alignContent="center" justifyContent="space-between">
							<CustomChip
								icon={React.cloneElement(config[props.transaction_type], { color: "inherit" })}
								label={props.label}
								c={props.transaction_type === 'sale' ? themeObj.chip.green : (props.transaction_type === 'return' ? themeObj.chip.red : themeObj.chip.yellow)}
								bgc={props.transaction_type === 'sale' ? themeObj.chip.green_background : (props.transaction_type === 'return' ? themeObj.chip.red_background : themeObj.chip.yellow_background)}
								mw={110}
							/>
							<Typography textAlign="center" fontSize="1rem" color={themeObj.subtitle} fontWeight={500}>₹ {Math.round(props.total_amount * 100)/100}</Typography>
						</Stack>
					</Stack>
				</Paper>
			</Button>
		</Box>
	)
}