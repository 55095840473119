import React, { useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Box, Button, Typography, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import CustomButton from '../Base/CustomButton';

// const pdfFile = require('C:/Users/ankan/Desktop/SDE CV.pdf');

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

export default function PdfPopup({ onOpen, onDownload, open, setOpen, link, loaded }) {
	// const [open, setOpen] = useState(false);
	// const [numPages, setNumPages] = useState(null);
	const dialogRef = useRef(null);
	// const [pageWidth, setPageWidth] = useState(0);
	// const [scale, setScale] = useState(1);

	// const handleClickOpen = () => {
	// 	// onOpen();
	// 	setOpen(true);
	// };

	// const handleClose = () => {
	// 	setOpen(false);
	// };

	// const handleDownload = () => {
	// 	onDownload();
	// };

	// const onDocumentLoadSuccess = ({ numPages }) => {
	// 	setNumPages(numPages);
	// };

	// const handleZoomIn = () => {
	// 	setScale(prevScale => prevScale + 0.2);
	// };

	// const handleZoomOut = () => {
	// 	setScale(prevScale => (prevScale - 0.2 > 0 ? prevScale - 0.2 : prevScale));
	// };

	// const handleFitToWidth = () => {
	// 	if (dialogRef.current) {
	// 		const newPageWidth = dialogRef.current.clientWidth * 0.8;
	// 		setScale(newPageWidth / (pageWidth / scale));
	// 	}
	// };

	// useEffect(() => {
	// 	if (dialogRef.current) {
	// 		setPageWidth(dialogRef.current.clientWidth * 0.8);
	// 	}
	// }, [open]);

	// useEffect(() => {
	// 	if (dialogRef.current) {
	// 		const newPageWidth = dialogRef.current.clientWidth * 0.8 * scale;
	// 		setPageWidth(newPageWidth);
	// 	}
	// }, [scale]);

	return (
		<Dialog
			ref={dialogRef}
			onClose={() => setOpen(false)}
			aria-labelledby="pdf-dialog"
			open={open}
			fullWidth
		// PaperProps={{
		// 	style: {
		// 		height: '90vh',
		// 	},
		// }}
		>
			<DialogTitle sx={{ m: 0, p: 2 }} id="pdf-dialog">
				PDF Viewer
				<IconButton
					aria-label="close"
					onClick={() => setOpen(false)}
					sx={{
						position: 'absolute',
						right: 12,
						top: 12,
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>

			{/* <DialogContent style={{ p: 0 }} >
					<Box display="flex" justifyContent="center" alignItems="center" sx={{ flexDirection: 'column' }}>
						<Box display="flex" justifyContent="center" alignItems="center" mt={2}>
							<Button onClick={handleZoomOut}>-</Button>
							<Typography variant="body1" mx={2}>{(scale * 100).toFixed(0)}%</Typography>
							<Button onClick={handleZoomIn}>+</Button>
							<Button onClick={handleFitToWidth} ml={2}>Fit to Width</Button>
						</Box>
						<Document file={pdfFile} onLoadSuccess={onDocumentLoadSuccess}>
							{[...Array(numPages).keys()].map(pageNumber => (
								<Page key={pageNumber} pageNumber={pageNumber + 1} width={pageWidth} scale={scale} />
							))}
						</Document>
					</Box>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDownload}>Download</Button>
					<Button onClick={() => setOpen(false)} color="primary">Close</Button>
				</DialogActions> */}

			<DialogContent dividers>
				{loaded ?
					<CustomButton fullWidth onClick={() => window.open(link, '_blank')}>Download PDF</CustomButton>
					:
					<CircularProgress />
				}
			</DialogContent>
		</Dialog>
	);
}
