import React, { useEffect, useState } from 'react';
import { Stack, Button, Box, Typography, Grid } from '@mui/material';
import google from '../../assets/google.png';
import computerBro from '../../assets/computer-bro.svg';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../context/ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import page1 from "../../assets/images/signin/page1.svg"
import page2 from "../../assets/images/signin/page2.svg"
import page3 from "../../assets/images/signin/page3.svg"

const carouselVariants = {
	initial: (direction) => ({
		x: direction > 0 ? '100%' : '-100%', // Start off-screen to the right or left
		opacity: 0,
	}),
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			opacity: { duration: 0.5 },
			x: { duration: 0.5 },
		},
	},
	exit: (direction) => ({
		x: direction > 0 ? '-100%' : '100%', // Exit to the left or right
		opacity: 0,
		transition: {
			opacity: { duration: 0.5 },
			x: { duration: 0.5 },
		},
	}),
};

const SignIn = () => {
	const { signIn } = useAuth();
	const { themeObj } = useTheme();
	const [page, setPage] = useState(1);
	const [direction, setDirection] = useState(1);

	const nextSlide = () => {
		setDirection(1);
		setPage((prev) => (prev + 1) % 3); // Assuming there are 3 pages
	};

	const prevSlide = () => {
		setDirection(-1);
		setPage((prev) => (prev - 1 + 3) % 3); // Wrap around to last slide if at the start
	};

	// Auto-play functionality
	useEffect(() => {
		const interval = setInterval(nextSlide, 2000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Grid container alignItems="center" justifyContent="center" height="100%" sx={{ marginTop: '50px' }} gap={8}>
			<Grid item xs={12} md={6}>
				<Box position="relative" overflow="hidden" width="100%" height="400px">
					<AnimatePresence initial={false} custom={direction}>
						<motion.div
							key={page}
							custom={direction}
							variants={carouselVariants}
							initial="initial"
							animate="enter"
							exit="exit"
							style={{
								position: 'absolute',
								width: '100%',
								height: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							{page === 0 && (
								<Stack justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
									<img src={page1} alt="page 1" style={{ width: '100%', height: '300px' }} />
									<Typography sx={{ fontSize: "1.2rem", color: themeObj.title }}>Elevate Your Shop Management</Typography>
								</Stack>
							)}
							{page === 1 && (
								<Stack justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
									<img src={page2} alt="page 2" style={{ width: '100%', height: '300px', transform: "scale(1.1)" }} />
									<Typography sx={{ fontSize: "1.2rem", color: themeObj.title }}>Smarter and Actionable Insights</Typography>
								</Stack>
							)}
							{page === 2 && (
								<Stack justifyContent="center" alignItems="center" gap={1} sx={{ width: '100%', height: '100%' }}>
									<img src={page3} alt="page 3" style={{ width: '100%', height: '300px', transform: "scale(1.25)" }} />
									<Typography sx={{ fontSize: "1.2rem", color: themeObj.title }}>Payments Redefined</Typography>
								</Stack>
							)}
						</motion.div>
					</AnimatePresence>
				</Box>
			</Grid>
			<Grid item xs={12} md={5} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: "90%" }}>
				<Button
					onClick={signIn}
					variant="contained"
					sx={{
						backgroundColor: themeObj.primary,
						padding: 0,
						borderRadius: '5px',
						outline: 'none',
						border: 'none',
						textTransform: 'none',
						width: "90%",
					}}
				>
					<Stack flexDirection="row" alignItems="center" width="100%">
						<Box
							sx={{
								backgroundColor: 'white',
								height: '55px',
								minWidth: '55px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								borderRadius: '5px',
							}}
						>
							<img width="35px" height="35px" src={google} alt="google sign in" />
						</Box>
						<Typography fontSize="1rem" fontWeight={500} color="white" textAlign="center" width="100%">
							Sign In with Google
						</Typography>
					</Stack>
				</Button>
			</Grid>
		</Grid>
	);
};

export default SignIn;
