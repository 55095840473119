import React, { useEffect, useState } from "react";
import { IconButton, Button, Box } from "@mui/material";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { useTheme } from "../../context/ThemeContext";

export default function Pagination({ hasNext, page, setPage }) {
	const { themeObj } = useTheme();
	return (
		<Box>
			<IconButton onClick={() => setPage(page - 1)} disabled={page === 1}><ArrowBackIosRoundedIcon htmlColor={themeObj.primary}/></IconButton>
			{page !== 1 && <Button sx={{ minWidth: 20, height: '40px', width: '40px', padding: 0, borderRadius: '50%', color: themeObj.subtitle }} onClick={() => setPage(page - 1)}>{page - 1}</Button>}
			<Button variant="filled" sx={{ minWidth: 20, height: '40px', width: '40px', padding: 0, borderRadius: '50%', backgroundColor: themeObj.primary, color: themeObj.title }}>{page}</Button>
			{hasNext && <Button sx={{ minWidth: 20, height: '40px', width: '40px', padding: 0, borderRadius: '50%', color: themeObj.subtitle }} onClick={() => setPage(page + 1)}>{page + 1}</Button>}
			<IconButton onClick={() => setPage(page + 1)} disabled={!hasNext}><ArrowForwardIosRoundedIcon htmlColor={themeObj.primary}/></IconButton>
		</Box>
	)
}