import React, { useEffect, useState, forwardRef, useRef } from 'react';
import { TextField, MenuItem, Box, Grid, Button, InputAdornment, Collapse, Paper, Typography, Stack, IconButton, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';

function fakeFetch(barcode) {
	const data = {
		item_id: "JG698H6",
		item_name: 'iPhone 13 Pro',
		quantity: '234',
		cost_price: '324',
		selling_price: '245',
		price: '234543',
	};
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				ok: true,
				json: () => data
			});
		}, 1000);
	});
}

const validationSchema = Yup.object({
	item_name: Yup.string().required('Item Name is required'),
	quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required').positive('Quantity must be a positive number'),
	price: Yup.number().typeError('Selling Price must be a number').required('Selling Price is required').positive('Selling Price must be a positive number'),
});

const TransactionForm = forwardRef(({ barcode, onFormSubmit, onDelete, type, setTotal, subtotal, quantity }, ref) => {
	const [expanded, setExpanded] = useState(true);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null); // Initialize data as null
	const total = useRef(0);
	const formik = useFormik({
		initialValues: {
			item_id: '',
			item_name: '',
			quantity: quantity ?? '',
			price: '', // Initialize price as empty string
			cost_price: '',
			selling_price: ''
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			const updatedValues = {
				...values,
				[type === 'sale' ? 'selling_price' : 'cost_price']: values.price,
			};
			setExpanded(false);
			onFormSubmit(updatedValues);
		},
	});

	useEffect(() => {
		const curr = Number(formik.values.price) * Number(formik.values.quantity) || 0;
		setTotal(subtotal - total.current + curr);
		total.current = curr;
	}, [formik.values.quantity, subtotal, formik.values.price])

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/items/view_items/MUM1957MH/?barcode_id=${barcode}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						"Access-Control-Allow-Origin": "*",
					},
				}
			);
			if (response.ok) {
				const jsonData = (await response.json());
				if (jsonData.status === "success") {
					const d = jsonData.data[0];
					setData(d);
					formik.setValues({
						item_id: d.item_id,
						item_name: d.item_name,
						price: d[`${type === 'sale' ? 'selling' : 'cost'}_price`] || '', // Set price based on priceType
						cost_price: d.cost_price,
						selling_price: d.selling_price,
						quantity: quantity || '',
					});
				}
			}
			setLoading(false);
		};
		fetchData();
	}, [barcode]);

	useEffect(() => {
		formik.setFieldValue('price', formik.values[`${type === 'sale' ? 'selling' : 'cost'}_price`]);
	}, [type])

	return (
		<Box sx={{ marginBottom: '7px', position: "relative" }}>
			{loading && (
				<Box
					sx={{
						borderBottomLeftRadius: '15px',
						borderBottomRightRadius: '15px',
						width: '100%',
						height: '100%',
						position: "absolute",
						zIndex: 10,
						backgroundColor: "rgba(0, 0, 0, 0.6)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<CircularProgress />
				</Box>
			)}
			<Paper elevation={4} sx={{ width: '100%', padding: '5px 20px', borderRadius: '5px', border: 'none' }} onClick={() => setExpanded(!expanded)}>
				<Grid container alignItems='center'>
					<Grid item xs={11}>
						<Stack>
							<Typography textAlign="left" fontWeight={500} fontSize="0.9rem">{barcode}</Typography>
							<Typography textAlign="left" fontSize="0.8rem">{formik.values.item_name}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={1}>
						<IconButton onClick={onDelete}>
							<DeleteRoundedIcon size="medium" />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
			<Collapse in={expanded} sx={{ marginTop: '5px' }}>
				<form
					ref={ref}
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ flexGrow: 1, margin: 'auto' }}
				>
					<Paper elevation={3} sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '15px', padding: "15px" }}>
						<Grid container spacing={1}>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									label="Barcode"
									value={barcode}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
									InputProps={{
										readOnly: true,
										sx: {
											fontSize: '0.8rem',
										},
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6} display="none">
								<TextField
									size="small"
									fullWidth
									id="item_id"
									name="item_id"
									label="Item ID*"
									value={formik.values.item_id ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_id && Boolean(formik.errors.item_id)}
									helperText={formik.touched.item_id && formik.errors.item_id}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
									InputProps={{
										sx: {
											fontSize: '0.8rem',
										},
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									fullWidth
									id="item_name"
									name="item_name"
									label="Item Name*"
									value={formik.values.item_name ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_name && Boolean(formik.errors.item_name)}
									helperText={formik.touched.item_name && formik.errors.item_name}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
									InputProps={{
										sx: {
											fontSize: '0.8rem',
										},
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="quantity"
									name="quantity"
									label="Quantity*"
									value={formik.values.quantity ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.quantity && Boolean(formik.errors.quantity)}
									helperText={formik.touched.quantity && formik.errors.quantity}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
									InputProps={{
										sx: {
											fontSize: '0.8rem',
										},
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="price"
									name="price"
									label="Price*"
									value={formik.values.price ?? ''}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.price && Boolean(formik.errors.price)}
									helperText={formik.touched.price && formik.errors.price}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
									InputProps={{
										startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon fontSize="small" /></InputAdornment>,
										sx: {
											fontSize: '0.8rem',
										},
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="left"
									name="left"
									label="Left Quantity"
									value={(formik.values?.quantity ? (type === 'purchase' ? String(Number(data?.quantity) + Number(formik.values?.quantity)) : String(Number(data?.quantity) - Number(formik.values?.quantity))) : data?.quantity) ?? ''}
									InputProps={{
										readOnly: true,
										sx: {
											fontSize: '0.8rem',
										},
									}}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="total"
									name="total"
									label="Total Amount"
									value={(Number(formik.values.price) * Number(formik.values.quantity) || 0) ?? ''}
									InputProps={{
										readOnly: true,
										startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon fontSize="small" /></InputAdornment>,
										sx: {
											fontSize: '0.8rem',
										},
									}}
									InputLabelProps={{
										sx: {
											fontSize: '0.8rem',
										}
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</Collapse>
		</Box>
	);
});

export default TransactionForm;
