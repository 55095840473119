const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const AUTH_TOKEN = "Bearer 22335564899";

async function searchItems(options) {
	const url = `${BASE_URL}/items/search_items`;
	const params = {
		query: options.query || '*',
		global_search: options.globalSearch || true,
		page: options.page || 1,
		per_page: options.perPage || 20,
	};
	if (options.categories) params.categories = options.categories.join(",");
	if (options.barcodeId) params.barcode_id = options.barcodeId;
	if (options.sortBy) params.sort_by = options.sortBy;

	try {
		const response = await fetch(url + "?" + new URLSearchParams(params), {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to search items: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to search items." };
	}
}

async function addItems(items) {
	const url = `${BASE_URL}/items/add_items/`;
	try {
		const response = await fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: AUTH_TOKEN,
			},
			body: JSON.stringify(items),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to add items: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to add items." };
	}
}

async function updateItem(itemId, updatedData) {
	const url = `${BASE_URL}/items/update_item/${itemId}`;
	try {
		const response = await fetch(url, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: AUTH_TOKEN,
			},
			body: JSON.stringify(updatedData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to update item: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to update item." };
	}
}

async function deleteItem(itemId) {
	const url = `${BASE_URL}/items/delete_item/${itemId}`;
	try {
		const response = await fetch(url, {
			method: "DELETE",
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to delete item: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to delete item." };
	}
}

export { addItems, updateItem, deleteItem, searchItems };