import React from "react";
import { Box } from "@mui/material";

export default function NotificationBar (props) {
	return (
		<Box>
			<span>
				{props.message}
			</span>
		</Box>
	)
};