import React, { createContext, useState, useContext, useEffect } from 'react';

const themeConfig = require('../data/theme.json');

const ThemeContext = createContext();

const useTheme = () => {
	const context = useContext(ThemeContext);
	if (!context) {
		throw new Error('useTheme must be used within a ThemeProvider');
	}
	return context;
};

const ThemeProvider = ({ children }) => {
	const [theme, setTheme] = useState('dark');
	const [themeObj, setThemeObj] = useState(themeConfig['dark']);

	useEffect(() => {
		setThemeObj(themeConfig[theme])
	}, [theme]);

	const changeTheme = () => {
		// currently set to toggle between the 3
		setTheme(theme === 'light' ? 'dark' : 'light')
	};

	const value = {
		theme,
		changeTheme,
		themeObj
	};

	return (
		<ThemeContext.Provider value={value}>
			{children}
		</ThemeContext.Provider>
	);
};

export { ThemeProvider, useTheme };
