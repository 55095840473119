import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Typography, Stack, Box, Collapse, CircularProgress, Alert } from '@mui/material';
import CustomButton from '../Base/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from "react-qr-code";
function Online({ total }) {
	return (
		<QRCode
			value={`upi://pay?pa=harsh90731%40oksbi&pn=Abhijit&tn=Test&am=${1.69}&cu=INR`}
			style={{ maxWidth: "300px", width: "100%" }}
		/>
	)
}


export default function SaveDialog({ onOpen, feedback, onClose }) {
	const [open, setOpen] = useState(false);
	const [billGenerated, setBillGenerated] = useState(false);

	const handleClose = () => {
		setOpen(false);
		onClose();
	}

	const handleClickOpen = () => {
		onOpen(() => setOpen(true));
	};

	return (
		<React.Fragment>
			<CustomButton fullWidth onClick={handleClickOpen}>Save</CustomButton>
			<Dialog
				onClose={handleClose}
				aria-labelledby="payment-category"
				open={open}
				sx={{ minHeight: "70vh" }}
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="payment-category">
					Add / Edit Item
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 12,
						top: 12,
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent>
					<Alert severity={feedback.success ? "success" : "error"}>{feedback.message}</Alert>
				</DialogContent>
			</Dialog>
		</React.Fragment>
	);
}
