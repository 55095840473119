import React from "react";
import { useLocale } from "../../context/LocaleContext";
import { Box, Paper, Typography, Stack, Grid, Button } from "@mui/material";
import { CustomChip, config } from "./CustomChips";
import { useTheme } from "../../context/ThemeContext";

export default function InventoryItem(props) {
	const { messages } = useLocale();
	const { themeObj } = useTheme();

	return (
		<Box sx={{ marginBottom: '10px' }}>
			<Button variant="text" sx={{ color: 'revert', textTransform: 'none', width: '100%', padding: 0, borderRadius: '5px', border: 'none' }} onClick={props.onClick}>
				<Paper elevation={3} sx={{ width: '100%', padding: '10px 20px', backgroundColor: themeObj.secondary }}>
					<Grid container alignItems='center'>
						<Grid item xs={7} sm={9} md={10} lg={10.5}>
							<Typography sx={{ overflow: "hidden", maxHeight: "56px", textWrap: "wrap", textOverflow: "ellipsis" }} width='calc(100% - 10px)' textAlign="left" fontSize="1.1rem" color={themeObj.title}>{props.item_name}</Typography>
						</Grid>
						<Grid item xs={5} sm={3} md={2} lg={1.5}>
							<Stack flexDirection="column" alignContent="center" justifyContent="space-between">
								<Typography textAlign="center" fontSize="1rem" color={themeObj.subtitle}>x {props.quantity}</Typography>
								<CustomChip
									icon={React.cloneElement(config[props.risk], { color: "inherit" })}
									label={messages.inventory.chips[props.risk]}
									c={props.risk === 'ok' ? themeObj.chip.green : (props.risk === 'understock' ? themeObj.chip.red : themeObj.chip.yellow)}
									bgc={props.risk === 'ok' ? themeObj.chip.green_background : (props.risk === 'understock' ? themeObj.chip.red_background : themeObj.chip.yellow_background)}
								/>
							</Stack>
						</Grid>
					</Grid>
				</Paper>
			</Button>
		</Box>
	)
}