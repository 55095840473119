import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const CustomButton = styled(Button)(() => ({
	backgroundColor: '#F5B60B',
	color: '#FFFFFF',
	borderRadius: '5px',
	boxShadow: 'none',
	'&:hover': {
		backgroundColor: '#F6AE09',
		borderRadius: '5px',
	},
	'&.Mui-disabled': {
		backgroundColor: '#D1D1D1',
		color: '#212121',
  	}
}));

export default CustomButton;