import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../context/LocaleContext";
import { Button, Grid, Paper, useMediaQuery, Stack } from "@mui/material";
import DesktopNavbar from "./DesktopNavbar";
import MobileNavbar from "./MobileNavbar";

export default function Navbar() {
	const { messages } = useLocale();
	const Navigation = useNavigate();
	const match = useMediaQuery('(min-width: 850px')

	return match ? <DesktopNavbar /> : <MobileNavbar />
};
