import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { IconButton, Box, Typography, LinearProgress } from '@mui/material';
import { ArrowForwardIos, ArrowBackIos } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import ShopDetails from './ShopDetails';
import AddressDetails from './AddressDetails';
import MiscDetails from './MiscDetals';
import Success from './Success';
import { createStore } from '../../apis/storeApi';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const pageVariants = {
	initial: (direction) => ({
		x: direction > 0 ? '100%' : '-100%', // Start off-screen to the right or left
		opacity: 0,
	}),
	enter: {
		x: 0,
		opacity: 1,
		transition: {
			opacity: { duration: 0.3 },
			x: { duration: 0.3 },
		},
	},
	exit: (direction) => ({
		x: direction > 0 ? '-100%' : '100%', // Exit to the left or right
		opacity: 0,
		transition: {
			opacity: { duration: 0.3 },
			x: { duration: 0.3 },
		},
	}),
};

const StoreCreatePage = () => {
	const [page, setPage] = useState(0);
	const [direction, setDirection] = useState(0);
	const [processing, setProcessing] = useState(false)
	const [success, setSuccess] = useState(false);
	const { themeObj } = useTheme();
	const { mongoUser, store, refresh } = useAuth()
	const formRefs = useRef({ shop: null, address: null, misc: null });
	const shopDetails = useRef(null);
	const addressDetails = useRef(null);
	const miscDetails = useRef(null);
	const navigate = useNavigate();

	const onFormSubmit = (form, values) => {
		switch (form) {
			case "shop":
				shopDetails.current = values;
				break;
			case "address":
				addressDetails.current = values;
				break;
			default:
				miscDetails.current = values;
				break;
		}
	}

	const handleRefUpdate = (formName, ref) => {
		formRefs.current[formName] = ref;
	}

	const handleNext = async () => {
		console.log(mongoUser)
		console.log(page);
		if (page < 3) {
			if (page === 0) {
				await formRefs.current.shop.requestSubmit();
				if (shopDetails.current && Object.values(shopDetails.current).length) {
					setPage(page + 1);
					setDirection(1);
					console.log("User details:", shopDetails.current);
				}
				const postData = async () => {
					const storeData = {
						...shopDetails.current,
						// address: { ...addressDetails.current },
						// ...miscDetails.current,
						email: mongoUser.email,
						phone: mongoUser.phone,
						store_owner_name: mongoUser.name,
					}
					const response = await createStore(mongoUser._id, storeData);
					setProcessing(false);
					if (response.success) {
						console.log(storeData);
						setSuccess(true);
						refresh();
					}
					else {
						console.error("Error creating user:", response.message);
						setSuccess(false);
					}
				}
				postData();
			}
			// else if (page === 1) {
			// 	await formRefs.current.address.requestSubmit();
			// 	setDirection(1);
			// 	setPage(page + 1);
			// }
			// else if (page === 1) {
			// 	await formRefs.current.misc.requestSubmit();
			// 	setDirection(1);
			// 	setPage(page + 1);
			// 	setProcessing(true);
			// 	const postData = async () => {
			// 		const storeData = {
			// 			...shopDetails.current,
			// 			address: { ...addressDetails.current },
			// 			...miscDetails.current,
			// 			email: mongoUser.email,
			// 			phone: mongoUser.phone,
			// 			store_owner_name: mongoUser.name,
			// 		}
			// 		const response = await createStore(mongoUser._id, storeData);
			// 		setProcessing(false);
			// 		if (response.success) {
			// 			console.log(storeData);
			// 			setSuccess(true);
			// 			refresh();
			// 		}
			// 		else {
			// 			console.error("Error creating user:", response.message);
			// 			setSuccess(false);
			// 		}
			// 	}
			// 	postData();
			// }
		}
	};

	const handleBack = () => {
		if (page > 0) {
			setDirection(-1);
			setPage(page - 1);
		}
	};

	useEffect(() => {
		if (!mongoUser) {
			navigate('/onboarding');
		}
		if (store) {
			navigate('/dashboard');
		}
	}, [store]);

	return (
		<Box sx={{ position: "relative", display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor: themeObj.background, overflowX: "hidden", color: themeObj.white }}>
			<Box sx={{ width: '100%', position: "absolute", top: 0 }}>
				<LinearProgress sx={{ position: "absolute", width: "100%", backgroundColor: "transparent", '& .MuiLinearProgress-bar': { backgroundColor: themeObj.primary } }} variant='determinate' value={page * 100} />
			</Box>
			<Box component='main' sx={{ flexGrow: 1, position: "relative", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", overflow: "hidden" }}>
				<AnimatePresence initial={false} custom={direction}>
					<motion.div
						key={page}
						custom={direction}
						variants={pageVariants}
						initial="initial"
						animate="enter"
						exit="exit"
						style={{ width: "100%", height: "100%", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center", padding: '50px' }}
					>
						{page === 0 && (
							<ShopDetails handleNext={handleNext} initial={shopDetails.current} ref={(ref) => handleRefUpdate("shop", ref)} onFormSubmit={onFormSubmit} />
						)}

						{/* {page === 1 && (
							<AddressDetails handleNext={handleNext} initial={addressDetails.current} ref={(ref) => handleRefUpdate("address", ref)} onFormSubmit={onFormSubmit} />
						)} */}
						{/* {page === 1 && (
							<MiscDetails handleNext={handleNext} handleBack={handleBack} initial={miscDetails.current} ref={(ref) => handleRefUpdate("misc", ref)} onFormSubmit={onFormSubmit} />
						)} */}
						{page === 1 && (
							<Success success={success} processing={processing} />
						)}
					</motion.div>
				</AnimatePresence>
			</Box>
			{/* <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "1rem", position: "absolute", bottom: 0, left: 0 }}>
				<IconButton sx={{ visibility: page === 0 ? 'hidden' : 'visible' }} onClick={handleBack}>
					<ArrowBackIos htmlColor={themeObj.primary} />
				</IconButton>
				<IconButton sx={{ visibility: page === 3 ? 'hidden' : 'visible' }} onClick={handleNext}>
					<ArrowForwardIos htmlColor={themeObj.primary} />
				</IconButton>
			</Box> */}
		</Box>
	);
};

export default StoreCreatePage;
