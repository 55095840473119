import React, { useState, useEffect } from "react";
import SignIn from "../components/SignIn/SignIn";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import krayaLogo from '../assets/krayaLogoWhite.jpg';
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useTheme } from '../context/ThemeContext';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

export default function SignInPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, store, mongoUser } = useAuth();
	const { themeObj } = useTheme();

	if (store) {
		console.log('here1')
		navigate(-1);
	}
	else if (!mongoUser && user) {
		console.log('here2')
		navigate('/onboarding');
	}
	else if (!store && mongoUser) {
		console.log('here3')
		navigate('/create-store');
	}
	else {
		return (
			<Box sx={{ position: "relative", display: 'flex', flexDirection: 'column', height: 'auto', minHeight: '100vh', backgroundColor: themeObj.background, overflowX: "hidden" }}>

				<header style={{ margin: 'min(5vw, 30px)', position: "fixed", top: "0.3rem", width: "min(90vw, 1300px)", zIndex: 10 }}>
					<Grid sx={{ backgroundColor: themeObj.card, borderRadius: "20px", border: "1px solid", borderColor: themeObj.secondary, boxShadow: "0 4px 6px -1px black", py: "9px", px: 1 }} container alignItems='center' justifyContent='space-between'>
						<Grid item xs={9} container gap={1} alignItems="center">
							<img src={krayaLogo} alt="" height={30} width={30} style={{ borderRadius: "50%" }} />
							<Typography sx={{ fontSize: "1.2rem" }} color={themeObj.contrast} fontWeight={500}>Kraya</Typography>
						</Grid>
					</Grid>
				</header>
				{/* <Box sx={{ display: "flex", justifyContent: "flex-start", position: "absolute", top: "10px", left: '5vw' }}>
					<IconButton onClick={() => navigate(location.state?.fromProfile ? -2 : -1)}><KeyboardBackspaceRoundedIcon fontSize="large" htmlColor={themeObj.primary} /></IconButton>
				</Box> */}
				<Box component='main' sx={{ flexGrow: 1, mt: 2, position: "relative" }}>
					<Box sx={{ mr: 'auto', ml: 'auto', width: 'min(90vw, 1300px)', height: '100%', pt: 4 }}>
						<SignIn />
					</Box>
				</Box>
			</Box>
		)
	}
}