import React, { useState } from "react";
import { Box, Paper, Typography, Stack, Grid, CircularProgress, Button } from "@mui/material";
import AddTaskRoundedIcon from '@mui/icons-material/AddTaskRounded';
import { useTheme } from "../../context/ThemeContext";
import CustomButton from "../Base/CustomButton";

function formatPrice(price) {
	if (price >= 10000000) {
		return (price / 10000000).toFixed(1) + ' Cr';
	} else if (price >= 100000) {
		return (price / 100000).toFixed(1) + ' Lacs';
	} else if (price >= 1000) {
		return (price / 1000).toFixed(1) + 'k';
	}
	return price;
}

export default function InstaSellItem(props) {
	const [loading, setLoading] = useState(false);
	const { themeObj } = useTheme();

	return (
		<Box sx={{ marginBottom: '12px', position: 'relative' }}>
			{loading &&
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						position: 'absolute',
						backgroundColor: 'rgba(0, 0 ,0 , 0.7)',
						height: '100%',
						width: '100%',
						zIndex: 10,
					}}
				>
					<CircularProgress sx={{ color: 'black' }} />
				</Box>
			}
			<Paper elevation={3} sx={{ width: '100%', padding: '10px 20px', backgroundColor: themeObj.secondary }}>
				<Grid container alignItems='center' spacing={1}>
					<Grid item xs={9} sm={10} md={10.5} lg={10.75}>
						<Typography sx={{ overflow: "hidden", maxHeight: "50px", textWrap: "wrap", textOverflow: "ellipsis" }} width='fit-content' textAlign="left" fontSize="1rem" color={themeObj.title}>{props.item_name}</Typography>
					</Grid>
					<Grid item xs={3} sm={2} md={1.5} lg={1.25} paddingRight={1}>
						<Stack flexDirection="column" alignItems="flex-end" justifyContent="space-between">
							<Typography textAlign="center" fontSize="1rem" color={themeObj.title}>x {props.current_quantity}</Typography>
							<Typography textAlign="center" fontSize="0.9rem" color={themeObj.subtitle}>₹ {formatPrice(props.selling_price)}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<CustomButton
							sx={{
								backgroundColor: themeObj.chip.green_background,
								border: "none",
								color: themeObj.chip.green,
								'&:hover': {
									backgroundColor: themeObj.chip.green_background,
									border: "none"
								},
								'&:focus': {
									backgroundColor: themeObj.chip.green_background,
									border: "none"
								}
							}}
							fullWidth
							variant="outlined"
							onClick={() => { setLoading(true); props.makeTransaction(() => setLoading(false)); }}
							startIcon={<AddTaskRoundedIcon fontSize="large" />}
						>
							Sell
						</CustomButton>
					</Grid>
					<Grid item xs={6}>
						<CustomButton
							sx={{
								backgroundColor: themeObj.chip.yellow_background,
								border: "none",
								color: themeObj.chip.yellow,
								'&:hover': {
									backgroundColor: themeObj.chip.yellow_background,
									border: "none"
								},
								'&:focus': {
									backgroundColor: themeObj.chip.yellow_background,
									border: "none"
								}
							}}
							fullWidth
							variant="outlined"
							onClick={() => props.addToCart()}
							startIcon={<AddTaskRoundedIcon fontSize="large" />}
						>
							Add to Cart
						</CustomButton>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	)
}