import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { Html5Qrcode } from "html5-qrcode";
import { Button } from "@mui/material";
import AUDIOURL from "../../assets/sounds/scanner.mp3";
import { useTheme } from "../../context/ThemeContext";
import { searchItems } from "../../apis/itemApi";

let html5QrCode;

const BarcodeScanner = forwardRef(({ onResult }, ref) => {
	const [audio] = useState(new Audio(AUDIOURL));
	const [permissionDenied, setPermissionDenied] = useState(false);
	const { themeObj } = useTheme();
	const [capturing, setCapturing] = useState(true);

	useImperativeHandle(ref, () => ({
		startScan: () => setCapturing(true),
		stopScan: () => setCapturing(false)
	}));

	useEffect(() => {
		html5QrCode = new Html5Qrcode(
			"reader",
		);
		const oldRegion = document.getElementById("qr-shaded-region");
		oldRegion && oldRegion.remove();

		return () => {
			if (html5QrCode.getState() !== 1) {
				html5QrCode.stop().catch(err => { console.log(err.message) });
			}
		}
	}, []);

	useEffect(() => {
		if (capturing) {
			if (html5QrCode.getState() === 3) {
				html5QrCode.resume();
			}
			else {
				handleClickAdvanced();
			}
		}
		else {
			handleStop();
		}
	}, [capturing]);

	const qrCodeSuccessCallback = (decodedText, decodedResult) => {
		audio.play();
		document.activeElement?.blur && document.activeElement.blur();
		const getItem = async () => {
			if (decodedText) {
				const result = await searchItems({ barcodeId: decodedText });
				if (result.success && result.data.data.items.length) {
					onResult({ type: "MONGO", value: result.data.data.items[0] });
				}
				else {
					onResult({ type: "BARCODE", values: decodedText});
				}
			}
		}
		setCapturing(false);
		getItem();
	};

	const handleClickAdvanced = () => {
		html5QrCode
			.start(
				{ facingMode: "environment" },
				{ fps: 30, qrbox: { width: 250, height: 100 }, aspectRatio: 0.3 },
				qrCodeSuccessCallback
			)
			.catch((err) => {
				console.error(err);
				setPermissionDenied(true);
			});
	};

	const handleStop = () => {
		try {
			html5QrCode.pause().catch((err) => {
				console.log(err.message);
			});
		} catch (err) {
			console.log(err);
		}
	};

	// const handleAskPermission = () => {
	// 	setPermissionDenied(false);
	// 	Html5Qrcode.getCameras()
	// 		.then(cameraId => {
	// 			handleClickAdvanced();
	// 		})
	// 		.catch((err) => {
	// 			setPermissionDenied(true);
	// 		});
	// };

	return (
		<div style={{ position: "sticky" }}>
			<div style={{ width: "100%", height: "100%", zIndex: 10, backgroundColor: "rgba(0, 0, 0, 0.8)", position: "absolute", display: capturing ? 'none' : 'flex', justifyContent: "center", alignItems: "center" }}>
				<Button style={{ width: "100%", height: "100%", color: themeObj.primary, fontWeight: 400, fontSize: "1.2rem" }} onClick={() => setCapturing(true)} variant="text" disableElevation disableFocusRipple disableRipple >New Scan</Button>
			</div>
			{permissionDenied &&
				<div style={{ width: "100%", height: "100%", zIndex: 10, display: 'flex', justifyContent: "center", alignItems: "center", fontSize: "1.5rem", fontWeight: 500, textWrap: "wrap" }}>
					Permission Denied, Please allow camera to use this feature
				</div>
			}
			<div id="reader"></div>
		</div>
	);
});

export default BarcodeScanner;