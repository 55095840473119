import { Grow, Popper, ClickAwayListener, Paper, IconButton, Stack, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useLocale } from '../../context/LocaleContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as React from 'react';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import LogoutIcon from '@mui/icons-material/Logout';

export default function MenuListComposition() {
	const { locale, changeLocale, messages } = useLocale();
	const Navigation = useNavigate();
	const [open, setOpen] = React.useState(false);
	const anchorRef = React.useRef();

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = React.useRef(open);
	React.useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<>
			<IconButton
				ref={anchorRef}
				aria-controls={open ? 'menu' : undefined}
				aria-expanded={open ? 'true' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				sx={{ mr: 2 }}
			>
				<MoreVertIcon />
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				placement="bottom-end"
				transition
				disablePortal
				sx={{ zIndex: 100 }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<List>
									<ListItem>
										<ListItemButton onClick={() => Navigation('/profile')}>
											<Stack flexDirection='row' alignItems='center' gap={1}>
												<AccountCircleRoundedIcon fontSize='large' />
												<ListItemText sx={{ textTransform: 'uppercase' }} primary={messages.navbar['profile']} />
											</Stack>
										</ListItemButton>
									</ListItem>
									<ListItem>
										<ListItemButton onClick={() => Navigation('/logout')}>
											<Stack flexDirection='row' alignItems='center' gap={1}>
												<LogoutIcon fontSize='large' />
												<ListItemText sx={{ textTransform: 'uppercase' }} primary={messages.navbar['logout']} />
											</Stack>
										</ListItemButton>
									</ListItem>
									<ListItem>
										<ListItemButton onClick={() => changeLocale()}>
											<Stack flexDirection='row' alignItems='center' gap={1}>
												<LogoutIcon fontSize='large' />
												<ListItemText sx={{ textTransform: 'uppercase' }} primary='Change Locale' />
											</Stack>
										</ListItemButton>
									</ListItem>
								</List>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}
