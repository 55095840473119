import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import { useTheme } from "../../context/ThemeContext";

export default function TopSection({ profilePic, storePic, name, email }) {
	const { themeObj } = useTheme();
	return (
		<Stack alignItems="center" sx={{ position: "relative" }}>
			<img src={storePic} alt="Store" height={200} style={{ width: "100vw", border: "1px solid", objectFit: "cover", boxShadow: `0 0 100px -40px ${themeObj.primary}` }} />
			<div style={{ position: "absolute", top: "150px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
				<img src={profilePic} alt="Profile" height={100} width={100} style={{ borderRadius: "50%", border: "2px solid", marginBottom: "10px", borderColor: themeObj.secondary }} />
			</div>
			<Box sx={{ height: "60px" }}></Box>
			<Typography color={themeObj.title} fontWeight={600} fontSize="1.8rem">{name}</Typography>
			<Typography color={themeObj.subtitle} fontSize="1rem">{email}</Typography>
		</Stack>
	);
}