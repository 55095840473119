import HomePage from '../pages/HomePage';
import TransactionsPage from '../pages/Transaction/TransactionsPage';
import InventoryPage from '../pages/Inventory/InventoryPage';
import BarcodePage from '../pages/BarcodePage';
import ReportPage from '../pages/ReportPage';
import ForecastPage from '../pages/ForecastPage';
import InstaSellPage from '../pages/InstaSellPage';
import SignInPage from '../pages/SignInPage';
import ProfilePage from '../pages/ProfilePage';
import ItemPage from '../pages/ItemPage';
import UnderConstruction from '../components/UnderConstruction';
import OnboardingPage from '../pages/Onboarding/OnboardingPage';
import StoreCreatePage from '../pages/StoreCreate/StoreCreatePage';
import SalesPage from '../pages/SalesPage';
import PurchasePage from '../pages/PurchasePage';

const protectedRoutes = [
	{ path: '/dashboard', component: <HomePage /> },
	{ path: '/transactions', component: <TransactionsPage /> },
	{ path: '/inventory', component: <InventoryPage /> },
	// { path: '/item/:itemId', component: <ItemPage /> },
	{ path: '/insta-sell', component: <InstaSellPage /> },
	{ path: '/barcode', component: <BarcodePage /> },
	// { path: '/report', component: <ReportPage /> },
	// { path: '/forecast', component: <ForecastPage /> },
	{ path: '/profile', component: <ProfilePage /> },
	{ path: '/logout', component: <UnderConstruction /> },
	{ path: '/onboarding', component: <OnboardingPage /> },
	{ path: '/create-store', component: <StoreCreatePage /> },
	{ path: '/sales', component: <SalesPage /> },
	{ path: '/purchase', component: <PurchasePage />}
];
const publicRoutes = [
	{ path: '/signin', component: <SignInPage /> },
]

export { protectedRoutes, publicRoutes };