import React from "react";
import { Paper, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { useLocale } from "../../context/LocaleContext";
import { CustomChip, config } from "./CustomChips";
import { useTheme } from "../../context/ThemeContext";

export default function StatisticDisplay({ action, setAction, data }) {
	const { messages } = useLocale();
	const { themeObj } = useTheme();
	const handleChange = (event, newAction) => {
		if (newAction !== null) {
			if (action.includes(newAction)) {
				setAction([...action].filter(a => a !== newAction));
			}
			else {
				setAction([...action, newAction]);
			}
		}
	}
	return (
		<ToggleButtonGroup
			value={action}
			exclusive
			onChange={handleChange}
			fullWidth
			sx={{ marginBottom: "10px" }}
		>
			<Paper elevation={3} sx={{ width: "100%", flexDirection: "row", display: "flex", backgroundColor: themeObj.card }}>
				<ToggleButton value="understock" sx={{ padding: "7px", textTransform: "none", '&.Mui-selected': { backgroundColor: themeObj.secondary }, '&.Mui-selected:hover': { backgroundColor: themeObj.secondary } }}>
					<Stack flexDirection="column" gap={0.5} justifyContent="center" alignItems="center">
						<CustomChip
							icon={React.cloneElement(config["understock"], { color: "inherit" })}
							label="Understock"
							c={themeObj.chip.red}
							bgc={themeObj.chip.red_background}
						/>
						<Typography fontSize="13px" color={themeObj.subtitle}>x 50</Typography>
					</Stack>
				</ToggleButton>
				<ToggleButton value="overstock" sx={{ padding: "7px", textTransform: "none", '&.Mui-selected': { backgroundColor: themeObj.secondary }, '&.Mui-selected:hover': { backgroundColor: themeObj.secondary } }}>
					<Stack flexDirection="column" gap={0.5} justifyContent="center" alignItems="center">
						<CustomChip
							icon={React.cloneElement(config["overstock"], { color: "inherit" })}
							label="Overstock"
							c={themeObj.chip.yellow}
							bgc={themeObj.chip.yellow_background}
						/>
						<Typography fontSize="13px" color={themeObj.subtitle}>x 24</Typography>
					</Stack>
				</ToggleButton>
			</Paper>
		</ToggleButtonGroup>
	);
}