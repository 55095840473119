import { Divider, Paper, Stack, Grid, Collapse, Box, Typography, IconButton, Button } from "@mui/material";
import React, { useState, useRef, useReducer, forwardRef, useImperativeHandle, useEffect } from "react";
import TableItem from "./TableItem";
import { useTheme } from "../../context/ThemeContext";
import { ExpandMoreRounded, AddCircleOutlineRounded } from "@mui/icons-material";
import { TransitionGroup } from "react-transition-group";
import { toast } from "react-toastify";

const calculateItemTotal = (values) => {
	if (!values || !values.quantity || !values.selling_price) return 0;

	const quantity = Number(values.quantity) || 0;
	const selling_price = Number(values.selling_price) || 0;
	const discount_amount = Number(values.discount_amount) || 0;
	const gst_rate = Number(values.gst_rate) || 0;

	return {
		sub_total: quantity * selling_price,
		discount_amount,
		cgst_amount: quantity * selling_price * gst_rate * 0.01 * 0.5,
		sgst_amount: quantity * selling_price * gst_rate * 0.01 * 0.5,
		charges_amount: 0
	};
};

const addValuesofObjects = (obj1, obj2) => {
	const result = {};
	const keys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

	keys.forEach(key => {
		const value1 = Number(obj1[key]) || 0;
		const value2 = Number(obj2[key]) || 0;
		result[key] = value1 + value2;
	});

	return result;
};

const formArrayReducer = (state, action) => {
	switch (action.type) {
		case "ADD_ENTRY":
			return !state.filter(form => form.id === action.payload.id).length ? [{ id: action.payload.id, values: {}, validated: false }, ...state] : state;
		case "UPDATE_ENTRY":
			return state.map((form) =>
				form.id === action.payload.id ? { ...form, values: { ...form.values, ...action.payload.values }, validated: true } : form
			);
		case "UPDATE_PARTIAL_ENTRY":
			return state.map((form) =>
				form.id === action.payload.id ? { ...form, values: { ...form.values, ...action.payload.values } } : form
			);
		case "DELETE_ENTRY":
			return state.filter((form) => form.id !== action.payload.id);
		case "RESET":
			return [];
		default:
			return state;
	}
};

const ItemsTable = forwardRef(({ onAmountChange, amount }, ref) => {
	const { themeObj } = useTheme();
	const [expanded, setExpanded] = useState(true);
	const formRefs = useRef([]);
	const valueRefs = useRef([]);
	const [formArray, dispatch] = useReducer(formArrayReducer, []);

	useImperativeHandle(ref, () => ({
		handleSubmit: () => {
			return new Promise(async (resolve) => {
				Object.values(formRefs.current).forEach((ref) => {
					ref.values = null;
				});
				await Object.values(formRefs.current).forEach(async (ref) => await ref.ref.requestSubmit());
				setTimeout(() => {
					resolve(formRefs.current.map(ref => ref.values));
				}, 500);
			});
		},
		hide: () => {
			setExpanded(false)
		},
		show: () => {
			setExpanded(true);
		},
		addItem: (data) => {
			console.log(data);
			if (data.type === 'TYPESENSE' || data.type === 'MONGO') {
				dispatch({ type: "ADD_ENTRY", payload: { id: data.value.item_id } });
				dispatch({ type: "UPDATE_PARTIAL_ENTRY", payload: { id: data.value.item_id, values: data.value } });
				valueRefs.current.push({ id: data.value.item_id });
			}
			else {
				toast.error("Item not found in the database.", { theme: "colored" } );
			}
		}
	}));

	const addEntry = () => {
		const id = Math.round(Math.random() * 10000);
		dispatch({ type: "ADD_ENTRY", payload: { id } });
		valueRefs.current.push({ id, values: {} });
	}

	const deleteItem = (id) => {
		dispatch({ type: "DELETE_ENTRY", payload: { id } });
		formRefs.current = formRefs.current.filter(ref => ref.id !== id);
		valueRefs.current = valueRefs.current.filter(ref => ref.id !== id);
		onAmountChange({ ...valueRefs.current.reduce((sum, ref) => addValuesofObjects(sum, calculateItemTotal(ref.values)), {}), charges_amount: amount.charges_amount || 0 });;
	};

	const onFormSubmit = (id, values) => {
		formRefs.current = formRefs.current.map(ref => ref.id === id ? { ...ref, values } : ref)
	}

	const onValuesChange = (id, values) => {
		valueRefs.current = valueRefs.current.filter(item => item.id !== id);
		valueRefs.current.push({ id, values });
		onAmountChange({ ...valueRefs.current.reduce((sum, ref) => addValuesofObjects(sum, calculateItemTotal(ref.values)), {}), charges_amount: amount.charges_amount || 0 });
	}

	return (
		<Box>
			<Paper elevation={4} sx={{ width: '100%', padding: '5px 10px', borderRadius: 1, backgroundColor: themeObj.secondary, height: '60px', display: "flex", alignItems: "center" }} onClick={() => setExpanded(!expanded)}>
				<Grid container alignItems='center'>
					<Grid item xs={10.5}>
						<Stack flexDirection="row" alignItems="center" justifyContent="space-between">
							<Typography textAlign="left" color={themeObj.title} fontSize="1.1rem">Items Table</Typography>
							<Stack alignItems="flex-end">
								<Typography color={themeObj.title} fontSize="0.7rem">Total Items</Typography>
								<Typography color={themeObj.title} fontSize="0.9rem">{formArray.length}</Typography>
							</Stack>
						</Stack>
					</Grid>
					<Grid item xs={1.5}>
						<IconButton>
							<ExpandMoreRounded sx={{ transition: 'all 0.3s ease', transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)' }} htmlColor={themeObj.primary} />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
			<Collapse in={expanded} sx={{ marginTop: '5px' }}>
				<Paper sx={{ backgroundColor: themeObj.card }}>
					<Stack gap={1}>
						<TransitionGroup>
							{formArray.map(form =>
								<Collapse key={form.id}>
									<TableItem
										deleteItem={() => deleteItem(form.id)}
										onFormSubmit={(values) => onFormSubmit(form.id, values)}
										onValuesChange={(values) => onValuesChange(form.id, values)}
										ref={el => {
											if (el) {
												// Only push valid refs
												formRefs.current = formRefs.current.filter(item => item.id !== form.id); // Remove any existing ref for this id
												formRefs.current.push({ id: form.id, ref: el });
											}
										}}
										data={form.values}
									/>
									<Divider sx={{ borderColor: themeObj.contrast, mt: 1, mb: 1 }} />
								</Collapse>
							)}
						</TransitionGroup>
						{/* <Button
							onClick={addEntry}
							endIcon={<AddCircleOutlineRounded />}
							sx={{
								color: themeObj.primary,
								'&:hover': {
									backgroundColor: "inherit"
								}
							}}
						>
							Add Entry
						</Button> */}
					</Stack>
				</Paper>
			</Collapse>
		</Box>
	);
});


export default ItemsTable;