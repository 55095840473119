import axios from 'axios';

// Environment variables
const authorizationKey = process.env.REACT_APP_AUDIO_API_KEY;
const userID = process.env.REACT_APP_BHASHINI_USER_ID;
const ulcaApiKey = process.env.REACT_APP_BHASHINI_API_KEY;
const baseUrl = 'https://dhruva-api.bhashini.gov.in/services/inference/pipeline';
const pipelineUrl = 'https://meity-auth.ulcacontrib.org/ulca/apis/v0/model/getModelsPipeline';

const langaugeModels = require('../data/language-models.json');

const buildHeaders = () => {
	return {
		'Accept': '*/*',
		// 'User-Agent': 'Thunder Client (https://www.thunderclient.com)',
		'Authorization': authorizationKey,
		'Content-Type': 'application/json',
	};
};

const buildHeaderPipeline = () => {
	return {
		'userID': userID,
		'ulcaApiKey': ulcaApiKey,
		'Content-Type': 'application/json',
	};
};

const pipeline = async (task, sourceLanguage = null, targetLanguage = null) => {
	try {
		const a = {pipelineResponseConfig: task.map(e => { return { config: [langaugeModels[`${e}_${sourceLanguage}_${e === "translation" ? targetLanguage : ''}`]] }}), }
		console.log(a);
		return a;

		// const pipelineId = '64392f96daac500b55c543cd';
		// const payload = {
		// 	'pipelineTasks': [],
		// 	'pipelineRequestConfig': { 'pipelineId': pipelineId },
		// };

		// if (task.includes('asr')) {
		// 	const asr = {
		// 		'taskType': 'asr',
		// 		'config': { 'language': { 'sourceLanguage': sourceLanguage } },
		// 	};
		// 	payload.pipelineTasks.push(asr);
		// }
		// if (task.includes('translation')) {
		// 	const translation = {
		// 		'taskType': 'translation',
		// 		'config': {
		// 			'language': {
		// 				'sourceLanguage': sourceLanguage,
		// 				'targetLanguage': targetLanguage,
		// 			},
		// 		},
		// 	};
		// 	payload.pipelineTasks.push(translation);
		// }
		// if (task.includes('tts')) {
		// 	const tts = {
		// 		'taskType': 'tts',
		// 		'config': { 'language': { 'sourceLanguage': sourceLangauge } },
		// 	};
		// 	payload.pipelineTasks.push(tts);
		// }

		// const headers = buildHeaderPipeline();
		// const response = await axios.post(pipelineUrl, payload, { headers });
		// return response.data;
	} catch (error) {
		console.error('Error in pipeline:', error);
		return false;
	}
};

const synthesizeAudio = async (audioContent, sourceLanguage, audioFormat = "wav", samplingRate = 16000) => {
	try {
		let pipelineResponse = await pipeline(['asr'], sourceLanguage);
		pipelineResponse = pipelineResponse.pipelineResponseConfig[0].config[0];
		pipelineResponse.audioFormat = audioFormat;
		pipelineResponse.samplingRate = samplingRate;
		// pipelineResponse.preProcessors = ['vad'];
		// pipelineResponse.postProcessors = ['itn'];

		const payload = {
			pipelineTasks: [{ taskType: 'asr', config: pipelineResponse }],
			inputData: { audio: [{ audioContent }] },
		};

		const headers = buildHeaders();
		const response = await axios.post(baseUrl, payload, { headers });
		const responseData = response.data;

		let responseFormat = {};
		responseData.pipelineResponse.forEach(item => {
			responseFormat[item.taskType] = item;
		});
		responseFormat.final_output = responseData.pipelineResponse[responseData.pipelineResponse.length - 1].output[0].source;

		return responseFormat;
	} catch (error) {
		console.error('Error in synthesizeAudio:', error);
		return false;
	}
};

const synthesizeAudioTranslate = async (audioContent, sourceLanguage, targetLanguage, audioFormat = "wav", samplingRate = 16000) => {
	try {
		if (sourceLanguage === targetLanguage) {
			return synthesizeAudio(audioContent, sourceLanguage, audioFormat, samplingRate);
		}

		const pipelineResponse = await pipeline(['asr', 'translation'], sourceLanguage, targetLanguage);
		const asrPipeline = pipelineResponse.pipelineResponseConfig[0].config[0];
		asrPipeline.audioFormat = audioFormat;
		asrPipeline.samplingRate = samplingRate;
		asrPipeline.preProcessors = ['vad'];
		asrPipeline.postProcessors = ['itn', 'punctuation'];
		const translationPipeline = pipelineResponse.pipelineResponseConfig[1].config[0];
		translationPipeline.postProcessors = ['glossary'];

		const payload = {
			pipelineTasks: [
				{ taskType: 'asr', config: asrPipeline },
				{ taskType: 'translation', config: translationPipeline },
			],
			inputData: { audio: [{ audioContent }] },
		};

		const headers = buildHeaders();
		const response = await axios.post(baseUrl, payload, { headers });
		const responseData = response.data;

		let responseFormat = {};
		responseData.pipelineResponse.forEach(item => {
			responseFormat[item.taskType] = item;
		});
		responseFormat.final_output = responseData.pipelineResponse[responseData.pipelineResponse.length - 1].output[0].target;

		return responseFormat;
	} catch (error) {
		console.error('Error in synthesizeAudioTranslate:', error);
		return false;
	}
};

const textToSpeech = async (text, targetLanguage, ttsGender = 'female', ttsSamplingRate = 8000) => {
	try {
		let pipelineResponse = await pipeline(['tts'], targetLanguage, null);
		pipelineResponse = pipelineResponse.pipelineResponseConfig[0].config[0];
		pipelineResponse.gender = pipelineResponse.supportedVoices[0];
		pipelineResponse.samplingRate = ttsSamplingRate;

		const payload = {
			pipelineTasks: [{ taskType: 'tts', config: pipelineResponse }],
			inputData: { input: [{ source: text }] },
		};

		const headers = buildHeaders();
		const response = await axios.post(baseUrl, payload, { headers });
		return response.data;
	} catch (error) {
		console.error('Error in textToSpeech:', error);
		return false;
	}
};

const translateAndTts = async (text, sourceLanguage, targetLanguage, ttsGender = 'female', ttsSamplingRate = 8000) => {
	try {
		if (sourceLanguage === targetLanguage) {
			return textToSpeech(text, targetLanguage, ttsGender, ttsSamplingRate);
		}

		const pipelineResponse = await pipeline(['translation', 'tts'], sourceLanguage, targetLanguage);
		const translationPipeline = pipelineResponse[0].config[0];
		const ttsPipeline = pipelineResponse[1].config[0];
		ttsPipeline.gender = ttsPipeline.supportedVoices[0];
		ttsPipeline.samplingRate = ttsSamplingRate;

		const payload = {
			pipelineTasks: [
				{ taskType: 'translation', config: translationPipeline },
				{ taskType: 'tts', config: ttsPipeline },
			],
			inputData: { input: [{ source: text }] },
		};

		const headers = buildHeaders();
		const response = await axios.post(baseUrl, payload, { headers });
		return response.data;
	} catch (error) {
		console.error('Error in translateAndTts:', error);
		return false;
	}
};

export { synthesizeAudio, synthesizeAudioTranslate, textToSpeech, translateAndTts };