import { Stack, TextField, Typography, InputAdornment, Grid, Button, MenuItem } from "@mui/material";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { useTheme } from '../../context/ThemeContext';
import { useLocale } from '../../context/LocaleContext';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useAuth } from '../../context/AuthContext';

const UserDetails = forwardRef(({ onFormSubmit, handleNext, initial }, ref) => {
	const { themeObj } = useTheme();
	const { messages } = useLocale();
	const { user } = useAuth();
	
	const validationSchema = Yup.object({
		name: Yup.string().required(messages.input.validations.required),
		phone: Yup.string().required(messages.input.validations.required).phone('IN', messages.input.validations.invalid),
		age: Yup.number().typeError(messages.input.validations.number).required(messages.input.validations.required).positive(messages.input.validations.positive),
		gender: Yup.string().required(messages.input.validations.required),
	});

	const formik = useFormik({
		initialValues: {
			name: initial?.name ?? '',
			phone: initial?.phone ?? '',
			age: initial?.age ?? '',
			gender: initial?.gender ?? '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onFormSubmit(values);
		},
	});

	useImperativeHandle(ref, () => ({
		requestSubmit: async () => {
			await formik.submitForm();
		}
	}));

	useEffect(() =>{
		if (!formik.values.name && user) formik.setFieldValue('name', user.displayName);
	}, [user]);

	return (
		<form
			component="form"
			onSubmit={formik.handleSubmit}
			style={{ flexGrow: 1, margin: 'auto' }}
		>
			<Stack gap={2}>
				<TextField
					fullWidth
					id="name"
					name="name"
					label={messages.input.fields.name}
					value={formik.values.name ?? ''}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.name && Boolean(formik.errors.name)}
					helperText={formik.touched.name && formik.errors.name}
					sx={{
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
				/>
				<TextField
					fullWidth
					id="phone"
					name="phone"
					label={messages.input.fields.phoneno}
					value={formik.values.phone ?? ''}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched.phone && Boolean(formik.errors.phone)}
					helperText={formik.touched.phone && formik.errors.phone}
					sx={{
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: themeObj.primary,
							},
							'& fieldset': {
								borderColor: themeObj.subtitle,
							},
						}
					}}
					InputLabelProps={{
						sx: {
							fontSize: '1rem',
							color: themeObj.title,
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					InputProps={{
						startAdornment: <InputAdornment position="start"><Typography sx={{ lineHeight: "unset" }} color={themeObj.subtitle}>+91</Typography></InputAdornment>,
						sx: {
							fontSize: '1rem',
							color: themeObj.title
						},
					}}
				/>
				<Grid container spacing={1}>
					<Grid item xs={6}>
						<TextField
							fullWidth
							id="age"
							name="age"
							label={messages.input.fields.age}
							value={formik.values.age ?? ''}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.age && Boolean(formik.errors.age)}
							helperText={formik.touched.age && formik.errors.age}
							sx={{
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '1rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								sx: {
									fontSize: '1rem',
									color: themeObj.title
								},
							}}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							select
							fullWidth
							id="gender"
							name="gender"
							label={messages.input.fields.gender}
							value={formik.values.gender ?? ''}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							error={formik.touched.gender && Boolean(formik.errors.gender)}
							helperText={formik.touched.gender && formik.errors.gender}
							sx={{
								fontSize: "0.8rem",
								color: themeObj.title,
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								},
								'& .MuiSelect-icon': {
									color: themeObj.title
								}
							}}
							InputLabelProps={{
								sx: {
									fontSize: '1rem',
									color: themeObj.title,
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							}}
							InputProps={{
								sx: {
									fontSize: '1rem',
									color: themeObj.title
								},
							}}
							SelectProps={{
								MenuProps: {
									sx: {
										'& .MuiMenu-paper': {
											backgroundColor: themeObj.card,
										},
										'& .MuiMenu-list': {
											p: 0
										}
									}
								}
							}}
						>
							<MenuItem value="male" sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} >Male</MenuItem>
							<MenuItem value="female" sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} >Female</MenuItem>
							<MenuItem value="other" sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} >Other</MenuItem>
						</TextField>
					</Grid>
				</Grid>
				<Button
					fullWidth
					variant="text"
					sx={{
						p: 1.5,
						color: themeObj.primary,
						boxShadow: 'none',
						'&:hover': {
							backgroundColor: "transparent",
						},
					}}
					onClick={handleNext}
					endIcon={<ArrowForwardRoundedIcon htmlColor={themeObj.primary} />}
				>
					{messages.common.next}
				</Button>
			</Stack>
		</form>
	)
});

export default UserDetails;