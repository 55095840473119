import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert outlined={props.outlined} elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertComponent = ({ type, message, outlined = false }) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		if (message) {
			setOpen(true);
			const timer = setTimeout(() => {
				setOpen(false);
			}, 5000);
			return () => clearTimeout(timer);
		}
	}, [message]);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
			<Alert outlined={outlined} onClose={handleClose} severity={type} sx={{ width: '100%' }}>
				{type !== 'info' && <AlertTitle>{type === 'success' ? 'Success' : 'Error'}</AlertTitle>}
				{message}
			</Alert>
		</Snackbar>
	);
};

export default AlertComponent;
