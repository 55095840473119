import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mui/material';
import { Player } from '@lordicon/react';
import useAudioRecorder from "../../hooks/useAudioRecorder";
import { useTheme } from '../../context/ThemeContext';
const MICROPHONE = require('../../data/microphone.json');

export default function Recorder({ setAudioURL, setAudioBlob }) {
	const [state, setState] = useState('in-reveal');
	const [loaded, setLoaded] = useState(false);
	const { isRecording, startRecording, stopRecording, recordingBlob } = useAudioRecorder();
	const { themeObj } = useTheme();

	const playerRef = useRef(null);
	useEffect(() => {
		playerRef.current?.playFromBeginning();
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (state === 'in-reveal' && loaded) {
			setState('loop-recording');
			playerRef.current?.playFromBeginning();
		}
		if (state === 'loop-recording' && loaded) {
			setState('in-reveal');
			playerRef.current?.play();
		}
	}, [isRecording]);


	useEffect(() => {
		if (recordingBlob) {
			const url = URL.createObjectURL(recordingBlob);
			setAudioBlob(recordingBlob);
			setAudioURL(url);
		}
	}, [recordingBlob]);

	return (
		<Button
			onClick={isRecording ? stopRecording : startRecording}
			sx={{
				padding: 2,
				borderRadius: '50%',
				width: 'min-content'
			}}
		>
			<Player
				ref={playerRef}
				icon={MICROPHONE}
				state={state}
				size={100}
				colorize={themeObj.primary}
				onComplete={() => { if (state === 'loop-recording') playerRef.current?.playFromBeginning() }}
			/>
		</Button>
	);
}
