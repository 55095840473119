import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocale } from "../../context/LocaleContext";
import { Button, Grid, Paper, Stack, IconButton } from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import DensitySmallOutlinedIcon from '@mui/icons-material/DensitySmallOutlined';
import AssessmentRoundedIcon from '@mui/icons-material/AssessmentRounded';
import DashboardRoundedIcon from '@mui/icons-material/DashboardRounded';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import CurrencyExchangeRoundedIcon from '@mui/icons-material/CurrencyExchangeRounded';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import logoText from '../../assets/logotext.png'

export default function DesktopNavbar() {
	const { messages } = useLocale();
	const Navigation = useNavigate();

	const navItems = [
		{
			name: messages.navbar['all-in-one'],
			href: '/dashboard',
			icon: <DashboardRoundedIcon fontSize='large' />
		},
		{
			name: messages.navbar['transaction'],
			href: '/transactions',
			icon: <CurrencyExchangeRoundedIcon fontSize='large' />
		},
		{
			name: messages.navbar['inventory'],
			href: '/inventory',
			icon: <Inventory2OutlinedIcon fontSize='large' />
		},
		{
			name: messages.navbar['barcode'],
			href: '/barcode',
			icon: <DensitySmallOutlinedIcon sx={{ transform: "rotate(90deg)" }} fontSize='large' />
		},
		{
			name: 'Insta-Sell',
			href: '/insta-sell',
			icon: <FlashOnRoundedIcon fontSize='large' />
		},
		{
			name: messages.navbar['report'],
			href: '/report',
			icon: <AssessmentRoundedIcon fontSize='large' />
		},
		{
			name: messages.navbar['forecast'],
			href: '/forecast',
			icon: <TrendingUpRoundedIcon fontSize='large' />
		},
	]

	return (
		<Grid container alignItems={'center'} justifyContent={'space-between'}>
			<Grid container justifyContent="center" item sm={2.5} lg={2} padding="10px">
				<img src={logoText} alt="logo text" width="90%" style={{ maxWidth: "200px" }}/>
			</Grid>
			<Grid item lg={1}>
				<Paper></Paper>
			</Grid>
			<Grid item xs={7} lg={6}>
				<Grid container justifyContent='space-between'>
					{navItems.map((element, index) => (
						<Grid key={index}>
							<Button onClick={() => Navigation(element.href)} disableFocusRipple disableRipple sx={{ color: '#000000' }}>
								<Stack flexDirection='column' justifyContent='center' alignItems='center'>
									{element.icon}
									{element.name}
								</Stack>
							</Button>
						</Grid>
					))}
				</Grid>
			</Grid>
			<Grid item lg={1}>
				<Paper></Paper>
			</Grid>
			<Grid item xs={2.5} lg={2} container alignItems="center" justifyContent='flex-end' columnGap={1}>
				<Grid>
					<IconButton>
						<NotificationsNoneOutlinedIcon fontSize="large" />
					</IconButton>
				</Grid>
				<Grid>
					<ProfileMenu />
				</Grid>
			</Grid>
		</Grid>
	)
};
