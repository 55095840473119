import { useReducer, useEffect } from 'react';

// Define initial state
const initialState = (key, defaultValue) => {
	const storedValue = localStorage.getItem(key);
	if (storedValue) {
		return JSON.parse(storedValue);
	}
	return defaultValue;
};

// Define reducer function
const reducer = (state, action) => {
	switch (action.type) {
		case 'SET':
			return action.payload;

		case 'REMOVE':
			localStorage.removeItem(action.key);
			return null;

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
};

export default function useLocalStorage(key, defaultValue) {
	const [state, dispatch] = useReducer(reducer, initialState(key, defaultValue));

	useEffect(() => {
		if (state === undefined) return;
		if (!state) {
			localStorage.removeItem(key);
		} else {
			localStorage.setItem(key, JSON.stringify(state));
		}
	}, [state, key]);

	return [state, dispatch];
}