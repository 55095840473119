import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Stack, Paper, Typography, Box } from "@mui/material";
import ProfileItem from "../components/Profile/ProfileItem";
import storePic from "../assets/shop.webp";
import TopSection from "../components/Profile/TopSection";
import { useTheme } from "../context/ThemeContext";

export default function ProfilePage() {
	const navigate = useNavigate();
	const { themeObj } = useTheme();
	const { mongoUser, store } = useAuth();

	// useEffect(() => {
	// 	if (!mongoUser) {
	// 		navigate('/onboarding')
	// 	}
	// }, []);

	return (
		<Stack gap={1}>
			<TopSection storePic={storePic} profilePic={mongoUser?.picture} name={mongoUser?.name} email={mongoUser?.email} />
			<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.secondary }}>
				<Typography color={themeObj.title} fontWeight={500} fontSize="1.2rem">{store?.store_name}</Typography>
				<Typography color={themeObj.subtitle} fontSize="0.7rem">{store?.store_category}</Typography>
			</Paper>
			<ProfileItem label="Address" value={store?.address?.address} />
			<Stack gap={1} flexDirection="row">
				<ProfileItem label="City" value={store?.address?.city} />
				<ProfileItem label="State" value={store?.address?.state} />
			</Stack>
			<Stack gap={1} flexDirection="row">
				<ProfileItem label="PIN Code" value={store?.address?.pincode} />
				<ProfileItem label="Phone" value={store?.phone} />
			</Stack>
			<ProfileItem label="UPI ID" value={store?.upi_id} />
			<Box sx={{ height: "20px" }}></Box>
		</Stack>
	);
}