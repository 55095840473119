const BASE_URL = process.env.REACT_APP_BACKEND_URL;

async function createStore(AUTH_TOKEN, storeData = {}) {
	try {
		const response = await fetch(`${BASE_URL}/stores/create_store/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${AUTH_TOKEN}`,
			},
			body: JSON.stringify(storeData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to create store: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to create store." };
	}
}

async function viewStore(AUTH_TOKEN) {  // Assumes viewing the current user's store
	try {
		const response = await fetch(`${BASE_URL}/stores/view_store/`, {
			headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to view store: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to view store." };
	}
}

async function updateStore(AUTH_TOKEN, updatedData = {}) {
	try {
		const response = await fetch(`${BASE_URL}/stores/update_store/`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${AUTH_TOKEN}`,
			},
			body: JSON.stringify(updatedData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to update store: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to update store." };
	}
}

async function deleteStore(AUTH_TOKEN) {
	try {
		const response = await fetch(`${BASE_URL}/stores/delete_store/`, {
			method: 'DELETE',
			headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to delete store: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to delete store." };
	}
}

export {
	createStore,
	viewStore,
	updateStore,
	deleteStore
};