import React from "react";
import { useTheme } from "../context/ThemeContext";

export default function Background({ children }) {
	const { themeObj } = useTheme();

	return (
		<div style={{ backgroundColor: themeObj.background }}>
			{children}
		</div>
	)
}