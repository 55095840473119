import React, { useEffect, useState, forwardRef } from 'react';
import { TextField, MenuItem, Box, Grid, Button, InputAdornment, Collapse, Paper, Typography, Stack, IconButton, CircularProgress } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import categories from '../../data/categories.json';  // assuming the JSON file is named categories.json
import { useTheme } from "../../context/ThemeContext";

function fakeFetch(barcode) {
	const data = {
		item_name: 'iPhone 13 Pro',
		quantity: '4',
		selling_price: '234543',
		cost_price: '',
		understock_qt: '',
		overstock_qt: '',
		item_category: 'electronics_and_gadgets',
	}
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve({
				ok: true,
				json: () => data
			});
		}, 1000);
	});
}

const validationSchema = Yup.object({
	item_name: Yup.string().required('Item Name is required'),
	quantity: Yup.number().typeError('Item Quantity must be a number').required('Item Quantity is required'),
	selling_price: Yup.number().typeError('Selling Price must be a number').required('Selling Price is required').positive('Selling Price must be a positive number'),
	cost_price: Yup.number().typeError('Cost Price must be a number').required('Cost Price is required').positive('Cost Price must be a positive number'),
	understock_qt: Yup.number().typeError('Understock Quantity must be a number').required('Understock Quantity is required').integer('Understock Quantity must be an integer').min(0, 'Understock Quantity must be at least 0'),
	overstock_qt: Yup.number().typeError('Overstock Quantity must be a number').required('Overstock Quantity is required').integer('Overstock Quantity must be an integer').min(0, 'Overstock Quantity must be at least 0'),
	item_category: Yup.string().required('Category is required'),
});

const ItemForm = forwardRef(({ barcode, onFormSubmit, onDelete, setItemAction }, ref) => {
	const [expanded, setExpanded] = useState(true);
	const [data, setData] = useState();
	const [loading, setLoading] = useState(false);
	const formik = useFormik({
		initialValues: {
			item_name: data?.item_name || '',
			quantity: data?.quantity || '',
			selling_price: data?.selling_price || '',
			cost_price: data?.cost_price || '',
			understock_qt: data?.understock_qt || '',
			overstock_qt: data?.overstock_qt || '',
			item_category: data?.item_category || '',
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setExpanded(false);
			onFormSubmit(values);
		},
	});

	const { themeObj } = useTheme();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const response = await await fetch(
				`${process.env.REACT_APP_BACKEND_URL}/items/view_items/MUM1957MH/?barcode_id=${barcode}`,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						"Access-Control-Allow-Origin": "*",
					},
				}
			);
			if (response.ok) {
				const jsonData = (await response.json());
				if (jsonData.status === "success") {
					const d = jsonData.data[0];
					console.log(d);
					setItemAction("edit");
					setData(d);
					formik.setValues(d);
				}
			}
			setLoading(false);
		};
		fetchData();
	}, [barcode]);

	return (
		<Box sx={{ marginBottom: '5px', position: "relative" }}>
			{loading && <Box sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', width: '100%', height: '100%', position: "absolute", zIndex: 10, backgroundColor: "rgba(0, 0, 0, 0.6)", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></Box>}
			<Paper elevation={4} sx={{ width: '100%', padding: '10px 20px', borderRadius: '5px', border: 'none', marginBottom: "5px" }} onClick={() => setExpanded(!expanded)}>
				<Grid container alignItems='center'>
					<Grid item xs={11}>
						<Stack>
							<Typography textAlign="left" fontSize="1.1rem">{barcode}</Typography>
							<Typography textAlign="left" fontSize="0.9rem">{formik.values.item_name}</Typography>
						</Stack>
					</Grid>
					<Grid item xs={1}>
						<IconButton onClick={onDelete}>
							<DeleteRoundedIcon />
						</IconButton>
					</Grid>
				</Grid>
			</Paper>
			<Collapse in={expanded}>
				<form
					ref={ref}
					component="form"
					onSubmit={formik.handleSubmit}
					sx={{ flexGrow: 1, margin: 'auto' }}
				>
					<Paper elevation={3} sx={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', padding: "10px" }}>
						<Grid container spacing={1.5}>
							<Grid item xs={12}>
								<TextField
									size="small"
									fullWidth
									label="Barcode"
									value={barcode}
									InputProps={{ readOnly: true }}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									fullWidth
									id="item_name"
									name="item_name"
									label="Item Name*"
									value={formik.values.item_name}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_name && Boolean(formik.errors.item_name)}
									helperText={formik.touched.item_name && formik.errors.item_name}
									InputLabelProps={{
										sx: {
											color: themeObj.primary
										}
									}}
									InputProps={{
										sx: {
											backgroundColor: "transparent"
										}
									}}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									size="small"
									fullWidth
									id="quantity"
									name="quantity"
									label="Item Quantity*"
									value={formik.values.quantity}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.quantity && Boolean(formik.errors.quantity)}
									helperText={formik.touched.quantity && formik.errors.quantity}
								/>
							</Grid>

							<Grid item xs={12} md={6}>
								<TextField
									select
									size="small"
									fullWidth
									id="item_category"
									name="item_category"
									label="Category*"
									value={formik.values.item_category}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.item_category && Boolean(formik.errors.item_category)}
									helperText={formik.touched.item_category && formik.errors.item_category}
								>
									{categories.map((item_category) => (
										<MenuItem key={item_category.value} value={item_category.value}>
											{item_category.label}
										</MenuItem>
									))}
								</TextField>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="selling_price"
									name="selling_price"
									label="Selling Price*"
									value={formik.values.selling_price}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.selling_price && Boolean(formik.errors.selling_price)}
									helperText={formik.touched.selling_price && formik.errors.selling_price}
									InputProps={{
										startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon fontSize="small" /></InputAdornment>,
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="cost_price"
									name="cost_price"
									label="Cost Price*"
									value={formik.values.cost_price}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.cost_price && Boolean(formik.errors.cost_price)}
									helperText={formik.touched.cost_price && formik.errors.cost_price}
									InputProps={{
										startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon fontSize="small" /></InputAdornment>,
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="understock_qt"
									name="understock_qt"
									label="Understock Quantity*"
									value={formik.values.understock_qt}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.understock_qt && Boolean(formik.errors.understock_qt)}
									helperText={formik.touched.understock_qt && formik.errors.understock_qt}
									InputProps={{
										startAdornment: <InputAdornment position="start"><TrendingDownRoundedIcon fontSize="small" /></InputAdornment>,
									}}
								/>
							</Grid>

							<Grid item xs={6}>
								<TextField
									size="small"
									fullWidth
									id="overstock_qt"
									name="overstock_qt"
									label="Overstock Quantity*"
									value={formik.values.overstock_qt}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={formik.touched.overstock_qt && Boolean(formik.errors.overstock_qt)}
									helperText={formik.touched.overstock_qt && formik.errors.overstock_qt}
									InputProps={{
										startAdornment: <InputAdornment position="start"><TrendingUpRoundedIcon fontSize="small" /></InputAdornment>,
									}}
								/>
							</Grid>
						</Grid>
					</Paper>
				</form>
			</Collapse>
		</Box>
	);
});

export default ItemForm;
