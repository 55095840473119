import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Stack, CircularProgress, Divider } from '@mui/material';
import { useTheme } from '../../context/ThemeContext';
import { useLocale } from '../../context/LocaleContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import krayaLogoWhite from '../../assets/krayaLogoWhite.jpg'

export default function StoreSelect({ success, processing }) {
	const { themeObj } = useTheme();
	const { messages } = useLocale();
	const [showLogo, setShowLogo] = useState(false);
	const naviagte = useNavigate();

	useEffect(() => {
		if (!processing && success) {
			setShowLogo(true);

			setTimeout(() => {
				setShowLogo(false);
			}, 2000);
		}
	}, [processing, success]);

	return (
		<Box sx={{ width: "100%", position: "relative" }}>
			{processing ?
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: "column", gap: 2 }}>
					<CircularProgress sx={{ color: themeObj.primary }} size="4rem" />
					<Typography gutterBottom sx={{ color: themeObj.subtitle }} variant="h6" textAlign="center">
						{messages.onboarding.processing}
					</Typography>
				</Box>
				:
				success ?
					<>
						<AnimatePresence>
							{showLogo && (
								<motion.div
									initial={{ opacity: 0, y: 20 }}
									animate={{ opacity: 1, y: 0 }}
									transition={{ duration: 0.5, delay: 0.3 }}
									exit={{ opacity: 0, y: -20 }}
									key="logo"
									style={{ width: "100%", height: "100%", position: "absolute", display: "flex", justifyContent: "center", alignItems: "center" }}
								>
									<Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem', flexDirection: "column" }}>
										<img src={krayaLogoWhite} width={100} alt="Kraya Logo" style={{ borderRadius: '50%' }} />
										<Typography gutterBottom sx={{ color: themeObj.title }} variant="h5" textAlign="center">
											Welcome to Kraya
										</Typography>
									</Box>
								</motion.div>
							)}
						</AnimatePresence>
						<motion.div
							initial={{ opacity: 0, y: 20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.5, delay: 3 }}
						>
							<Stack gap={2} sx={{ width: "100%" }}>
								<Box sx={{ display: 'flex', alignItems: 'center', gap: '1.5rem', flexDirection: "column" }}>
									<img src={krayaLogoWhite} width={100} alt="Kraya Logo" style={{ borderRadius: '50%' }} />
									<Typography gutterBottom sx={{ color: themeObj.title }} variant="h5" textAlign="center">
										Get Started
									</Typography>
								</Box>
								<Button
									fullWidth
									sx={{
										p: 1.5,
										backgroundColor: themeObj.primary,
										color: themeObj.title,
										boxShadow: 'none',
										'&:hover': {
											backgroundColor: themeObj.primary,
										},
									}}
									onClick={() => naviagte('/create-store')}
								>
									Create Your Store Now
								</Button>
								<Divider sx={{ my: 2, "&::before, &::after": { borderColor: themeObj.primary } }}>
									<Typography color={themeObj.title}>Or</Typography>
								</Divider>
								<Typography
									textAlign="center"
									sx={{
										color: themeObj.title,
									}}
								>
									Already have an invite code?
								</Typography>
								<input
									type="text"
									// value={joinCode}
									// onChange={(e) => setJoinCode(e.target.value)}
									placeholder="Invitation code or link"
									style={{
										padding: '1rem',
										fontSize: '1rem',
										borderRadius: '4px',
										border: '1px solid',
										color: themeObj.title,
										borderColor: themeObj.subtitle,
										backgroundColor: themeObj.secondary,
									}}
								/>
								<Button
									fullWidth
									sx={{
										p: 1.5,
										backgroundColor: themeObj.primary,
										color: themeObj.title,
										boxShadow: 'none',
										'&:hover': {
											backgroundColor: themeObj.primary,
										},
									}}
									onClick={() => console.log('Join store button clicked')}
								>
									Join Using Code
								</Button>
							</Stack>
						</motion.div>
					</>
					:
					<Typography gutterBottom sx={{ color: themeObj.title }} variant="h4" textAlign="center">
						{messages.onboarding.failure}
					</Typography>
			}
		</Box>
	);
}