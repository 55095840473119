import React, { useState } from 'react'
import { Stack, Typography, Grid, Paper, useMediaQuery } from '@mui/material'
import Seletor from '../components/Forecast/Selector';
import ForecastGraph from '../components/Forecast/ForecastGraph';
import SearchBar from '../components/Common/SearchBar';

const combinedSalesData = {
	initial: [
		{ date: new Date('2023-02-05'), value: 14000, historical: true },
		{ date: new Date('2023-02-08'), value: 13500, historical: true },
		{ date: new Date('2023-02-11'), value: 12000, historical: true },
		{ date: new Date('2023-02-14'), value: 10500, historical: true },
		{ date: new Date('2023-02-17'), value: 11000, historical: true },
		{ date: new Date('2023-02-20'), value: 13500, historical: true },
		{ date: new Date('2023-02-23'), value: 14000, historical: true },
		{ date: new Date('2023-02-26'), value: 15000, historical: true },
		{ date: new Date('2023-03-01'), value: 13000, historical: true },
		{ date: new Date('2023-03-04'), value: 14500, historical: true },
		{ date: new Date('2023-03-07'), value: 16000, historical: true },
		{ date: new Date('2023-03-10'), value: 15500, historical: true },
		{ date: new Date('2023-03-13'), value: 12000, historical: true },
		{ date: new Date('2023-03-16'), value: 14000, historical: true },
		{ date: new Date('2023-03-19'), value: 13500, historical: true },
		{ date: new Date('2023-03-22'), value: 15000, historical: true },
		{ date: new Date('2023-03-25'), value: 16000, historical: true },
		{ date: new Date('2023-03-28'), value: 17000, historical: true },
		{ date: new Date('2023-03-31'), value: 16500, historical: true },
		{ date: new Date('2023-04-03'), value: 18000, historical: true },
		{ date: new Date('2023-04-06'), value: 17500, historical: true },
		{ date: new Date('2023-04-09'), value: 19000, historical: true },
		{ date: new Date('2023-04-12'), value: 18500, historical: true },
		{ date: new Date('2023-04-15'), value: 17000, historical: true },
		{ date: new Date('2023-04-18'), value: 16000, historical: true },
		{ date: new Date('2023-04-21'), value: 18500, historical: true },
		{ date: new Date('2023-04-24'), value: 18000, historical: true },
		{ date: new Date('2023-04-27'), value: 19500, historical: true },
		{ date: new Date('2023-04-30'), value: 18000, historical: true },
		{ date: new Date('2023-05-03'), value: 19000, historical: true },
		{ date: new Date('2023-05-06'), value: 18500, historical: true },
		{ date: new Date('2023-05-09'), value: 18000, historical: true },
	],
	'2-week': [
		{ date: new Date('2023-05-10'), value: 19000, quantile_50_low: 18800, quantile_50_high: 19200, historical: false },
		{ date: new Date('2023-05-13'), value: 19500, quantile_50_low: 19300, quantile_50_high: 19700, historical: false },
		{ date: new Date('2023-05-16'), value: 20000, quantile_50_low: 19800, quantile_50_high: 20200, historical: false },
		{ date: new Date('2023-05-19'), value: 19500, quantile_50_low: 19300, quantile_50_high: 19700, historical: false },
		{ date: new Date('2023-05-22'), value: 20000, quantile_50_low: 19800, quantile_50_high: 20200, historical: false },
	],
	'1-month': [
		{ date: new Date('2023-05-14'), value: 20500, quantile_50_low: 20300, quantile_50_high: 20700, historical: false },
		{ date: new Date('2023-05-17'), value: 21000, quantile_50_low: 20800, quantile_50_high: 21200, historical: false },
		{ date: new Date('2023-05-20'), value: 21500, quantile_50_low: 21300, quantile_50_high: 21700, historical: false },
		{ date: new Date('2023-05-23'), value: 22000, quantile_50_low: 21800, quantile_50_high: 22200, historical: false },
		{ date: new Date('2023-05-26'), value: 21500, quantile_50_low: 21300, quantile_50_high: 21700, historical: false },
		{ date: new Date('2023-05-29'), value: 22000, quantile_50_low: 21800, quantile_50_high: 22200, historical: false },
		{ date: new Date('2023-06-01'), value: 21500, quantile_50_low: 21300, quantile_50_high: 21700, historical: false },
		{ date: new Date('2023-06-04'), value: 22000, quantile_50_low: 21800, quantile_50_high: 22200, historical: false },
		{ date: new Date('2023-06-07'), value: 22500, quantile_50_low: 22300, quantile_50_high: 22700, historical: false },
		{ date: new Date('2023-06-10'), value: 23000, quantile_50_low: 22800, quantile_50_high: 23200, historical: false },
	],
	'3-month': [
		{ date: new Date('2023-06-07'), value: 22500, quantile_50_low: 22300, quantile_50_high: 22700, historical: false },
		{ date: new Date('2023-06-14'), value: 23500, quantile_50_low: 23300, quantile_50_high: 23700, historical: false },
		{ date: new Date('2023-06-21'), value: 22000, quantile_50_low: 21800, quantile_50_high: 22200, historical: false },
		{ date: new Date('2023-06-28'), value: 24000, quantile_50_low: 23800, quantile_50_high: 24200, historical: false },
		{ date: new Date('2023-07-05'), value: 22500, quantile_50_low: 22300, quantile_50_high: 22700, historical: false },
		{ date: new Date('2023-07-12'), value: 24500, quantile_50_low: 24300, quantile_50_high: 24700, historical: false },
		{ date: new Date('2023-07-19'), value: 23000, quantile_50_low: 22800, quantile_50_high: 23200, historical: false },
		{ date: new Date('2023-07-26'), value: 25000, quantile_50_low: 24800, quantile_50_high: 25200, historical: false },
		{ date: new Date('2023-08-02'), value: 24000, quantile_50_low: 23800, quantile_50_high: 24200, historical: false },
		{ date: new Date('2023-08-09'), value: 25500, quantile_50_low: 25300, quantile_50_high: 25700, historical: false },
		{ date: new Date('2023-08-16'), value: 24500, quantile_50_low: 24300, quantile_50_high: 24700, historical: false },
		{ date: new Date('2023-08-23'), value: 26000, quantile_50_low: 25800, quantile_50_high: 26200, historical: false },
	],
};
const combinedRiskData = {
	initial: [
		{ date: new Date('2023-02-05'), value: 800, historical: true },
		{ date: new Date('2023-02-12'), value: 950, historical: true },
		{ date: new Date('2023-02-19'), value: 750, historical: true },
		{ date: new Date('2023-02-26'), value: 850, historical: true },
		{ date: new Date('2023-03-05'), value: 1000, historical: true },
		{ date: new Date('2023-03-12'), value: 950, historical: true },
		{ date: new Date('2023-03-19'), value: 1000, historical: true },
		{ date: new Date('2023-03-26'), value: 1050, historical: true },
		{ date: new Date('2023-04-02'), value: 900, historical: true },
		{ date: new Date('2023-04-09'), value: 1150, historical: true },
		{ date: new Date('2023-04-16'), value: 1200, historical: true },
		{ date: new Date('2023-04-23'), value: 1050, historical: true },
		{ date: new Date('2023-04-30'), value: 1300, historical: true },
		{ date: new Date('2023-05-07'), value: 1250, historical: true },
	],
	'2-week': [
		{
			date: new Date('2023-05-14'),
			value: 1400,
			quantile_50_low: 1300,
			quantile_50_high: 1500,
			quantile_75_low: 1250,
			quantile_75_high: 1550,
			quantile_95_low: 1200,
			quantile_95_high: 1600,
			historical: false,
		},
		{
			date: new Date('2023-05-21'),
			value: 1450,
			quantile_50_low: 1350,
			quantile_50_high: 1550,
			quantile_75_low: 1300,
			quantile_75_high: 1600,
			quantile_95_low: 1250,
			quantile_95_high: 1650,
			historical: false,
		},
	],
	'1-month': [
		{
			date: new Date('2023-06-14'),
			value: 1500,
			quantile_50_low: 1400,
			quantile_50_high: 1600,
			quantile_75_low: 1350,
			quantile_75_high: 1650,
			quantile_95_low: 1300,
			quantile_95_high: 1700,
			historical: false,
		},
		{
			date: new Date('2023-07-14'),
			value: 1550,
			quantile_50_low: 1450,
			quantile_50_high: 1650,
			quantile_75_low: 1400,
			quantile_75_high: 1700,
			quantile_95_low: 1350,
			quantile_95_high: 1750,
			historical: false,
		},
		{
			date: new Date('2023-08-14'),
			value: 1600,
			quantile_50_low: 1500,
			quantile_50_high: 1700,
			quantile_75_low: 1450,
			quantile_75_high: 1750,
			quantile_95_low: 1400,
			quantile_95_high: 1800,
			historical: false,
		},
		{
			date: new Date('2023-09-14'),
			value: 1650,
			quantile_50_low: 1550,
			quantile_50_high: 1750,
			quantile_75_low: 1500,
			quantile_75_high: 1800,
			quantile_95_low: 1450,
			quantile_95_high: 1850,
			historical: false,
		},
	],
	'3-month': [
		{
			date: new Date('2023-10-14'),
			value: 1700,
			quantile_50_low: 1600,
			quantile_50_high: 1800,
			quantile_75_low: 1550,
			quantile_75_high: 1850,
			quantile_95_low: 1500,
			quantile_95_high: 1900,
			historical: false,
		},
		{
			date: new Date('2023-11-14'),
			value: 1750,
			quantile_50_low: 1650,
			quantile_50_high: 1850,
			quantile_75_low: 1600,
			quantile_75_high: 1900,
			quantile_95_low: 1550,
			quantile_95_high: 1950,
			historical: false,
		},
		{
			date: new Date('2023-12-14'),
			value: 1800,
			quantile_50_low: 1700,
			quantile_50_high: 1900,
			quantile_75_low: 1650,
			quantile_75_high: 1950,
			quantile_95_low: 1600,
			quantile_95_high: 2000,
			historical: false,
		},
		{
			date: new Date('2024-01-14'),
			value: 1850,
			quantile_50_low: 1750,
			quantile_50_high: 1950,
			quantile_75_low: 1700,
			quantile_75_high: 2000,
			quantile_95_low: 1650,
			quantile_95_high: 2050,
			historical: false,
		},
		{
			date: new Date('2024-02-14'),
			value: 1900,
			quantile_50_low: 1800,
			quantile_50_high: 2000,
			quantile_75_low: 1750,
			quantile_75_high: 2050,
			quantile_95_low: 1700,
			quantile_95_high: 2100,
			historical: false,
		},
		{
			date: new Date('2024-03-14'),
			value: 1950,
			quantile_50_low: 1850,
			quantile_50_high: 2050,
			quantile_75_low: 1800,
			quantile_75_high: 2100,
			quantile_95_low: 1750,
			quantile_95_high: 2150,
			historical: false,
		},
		{
			date: new Date('2024-04-14'),
			value: 2000,
			quantile_50_low: 1900,
			quantile_50_high: 2100,
			quantile_75_low: 1850,
			quantile_75_high: 2150,
			quantile_95_low: 1800,
			quantile_95_high: 2200,
			historical: false,
		},
		{
			date: new Date('2024-05-14'),
			value: 2050,
			quantile_50_low: 1950,
			quantile_50_high: 2150,
			quantile_75_low: 1900,
			quantile_75_high: 2200,
			quantile_95_low: 1850,
			quantile_95_high: 2250,
			historical: false,
		},
		{
			date: new Date('2024-06-14'),
			value: 2100,
			quantile_50_low: 2000,
			quantile_50_high: 2200,
			quantile_75_low: 1950,
			quantile_75_high: 2250,
			quantile_95_low: 1900,
			quantile_95_high: 2300,
			historical: false,
		},
		{
			date: new Date('2024-07-14'),
			value: 2150,
			quantile_50_low: 2050,
			quantile_50_high: 2250,
			quantile_75_low: 2000,
			quantile_75_high: 2300,
			quantile_95_low: 1950,
			quantile_95_high: 2350,
			historical: false,
		},
		{
			date: new Date('2024-08-14'),
			value: 2200,
			quantile_50_low: 2100,
			quantile_50_high: 2300,
			quantile_75_low: 2050,
			quantile_75_high: 2350,
			quantile_95_low: 2000,
			quantile_95_high: 2400,
			historical: false,
		},
	],
};
const combinedDemandData = {
	initial: [
		{ date: new Date('2023-02-05'), value: 28000, historical: true },
		{ date: new Date('2023-02-12'), value: 23500, historical: true },
		{ date: new Date('2023-02-19'), value: 23500, historical: true },
		{ date: new Date('2023-02-26'), value: 22000, historical: true },
		{ date: new Date('2023-03-05'), value: 24500, historical: true },
		{ date: new Date('2023-03-12'), value: 26000, historical: true },
		{ date: new Date('2023-03-19'), value: 28500, historical: true },
		{ date: new Date('2023-03-26'), value: 26000, historical: true },
		{ date: new Date('2023-04-02'), value: 22500, historical: true },
		{ date: new Date('2023-04-09'), value: 24000, historical: true },
		{ date: new Date('2023-04-16'), value: 25500, historical: true },
		{ date: new Date('2023-04-23'), value: 28000, historical: true },
		{ date: new Date('2023-04-30'), value: 28500, historical: true },
		{ date: new Date('2023-05-07'), value: 29000, historical: true },
	],
	'2-week': [
		{
			date: new Date('2023-05-14'),
			value: 29500,
			quantile_50_low: 28500,
			quantile_50_high: 30500,
			quantile_75_low: 28000,
			quantile_75_high: 31000,
			quantile_95_low: 27500,
			quantile_95_high: 31500,
			historical: false,
		},
		{
			date: new Date('2023-05-21'),
			value: 30000,
			quantile_50_low: 29000,
			quantile_50_high: 31000,
			quantile_75_low: 28500,
			quantile_75_high: 31500,
			quantile_95_low: 28000,
			quantile_95_high: 32000,
			historical: false,
		},
	],
	'1-month': [
		{
			date: new Date('2023-06-14'),
			value: 30500,
			quantile_50_low: 29500,
			quantile_50_high: 31500,
			quantile_75_low: 29000,
			quantile_75_high: 32000,
			quantile_95_low: 28500,
			quantile_95_high: 32500,
			historical: false,
		},
		{
			date: new Date('2023-07-14'),
			value: 31000,
			quantile_50_low: 30000,
			quantile_50_high: 32000,
			quantile_75_low: 29500,
			quantile_75_high: 32500,
			quantile_95_low: 29000,
			quantile_95_high: 33000,
			historical: false,
		},
		{
			date: new Date('2023-08-14'),
			value: 31500,
			quantile_50_low: 30500,
			quantile_50_high: 32500,
			quantile_75_low: 30000,
			quantile_75_high: 33000,
			quantile_95_low: 29500,
			quantile_95_high: 33500,
			historical: false,
		},
		{
			date: new Date('2023-09-14'),
			value: 32000,
			quantile_50_low: 31000,
			quantile_50_high: 33000,
			quantile_75_low: 30500,
			quantile_75_high: 33500,
			quantile_95_low: 30000,
			quantile_95_high: 34000,
			historical: false,
		},
	],
	'3-month': [
		{
			date: new Date('2023-10-14'),
			value: 32500,
			quantile_50_low: 31500,
			quantile_50_high: 33500,
			quantile_75_low: 31000,
			quantile_75_high: 34000,
			quantile_95_low: 30500,
			quantile_95_high: 34500,
			historical: false,
		},
		{
			date: new Date('2023-11-14'),
			value: 33000,
			quantile_50_low: 32000,
			quantile_50_high: 34000,
			quantile_75_low: 31500,
			quantile_75_high: 34500,
			quantile_95_low: 31000,
			quantile_95_high: 35000,
			historical: false,
		},
		{
			date: new Date('2023-12-14'),
			value: 33500,
			quantile_50_low: 32500,
			quantile_50_high: 34500,
			quantile_75_low: 32000,
			quantile_75_high: 35000,
			quantile_95_low: 31500,
			quantile_95_high: 35500,
			historical: false,
		},
		{
			date: new Date('2024-01-14'),
			value: 34000,
			quantile_50_low: 33000,
			quantile_50_high: 35000,
			quantile_75_low: 32500,
			quantile_75_high: 35500,
			quantile_95_low: 32000,
			quantile_95_high: 36000,
			historical: false,
		},
		{
			date: new Date('2024-02-14'),
			value: 34500,
			quantile_50_low: 33500,
			quantile_50_high: 35500,
			quantile_75_low: 33000,
			quantile_75_high: 36000,
			quantile_95_low: 32500,
			quantile_95_high: 36500,
			historical: false,
		},
		{
			date: new Date('2024-03-14'),
			value: 35000,
			quantile_50_low: 34000,
			quantile_50_high: 36000,
			quantile_75_low: 33500,
			quantile_75_high: 36500,
			quantile_95_low: 33000,
			quantile_95_high: 37000,
			historical: false,
		},
		{
			date: new Date('2024-04-14'),
			value: 35500,
			quantile_50_low: 34500,
			quantile_50_high: 36500,
			quantile_75_low: 34000,
			quantile_75_high: 37000,
			quantile_95_low: 33500,
			quantile_95_high: 37500,
			historical: false,
		},
		{
			date: new Date('2024-05-14'),
			value: 35500,
			quantile_50_low: 34500,
			quantile_50_high: 36500,
			quantile_75_low: 34000,
			quantile_75_high: 37000,
			quantile_95_low: 33500,
			quantile_95_high: 37500,
			historical: false,
		},
		{
			date: new Date('2024-06-14'),
			value: 35500,
			quantile_50_low: 34500,
			quantile_50_high: 36500,
			quantile_75_low: 34000,
			quantile_75_high: 37000,
			quantile_95_low: 33500,
			quantile_95_high: 37500,
			historical: false,
		},
		{
			date: new Date('2024-07-14'),
			value: 35500,
			quantile_50_low: 34500,
			quantile_50_high: 36500,
			quantile_75_low: 34000,
			quantile_75_high: 37000,
			quantile_95_low: 33500,
			quantile_95_high: 37500,
			historical: false,
		},
		{
			date: new Date('2024-08-14'),
			value: 35500,
			quantile_50_low: 34500,
			quantile_50_high: 36500,
			quantile_75_low: 34000,
			quantile_75_high: 37000,
			quantile_95_low: 33500,
			quantile_95_high: 37500,
			historical: false,
		},
	]
}

export default function ForecastPage() {
	const [days, setDays] = useState('2-week');
	const [type, setType] = useState('sale');
	const match = useMediaQuery('(min-width: 600px)');
	const [data, setData] = useState(combinedSalesData);

	React.useEffect(() => {
		if (type === 'sale') {
			setData(combinedSalesData);
		}
		else if (type === 'demand') {
			setData(combinedDemandData);
		}
		else {
			setData(combinedRiskData);
		}
	}, [type])

	const dayOptions = [
		{
			text: '2 weeks',
			value: '2-week'
		},
		{
			text: '1 Month',
			value: '1-month'
		},
		{
			text: '3 Months',
			value: '3-month'
		}
	]

	const typeOptions = [
		{
			text: 'Sale',
			value: 'sale'
		},
		{
			text: 'Demand',
			value: 'demand'
		},
		{
			text: 'Risk',
			value: 'risk'
		}
	]
	return (
		<>
			<Seletor title='Select Days' options={dayOptions} selected={days} setSelected={setDays} />
			{/* <Grid container columnGap={2} rowGap={2} justifyContent="center" marginBottom="20px">
				<Grid item xs={12} sm={5.8}><SearchBar /></Grid>
				<Grid item xs={12} sm={5.8}><Seletor title='Select Days' options={dayOptions} selected={days} setSelected={setDays} /></Grid>
				<Grid item xs={12} sm={5.8}><Seletor title='Select Transaction Type' options={typeOptions} selected={type} setSelected={setType} /></Grid>
			</Grid> */}
			<ForecastGraph days={days} isPhone={match} combinedData={data} />
		</>
	);
}