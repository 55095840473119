import React, { useState, useEffect, createContext, useContext } from "react";
import { auth } from "../services/firebase";
import { signInWithPopup, GoogleAuthProvider, getAdditionalUserInfo, signOut as so } from "firebase/auth";
import { viewUser } from "../apis/userApi";
import { viewStore } from "../apis/storeApi";
import useLocalStorage from "../hooks/useLocalStorage";;

const AuthContext = createContext({ user: null });

export const AuthProvider = (props) => {
	const [user, setUser] = useState(null);
	const [mongoUser, mongoUserDispatch] = useLocalStorage('user');
	const [store, storeDispatch] = useLocalStorage('store');

	const signIn = async () => {
		const provider = new GoogleAuthProvider();
		const res = await signInWithPopup(auth, provider);
	}
	const signOut = () => {
		console.log('here32');
		mongoUserDispatch({ type: "REMOVE" })
		storeDispatch({ type: "REMOVE" })
		setUser(null);
		return so(auth);
	}

	const refresh = () => {
		if (!mongoUser && user) {
			const getMongoUser = async () => {
				const response = await viewUser(user?.uid);
				if (response.success) mongoUserDispatch({ type: "SET", payload: response.data });
				console.log("User set");
			}
			getMongoUser();
		}
		if (!store && mongoUser) {
			const getStore = async () => {
				const response = await viewStore(user?.uid);
				if (response.success) storeDispatch({ type: "SET", payload: response.data });
				console.log("Store set");
			}
			getStore();
		}
	}

	useEffect(() => {
		auth.onAuthStateChanged((user) => {
			setUser(user);
		});
	}, []);

	useEffect(() => {
		refresh();
	})

	const value = {
		user,
		mongoUser,
		store,
		signIn,
		signOut,
		refresh
	}

	return (
		<AuthContext.Provider value={value}>
			{props.children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => {
	return useContext(AuthContext);
};