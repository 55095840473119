// RedirectionContext.js
import React, { createContext, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const RedirectionContext = createContext();

export const RedirectionProvider = ({ children }) => {
    const [redirectionData, setRedirectionData] = useState({});
    const navigate = useNavigate();
	const location = useLocation();

    const setRedirection= (path, data) => {
		const prevpath = location.pathname;
		console.log(prevpath);
        setRedirectionData({ path: path, data: data, initialPage: prevpath.slice(1) });
        navigate(path);
    };

    return (
        <RedirectionContext.Provider value={{ redirectionData, setRedirection }}>
            {children}
        </RedirectionContext.Provider>
    );
};

export const useRedirection = () => {
    return useContext(RedirectionContext);
};
