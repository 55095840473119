import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from "@mui/material";
import 'dayjs/locale/en-in';
import { useTheme } from "../../context/ThemeContext";

const DateFilter = ({ label, value, setValue }) => {
	const { themeObj } = useTheme();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'center',
					position: 'relative',
				}}
			>
				<DatePicker
					label={label}
					disableFuture
					value={value}
					onChange={setValue}
					format="DD/MM/YYYY"
					InputLabelProps={{
						sx: {
							color: themeObj.title,
							fontSize: '0.8rem',
							'&.Mui-focused': {
								color: themeObj.primary
							},
						}
					}}
					slotProps={{
						field: { clearable: true },
						textField: {
							sx: {
								width: '100%',
								'& .MuiOutlinedInput-root': {
									'&.Mui-focused fieldset': {
										borderColor: themeObj.primary,
									},
									'& fieldset': {
										borderColor: themeObj.subtitle,
									},
								}
							},
							InputLabelProps: {
								sx: {
									color: themeObj.title,
									fontSize: '0.8rem',
									'&.Mui-focused': {
										color: themeObj.primary
									},
								}
							},
							InputProps: {
								sx: {
									color: themeObj.title,
									fontSize: '0.8rem',
								},
							}
						},
						actionBar: ({ wrapperVariant }) => ({
							actions: wrapperVariant === 'desktop' ? [] : ['cancel', 'accept', 'clear'],
						}),
					}}

				/>
			</Box>
		</LocalizationProvider>
	)
};

export default DateFilter;