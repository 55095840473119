import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/en-in';
import { useTheme } from "../../context/ThemeContext";
import dayjs from "dayjs";

export default function DateField(props) {
	const { themeObj } = useTheme();

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-in">
			<DatePicker
				{...props}
				value={props.value} // Ensuring value is handled as dayjs object
				onChange={(newValue) => props.onChange(props.name, newValue ? newValue : '')} // Formatting to string on change
				slotProps={{
					field: { clearable: true },
					textField: {
						size: "small",
						sx: {
							width: '100%',
							'& .MuiOutlinedInput-root': {
								'&.Mui-focused fieldset': {
									borderColor: themeObj.primary,
								},
								'& fieldset': {
									borderColor: themeObj.subtitle,
								},
							}
						},
						InputLabelProps: {
							sx: {
								color: themeObj.title,
								fontSize: '1rem',
								'&.Mui-focused': {
									color: themeObj.primary
								},
							}
						},
						InputProps: {
							sx: {
								color: themeObj.title,
								fontSize: '1rem',
							},
						}
					},
					actionBar: ({ wrapperVariant }) => ({
						actions: wrapperVariant === 'desktop' ? [] : ['cancel', 'accept'],
					}),
				}}
			/>
		</LocalizationProvider>
	);
}
