// Define base URL
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Define functions
async function createUser(AUTH_TOKEN, userData = {}) {
	try {
		const response = await fetch(`${BASE_URL}/users/create_user/`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${AUTH_TOKEN}`,
			},
			body: JSON.stringify(userData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to create user: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to create user." };
	}
}

async function viewUser(AUTH_TOKEN) {
	try {
		const response = await fetch(`${BASE_URL}/users/view_user/`, {
			headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to view user: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to view user." };
	}
}

async function updateUser(AUTH_TOKEN, updatedData = {}) {
	try {
		const response = await fetch(`${BASE_URL}/users/update_user/`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${AUTH_TOKEN}`,
			},
			body: JSON.stringify(updatedData),
		});
		if (!response.ok) {
			return { success: false, message: `Failed to update user: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to update user." };
	}
}

async function delinkStore(AUTH_TOKEN) {
	try {
		const response = await fetch(`${BASE_URL}/users/delink_store/`, {
			method: 'POST', // or DELETE, depending on your API
			headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to delink store: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to delink store." };
	}
}

async function deleteUser(AUTH_TOKEN) {
	try {
		const response = await fetch(`${BASE_URL}/users/delete_user/`, {
			method: 'DELETE',
			headers: { Authorization: `Bearer ${AUTH_TOKEN}` },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to delete user: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to delete user." };
	}
}

// Exporting functions
export {
	createUser,
	viewUser,
	updateUser,
	delinkStore,
	deleteUser,
}