import { Chip, styled } from '@mui/material';

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import RefreshRoundedIcon from '@mui/icons-material/RefreshRounded';

const CustomChip = styled(Chip)(({ theme, c, bgc, mw='unset' }) => ({
	fontWeight: 400,
	fontSize: 14,
	minWidth: mw,
	width: "100%",
	color: c,
	border: '1px solid',
	borderColor: "transparent",
	backgroundColor: bgc,
}));

const CustomChipSelected = styled(Chip)(({ theme, c }) => ({
	fontWeight: 400,
	fontSize: 13,
	minWidth: 110,
	color: c,
	border: '1px solid',
	backgroundColor: 'transparent',
}));

const config = {
	sale: <PaidOutlinedIcon fontSize="small" />,
	return: <RefreshRoundedIcon fontSize="small" />,
	purchase: <ShoppingCartOutlinedIcon fontSize="small" />,
}

export { CustomChip, CustomChipSelected, config }