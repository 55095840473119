async function transcribe(blob, language = 'en') {
	const formData = new FormData();
	formData.append('file', blob, 'sample_audio.m4a');
	formData.append('model', 'distil-whisper-large-v3-en');
	formData.append('language', 'en');

	const response = await fetch('https://api.groq.com/openai/v1/audio/transcriptions', {
		method: 'POST',
		headers: {
			'Authorization': `Bearer ${process.env.REACT_APP_GROQ_API_KEY}`, // Replace with your actual API key
		},
		body: formData
	});

	// Check for errors
	if (!response.ok) {
		console.log(`HTTP error! status: ${response.status}`);
	}

	// Parse the response
	const transcription = await response.json();
	return transcription.text;
}

// Example usage
export { transcribe };

