import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogActions, DialogContent, IconButton, Typography, Stack, ToggleButton, ToggleButtonGroup, Switch, Box, Collapse, CircularProgress, Alert } from '@mui/material';
import CustomButton from '../Base/CustomButton';
import CloseIcon from '@mui/icons-material/Close';
import QRCode from "react-qr-code";
import PdfPopup from '../PDF/PdfPopup';

const Android12Switch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2,
		backgroundColor: 'rgba(250, 10, 10, 0.6)',
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2,
	},
	'& .MuiSwitch-switchBase': {
		color: 'rgb(250, 10, 10)',
		'&.Mui-checked': {
			color: 'rgba(70, 170, 10)',
			'& + .MuiSwitch-track': {
				backgroundColor: 'rgba(70, 170, 10, 0.6)',
			}
		}
		// '& .MuiSwitch-checked': {
		// 	'& + .MuiSwitch-track': {
		// 	backgroundColor: 'rgba(70, 170, 10, 0.8)',
		// 	},
		// }
	}
}));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}));

function Online({ total }) {
	return (
		<QRCode
			value={`upi://pay?pa=harsh90731%40oksbi&pn=Abhijit&tn=Test&am=${total}&cu=INR`}
			style={{ maxWidth: "300px", width: "100%" }}
		/>
	)
}

function Offline({ total }) {
	return (
		<Typography gutterBottom>
			Please Pay {total}
		</Typography>
	)
}

function Payment({ checked, total }) {
	return (
		<Box style={{ height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			{checked ?
				<Collapse in={checked}><Online total={total} /></Collapse>
				:
				<Collapse in={!checked}><Offline total={total} /></Collapse>
			}
		</Box>
	)
}

export default function PaymentDialog({ onOpen, submit, total, txnId, resetForm, feedback, setPaymentMethod }) {
	const [open, setOpen] = useState(false);
	const [type, setType] = useState();
	const [link, setLink] = useState('');
	const [checked, setChecked] = useState(false); // false for offline, true for online
	const [billGenerated, setBillGenerated] = useState(false);
	const [submitted, setSubmitted] = useState(false);
	// const [billOpen, setBillOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		if (billGenerated) {
			resetForm();
		}
		setOpen(false);
	}

	const handleClickOpen = () => {
		onOpen(() => setOpen(true));
	};

	const handleSubmit = () => {
		setLoading(true);
		submit();
		setLoading(false);
		setSubmitted(true);
	}

	const handleOpenBill = async () => {
		const getData = async () => {
			const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/transaction/bill?store_id=MUM1957MH&transaction_id=${txnId}`,
				{
					headers: {
						"Access-Control-Allow-Origin": "*",
					},
				}
			)
			if (response.ok) {
				const res = await response.json();
				if (res.status === "success") {
					console.log("fetched bill", res);
					setLink(res.bill_link);
					setBillGenerated(true);
					window.open(res.bill_link, '_blank');
				}
				else {
					console.log("something went wrong, please try again");
				}
			}
		}
		if (!billGenerated) {
			await getData();
		}
		// setBillOpen(true)
		else {
			window.open(link, '_blank');
		}
	};

	useEffect(() => {
		setPaymentMethod(checked);
	}, [checked])

	return (
		<React.Fragment>
			<CustomButton fullWidth onClick={handleClickOpen}>Create Payment</CustomButton>
			<Dialog
				onClose={handleClose}
				aria-labelledby="payment-category"
				open={open}
				sx={{ minHeight: "70vh" }}
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle sx={{ m: 0, p: 2 }} id="payment-category">
					Select Payment Method
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: 12,
						top: 12,
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent sx={{ opacity: submitted ? 0 : 1, transition: 'opacity 0.5s ease' }}>
					<Stack direction="row" gap={2} justifyContent="center" alignItems="center">
						<Typography>Cash</Typography>
						<Android12Switch checked={checked} onChange={(e) => setChecked(e.target.checked)} size="large" />
						<Typography>UPI</Typography>
					</Stack>
					<Payment checked={checked} total={total} />
				</DialogContent>
				<DialogContent sx={{ width: "100%", opacity: submitted ? 1 : 0, transition: 'all 250ms ease 250ms', position:"absolute", transform: "translate(50%, -50%)", top: "50%", right: "50%" }}>
					<Alert severity={feedback.success ? "success" : "error"}>{feedback.message}</Alert>
				</DialogContent>
				<DialogActions>
					{submitted ?
						<CustomButton fullWidth onClick={handleOpenBill}>Open Bill</CustomButton>
						:
						(loading ?
							<CustomButton disabled startIcon={<CircularProgress size={20} sx={{ color: 'white' }} />} fullWidth onClick={handleSubmit}>Submit All</CustomButton>
							:
							<CustomButton fullWidth onClick={handleSubmit}>Submit All</CustomButton>
						)
					}
				</DialogActions>
				{/* <PdfPopup link={link} loaded={billGenerated} open={billOpen} setOpen={setBillOpen}/> */}
			</Dialog>
		</React.Fragment>
	);
}
