const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const AUTH_TOKEN = "Bearer 22335564899";

async function searchAudio(base64Audio) {
    try {
        const response = await fetch(`${BASE_URL}/audios/search_audio/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: AUTH_TOKEN,
            },
            body: JSON.stringify({ base64: base64Audio }),
        });
        if (!response.ok) {
            return { success: false, message: `Failed to search audio: ${response.statusText}` };
        }
        const data = await response.json();
        return { success: true, data };
    } catch (error) {
        return { success: false, message: "Network error, unable to search audio." };
    }
}

export { searchAudio };
