/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { IconButton, Stack, Box, Typography, Paper, CircularProgress, Chip, Button, TextField, MenuItem } from "@mui/material";
import { css, keyframes } from "@emotion/react";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import EditIcon from '@mui/icons-material/Edit';
import ItemDetailField from "../../components/Item/ItemDetailField";
import ItemDetailEdit from "../../components/Item/ItemDetailEdit";
import CancelIcon from '@mui/icons-material/Cancel';
import SaveRoundedIcon from '@mui/icons-material/SaveRounded';
import { useTheme } from "../../context/ThemeContext";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import TagsEdit from "../../components/Item/TagsEdit";
import BarcodeEdit from "../../components/Item/BarcodeEdit";
import { updateItem } from "../../apis/itemApi";

const validationSchema = Yup.object({
	item_name: Yup.string().required('Item name is required'),
	item_category: Yup.string().required('Category is required'),
	item_description: Yup.string(),
	barcode_id: Yup.string(),
	hsn_sac_code: Yup.string(),
	quantity: Yup.number().typeError('Quantity must be a number').required('Quantity is required').min(0, 'Quantity must be positive'),
	qt_type: Yup.string().required('Quantity type is required'),
	cost_price: Yup.number().typeError('Cost Price must be a number').required('Cost price is required').positive('Cost price must be positive'),
	selling_price: Yup.number().typeError('Selling Price must be a number').required('Selling price is required').positive('Selling price must be positive'),
	overstock_qt: Yup.number().typeError('Overstock Quantity must be a number').required('Overstock quantity is required').min(0, 'Overstock quantity must be positive'),
	understock_qt: Yup.number().typeError('Undetstock Quantity must be a number').required('Understock quantity is required').min(0, 'Understock quantity must be positive'),
});

export default function ItemDetails({ onBack, data, editCallback }) {
	const [loaded, setLoaded] = useState(true);
	const { themeObj } = useTheme();
	const [isEditing, setIsEditing] = useState(false);
	const [isSaving, setIsSaving] = useState(false);

	const colorCycle = keyframes`
    0% {
      background-color: var(--primary-color);
    }
	60% {
      background-color: var(--primary-color);
    }
	100% {
      background-color: var(--alternate-color);
    }
  `;

	const primaryColor = data.quantity < data.understock_qt
		? themeObj.chip.red
		: data.quantity > data.overstock_qt
			? themeObj.chip.yellow
			: themeObj.chip.green;

	const alternateColor = data.quantity < data.understock_qt
		? themeObj.chip.red_background
		: data.quantity > data.overstock_qt
			? themeObj.chip.yellow_background
			: themeObj.chip.green_background;

	const categories = ['kilograms', 'litres', 'pieces', 'units']

	const formik = useFormik({
		initialValues: {
			item_name: data.item_name ?? '',
			item_category: data.item_category ?? '',
			item_description: data.item_description ?? '',
			item_tags: data.item_tags ?? [],
			barcode_id: data.barcode_id ?? '',
			hsn_sac_code: data.hsn_sac_code ?? '',
			quantity: data.quantity ?? '',
			qt_type: data.qt_type ?? '',
			cost_price: data.cost_price ?? '',
			selling_price: data.selling_price ?? '',
			overstock_qt: data.overstock_qt ?? '',
			understock_qt: data.understock_qt ?? '',
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			try {
				setIsSaving(true);
				const response = await updateItem(data.item_id, values);
				if (response.success) {
					setIsEditing(false);
					editCallback(values);
					// Optionally refresh data or show success message
				} else {
					// Handle error
					console.error('Failed to update item');
				}
			} catch (error) {
				console.error('Error updating item:', error);
			} finally {
				setIsSaving(false);
			}
		},
	});

	const renderField = (label, field) => {
		if (isEditing) {
			return (
				<ItemDetailEdit
					heading={label}
					name={field}
					placeholder={label}
					value={formik.values[field]}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					error={formik.touched[field] && Boolean(formik.errors[field])}
					helperText={formik.touched[field] && formik.errors[field]}
				/>
			);
		}
		return <ItemDetailField label={label} value={data[field]} />;
	};

	if (!loaded) {
		return (
			<Box sx={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				backgroundColor: 'rgba(0, 0, 0, 0.6)',
				backdropFilter: "blur(5px)",
				height: '100%',
				width: '100%',
				position: 'absolute',
				top: 0,
				left: 0
			}}>
				<CircularProgress size={70} />
			</Box>
		);
	}

	return (
		<form onSubmit={formik.handleSubmit}>
			<Stack gap={1}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
					<IconButton onClick={onBack}>
						<KeyboardBackspaceRoundedIcon htmlColor={themeObj.primary} />
					</IconButton>
					{!isEditing ? (
						<IconButton onClick={() => setIsEditing(true)}>
							<EditIcon htmlColor={themeObj.primary} />
						</IconButton>
					) : (
						<Stack direction="row" spacing={0.5}>
							<IconButton onClick={() => setIsEditing(false)}>
								<CancelIcon htmlColor={themeObj.primary} />
							</IconButton>
							<IconButton type="submit" disabled={isSaving}>
								{isSaving ? <CircularProgress size={24} /> : <SaveRoundedIcon htmlColor={themeObj.primary} />}
							</IconButton>
						</Stack>
					)}
				</Box>

				<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: isEditing ? themeObj.card : themeObj.secondary }}>
					{isEditing ? (
						<>
							<TextField
								size="small"
								multiline
								fullWidth
								heading="Item Name"
								name="item_name"
								placeholder="Item Name*"
								value={formik.values.item_name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.item_name && Boolean(formik.errors.item_name)}
								helperText={formik.touched.item_name && formik.errors.item_name}
								InputProps={{
									sx: {
										fontSize: '1.3rem',
										color: themeObj.contrast,
										'& fieldset': {
											border: "none",
										},
										p: 0
									},
								}}
							/>
							<TagsEdit
								data={formik.values.item_tags}
								setVal={(val) => formik.setFieldValue('item_tags', val)}
							/>
							<TextField
								size="small"
								fullWidth
								heading="Category"
								name="item_category"
								placeholder="Category*"
								value={formik.values.item_category}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.item_category && Boolean(formik.errors.item_category)}
								helperText={formik.touched.item_category && formik.errors.item_category}
								InputProps={{
									sx: {
										fontWeight: 500,
										fontSize: '0.8rem',
										color: themeObj.contrast,
										'& fieldset': {
											border: "none",
										},
										'& input': {
											p: 0
										}
									},
								}}
							/>
							<TextField
								size="small"
								fullWidth
								multiline
								heading="Description"
								name="item_description"
								placeholder="Description"
								value={formik.values.item_description}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.item_description && Boolean(formik.errors.item_description)}
								helperText={formik.touched.item_description && formik.errors.item_description}
								InputProps={{
									sx: {
										fontSize: '0.8rem',
										color: themeObj.contrast,
										'& fieldset': {
											border: "none",
										},
										p: 0
									},
								}}
							/>
						</>
					) : (
						<>
							<Stack flexDirection="row" alignItems="center" gap={2}>
								<Typography color={themeObj.title} fontWeight={500} fontSize="1.3rem">
									{data.item_name}
								</Typography>
								<div
									css={css`
									animation: ${colorCycle} 2s ease infinite alternate;
								  `}
									style={{
										height: 8,
										width: 8,
										borderRadius: 5,
										"--primary-color": primaryColor,
										"--alternate-color": alternateColor,
										backgroundColor: primaryColor,
									}}
								/>
							</Stack>
							<Stack flexDirection="row" gap={0.5} sx={{ my: 0.5 }}>
								{data.item_tags?.map((t, i) => (
									<Chip
										variant="outlined"
										size="small"
										color="warning"
										key={i}
										label={t}
									/>
								))}
							</Stack>
							<Typography color={themeObj.title} fontWeight={500} fontSize="0.8rem">
								{data.item_category}
							</Typography>
							<Typography color={themeObj.subtitle} fontSize="0.8rem">
								{data.item_description}
							</Typography>
						</>
					)}
				</Paper>

				<Stack gap={1} flexDirection="row">
					{isEditing ?
						<BarcodeEdit
							heading="Barcode ID"
							name="barcode_id"
							placeholder="Barcode ID"
							value={formik.values.barcode_id}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							setval={(val) => formik.setFieldValue("barcode_id", val)}
							error={formik.touched.barcode_id && Boolean(formik.errors.barcode_id)}
							helperText={formik.touched.barcode_id && formik.errors.barcode_id}
						/>
						:
						<ItemDetailField label="Barcode ID" value={data.barcode_id} />
					}
					{renderField("HSN/SAC Code", "hsn_sac_code")}
				</Stack>

				<Stack gap={1} flexDirection="row">
					{renderField("Quantity", "quantity")}
					{isEditing ?
						<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: isEditing ? themeObj.card : themeObj.secondary }}>
							<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">Quantity Type:</Typography>
							<TextField
								select
								size="small"
								variant="standard"
								fullWidth
								heading="Quantity Type"
								name="qt_type"
								value={formik.values.qt_type}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								error={formik.touched.qt_type && Boolean(formik.errors.qt_type)}
								helperText={formik.touched.qt_type && formik.errors.qt_type}
								sx={{
									'& div:first-of-type': {
										p: 0
									},
									'& .MuiSelect-icon': {
										color: themeObj.title
									}
								}}
								InputProps={{
									sx: {
										fontSize: '1rem',
										color: themeObj.contrast,
										'&::before': {
											borderBottomColor: themeObj.subtitle
										},
										'&::after': {
											borderBottomColor: themeObj.primary
										}
									},
								}}
								SelectProps={{

									MenuProps: {
										sx: {
											'& .MuiMenu-paper': {
												backgroundColor: themeObj.card,
											},
											'& .MuiMenu-list': {
												p: 0
											}
										}
									}
								}}
							>
								{categories.map((e, i) => (
									<MenuItem sx={{ fontSize: "0.8rem", '&.Mui-selected': { backgroundColor: themeObj.secondary }, color: themeObj.subtitle }} key={i} value={e}>
										{e}
									</MenuItem>
								))}
							</TextField>
						</Paper>

						:
						<ItemDetailField label="Quantity Type" value={data.qt_type} />
					}
				</Stack>

				<Stack gap={1} flexDirection="row">
					{renderField("Cost Price", "cost_price")}
					{renderField("Selling Price", "selling_price")}
				</Stack>

				<Stack gap={1} flexDirection="row">
					{renderField("Overstock Quantity", "overstock_qt")}
					{renderField("Understock Quantity", "understock_qt")}
				</Stack>
			</Stack>
		</form>
	);
}