import React, { useState, useRef, useEffect } from 'react';
import { IconButton, Box, CircularProgress, Stack } from '@mui/material';

import { Player } from '@lordicon/react';
import CustomButton from '../Base/CustomButton';
import { useLocale } from '../../context/LocaleContext';
const PAUSEPLAYBTN = require('../../data/playpause.json');
const CLOSE = require('../../data/close.json')

const CustomMediaPlayer = ({ submit, mediaUrl, clearAudio }) => {
	const [isPlaying, setIsPlaying] = useState(false);
	const [progress, setProgress] = useState(0);
	const audioRef = useRef(new Audio(mediaUrl));
	const pausePlayRef = useRef(null);
	const closeRefButton = useRef(null);
	const closeRef = useRef(null);
	const { messages } = useLocale();

	useEffect(() => {
		const audio = audioRef.current;

		const updateProgress = () => {
			setProgress((audio.currentTime / audio.duration) * 100);
		};

		const handleHover = () => {
			closeRef.current?.playFromBeginning();
		}

		audio.addEventListener('timeupdate', updateProgress);
		audio.addEventListener('ended', () => setIsPlaying(false));
		const node = closeRefButton.current;

		if (node) {
			node.addEventListener('mouseenter', handleHover);
		}

		return () => {
			audio.removeEventListener('timeupdate', updateProgress);
			audio.removeEventListener('ended', () => setIsPlaying(false));
			if (node) {
				node.removeEventListener('mouseenter', handleHover);
			}
		};

	}, []);


	const togglePlayPause = () => {
		const audio = audioRef.current;
		if (isPlaying) {
			audio.pause();
		} else {
			audio.play();
		}
		setIsPlaying(!isPlaying);
	};

	useEffect(() => {
		pausePlayRef.current?.playFromBeginning();
	}, [isPlaying])

	return (
		<>
			<Box position="relative" display="inline-block">
				<CircularProgress
					variant="determinate"
					value={progress}
					size={100}
					thickness={2}
					sx={{ color: isPlaying ? '#F5B60B' : 'transparent' }}
				/>
				<IconButton
					onClick={togglePlayPause}
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						zIndex: 1,
					}}
				>
					<Player
						ref={pausePlayRef}
						icon={PAUSEPLAYBTN}
						state={isPlaying ? 'morph-play-pause' : 'morph-pause-play'}
						size={100}
					/>
				</IconButton>
			</Box>
			<Stack
				flexDirection='column'
				gap={2}
				alignItems='center'
			>
				<IconButton
					onClick={clearAudio}
					ref={closeRefButton}
					sx={{ height: 80, width: 80 }}

				>
					<Player
						ref={closeRef}
						icon={CLOSE}
						size={60}
					/>
				</IconButton>
				<CustomButton onClick={submit} variant="contained" sx={{ width: 150, height: 40 }}>{messages.common.confirm}</CustomButton>
			</Stack>
		</>
	);
};

export default CustomMediaPlayer;
