const BASE_URL = process.env.REACT_APP_BACKEND_URL;
const AUTH_TOKEN = "Bearer 22335564899";

async function addInstantSellTransaction(items = []) {
	try {
		const response = await fetch(`${BASE_URL}/instant_sell/add_instant_sell_transaction`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: AUTH_TOKEN,
			},
			body: JSON.stringify(items), // Send even if empty, API should handle validation
		});
		if (!response.ok) {
			return { success: false, message: `Failed to add instant sell transaction: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to add instant sell transaction." };
	}
}

async function updateInstantSellTransaction(transactionId, updatedData = {}) {
	if (!transactionId) {
		return { success: false, message: "Transaction ID is required." };
	}

	try {
		const response = await fetch(`${BASE_URL}/instant_sell/update_instant_sell_transaction/${transactionId}`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
				Authorization: AUTH_TOKEN,
			},
			body: JSON.stringify(updatedData), // Send even if empty, API should handle validation
		});
		if (!response.ok) {
			return { success: false, message: `Failed to update instant sell transaction: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to update instant sell transaction." };
	}
}

async function viewInstantSellTransaction(options = {}) {
	const url = `${BASE_URL}/instant_sell/view_instant_sale_transaction`;
	const params = new URLSearchParams();

	for (const key in options) {
		if (options[key] !== null && options[key] !== undefined) {
			params.append(key, options[key]);
		}
	}

	try {
		const response = await fetch(`${url}?${params}`, {
			headers: { Authorization: AUTH_TOKEN },
		});
		if (!response.ok) {
			return { success: false, message: `Failed to view instant sell transactions: ${response.statusText}` };
		}
		const data = await response.json();
		return { success: true, data };
	} catch (error) {
		return { success: false, message: "Network error, unable to view instant sell transactions." };
	}
}

export {
	addInstantSellTransaction,
	updateInstantSellTransaction,
	viewInstantSellTransaction,
}