import { IconButton, Paper, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider } from "@mui/material";
import React, { useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from "../../context/ThemeContext";
import BarcodeScanner from "../Common/BarcodeScanner";

export default function BarcodeEdit({ setval, ...props }) {
	const { themeObj } = useTheme();
	const [open, setOpen] = useState(false);
	return (
		<>
			<Paper sx={{ width: "100%", padding: "10px 15px", backgroundColor: themeObj.card }} onClick={() => setOpen(true)}>
				<Typography color={themeObj.subtitle} fontWeight={600} fontSize="0.7rem">{props.heading}:</Typography>
				<Typography color={themeObj.title} fontSize="`1rem">{props.value || 'Barcode ID'}</Typography>
			</Paper>
			<Dialog
				onClose={() => setOpen(false)}
				aria-labelledby="payment-category"
				open={open}
				sx={{ minHeight: "70vh" }}
				PaperProps={{
					sx: {
						backgroundColor: themeObj.secondary,
					}
				}}
				maxWidth='sm'
				fullWidth
			>
				<DialogTitle sx={{ m: 0, p: 2, color: themeObj.title }} id="payment-category">
					Scan Barcode
				</DialogTitle>

				<IconButton
					aria-label="close"
					onClick={() => setOpen(false)}
					sx={{
						position: 'absolute',
						right: 12,
						top: 12,
					}}
				>
					<CloseIcon htmlColor={themeObj.primary} />
				</IconButton>
				<DialogContent
					sx={{
						p: 1,
					}}
				>
					<BarcodeScanner onResult={(val) => setval(val)} />
				</DialogContent>
				<Divider variant="middle" sx={{ marginBottom: '5px', "&::before, &::after": { borderColor: themeObj.subtitle } }}>
					<Typography color={themeObj.title} fontSize="0.8rem">OR</Typography>
				</Divider>
				<DialogContent sx={{ py: 1, px: 2 }}>
					<TextField
						size="small"
						variant="standard"
						fullWidth
						{...props}
						InputProps={{
							sx: {
								fontSize: '1rem',
								py: 0,
								color: themeObj.contrast,
								'&::before': {
									borderBottomColor: themeObj.subtitle
								},
								'&::after': {
									borderBottomColor: themeObj.primary
								},
								'& input': {
									py: 0
								}
							},
						}}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						fullWidth
						sx={{
							backgroundColor: themeObj.primary,
							color: themeObj.title,
							borderRadius: '5px',
							boxShadow: 'none',
							'&:hover': {
								backgroundColor: themeObj.primary_hover,
								borderRadius: '5px',
							}
						}}
						onClick={() => setOpen(false)}
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}