import React from "react";
import { Stack, ToggleButton as Tb, ToggleButtonGroup, Typography, Paper, styled } from "@mui/material";
import { useLocale } from "../../context/LocaleContext";
import { useTheme } from "../../context/ThemeContext";

const ToggleButton = styled(Tb)(({theme, c, bgc, selc, selbgc}) => ({
	backgroundColor: bgc,
	color: c,
	'&.Mui-selected': {
		backgroundColor: selbgc,
		color: selc,
	},
	'&.Mui-selected:hover': {
		backgroundColor: selbgc,
		color: selc,
	},
}));

export default function TransactionSelector({ transaction, setTransaction }) {
	const { messages } = useLocale();
	const { themeObj } = useTheme();

	const handleChange = (event, newTransaction) => {
		if (newTransaction !== null) {
			setTransaction(newTransaction)
		}
	}

	const colorOpts = {
		c: themeObj.subtitle,
		selc: themeObj.primary,
		bgc: themeObj.card,
		selbgc: themeObj.secondary
	}

	return (
		<ToggleButtonGroup
			value={transaction}
			exclusive
			onChange={handleChange}
			fullWidth
			sx={{ marginTop: "5px", marginBottom: "5px" }}
		>
			<Paper elevation={3} sx={{ width: "100%", flexDirection: "row", display: "flex", backgroundColor: themeObj.background }}>
				<ToggleButton {...colorOpts} value="sale" sx={{ padding: "7px" }}>
					<Stack flexDirection="row" justifyContent="center" alignItems="center">
						<Typography fontSize="0.7rem">{messages.barcode.sale}</Typography>
					</Stack>
				</ToggleButton>
				<ToggleButton {...colorOpts} value="purchase" sx={{ padding: "7px" }}>
					<Stack flexDirection="row" justifyContent="center" alignItems="center">
						<Typography fontSize="0.7rem">{messages.barcode.purchase}</Typography>
					</Stack>
				</ToggleButton>
				<ToggleButton {...colorOpts} value="return" sx={{ padding: "7px" }}>
					<Stack flexDirection="row" justifyContent="center" alignItems="center">
						<Typography fontSize="0.7rem">{messages.barcode.return}</Typography>
					</Stack>
				</ToggleButton>
			</Paper>
		</ToggleButtonGroup >
	);
}