import React, { useState } from "react";
import { Box, Typography, Stack, Divider, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CustomButton from "../Base/CustomButton";
import { useTheme } from "../../context/ThemeContext";
import { AddRounded, DeleteOutlineRounded, RemoveRounded, RemoveShoppingCartOutlined, ClearAllRounded } from "@mui/icons-material";

const drawerBleeding = 56;

const Puller = styled('div')(({ theme }) => ({
	width: 30,
	height: 6,
	borderRadius: 3,
	position: 'absolute',
	top: 8,
	left: 'calc(50% - 15px)',
}));

const Cart = ({ cart, addItem, deleteFromCart, removeSingleItem, clearCart, makeTransaction }) => {
	const [open, setOpen] = useState(false);

	const { themeObj } = useTheme();

	const toggleDrawer = (newOpen) => () => {
		setOpen(newOpen);
	};

	return (
		<>
			{/* <CustomButton fullWidth onClick={toggleDrawer(true)}>
				Open Cart
			</CustomButton> */}
			<SwipeableDrawer
				anchor="bottom"
				open={open}
				onClose={toggleDrawer(false)}
				onOpen={toggleDrawer(true)}
				swipeAreaWidth={drawerBleeding}
				disableSwipeToOpen={false}
				ModalProps={{
					keepMounted: true,
				}}
				sx={{ 
					'& .MuiDrawer-paper': {
						height: `calc(50% - ${drawerBleeding}px)`,
						overflow: 'visible',
					},
				}}
			>
				<Box
					sx={{
						backgroundColor: themeObj.secondary,
						position: 'absolute',
						top: -drawerBleeding,
						borderTopLeftRadius: 8,
						borderTopRightRadius: 8,
						visibility: 'visible',
						right: 0,
						left: 0,
					}}
				>
					<Puller sx={{ backgroundColor: themeObj.subtitle }} />
					<Stack flexDirection="row" justifyContent="space-between" alignItems="center">
						<Typography sx={{ p: 2 }} color={themeObj.title} fontSize="1.2rem">Cart <span style={{ fontSize: "1rem", color: themeObj.subtitle }}>({cart.length} items)</span></Typography>
						{open &&
							<IconButton onClick={clearCart}>
								<Stack sx={{ px: 2 }} flexDirection="row" gap={1} alignItems="center">
									<Typography color={themeObj.title} fontSize="0.8rem">Clear Cart</Typography>
									<ClearAllRounded fontSize="large" htmlColor={themeObj.primary} />
								</Stack>
							</IconButton>
						}

					</Stack>
				</Box>
				<Divider sx={{ borderWidth: "2px", borderColor: themeObj.subtitle }} />
				<Stack sx={{ backgroundColor: themeObj.secondary, px: 2, pb: 2, height: '100%', overflow: 'auto' }}>
					{cart.length > 0 ?
						<Stack flexDirection="column">
							{cart.map((item, index) => (
								<Stack key={index} justifyContent="space-between" alignItems="center" flexDirection="row" gap={2} sx={{ py: 2 }}>
									<Stack>
										<Typography sx={{ color: themeObj.title, textOverflow: "ellipsis", overflow: "hidden", height: "56px" }} fontSize="1.1rem" fontWeight={50}>{item.item_name}</Typography>
										<Stack gap={4} alignItems="center" flexDirection="row">
											<Typography sx={{ color: themeObj.title }} fontSize="1rem" fontWeight={600}>x {item.quantity}</Typography>
											<Typography sx={{ color: themeObj.title }} fontSize="1rem" fontWeight={600}>₹ {item.total_price}</Typography>
										</Stack>
									</Stack>
									<Stack flexDirection="row" gap={0.5}>
										<IconButton onClick={() => addItem(item)} sx={{ height: "fit-content", backgroundColor: themeObj.chip.green_background }}><AddRounded htmlColor={themeObj.chip.green} /></IconButton>
										<IconButton onClick={() => removeSingleItem(item)} sx={{ height: "fit-content", backgroundColor: themeObj.chip.yellow_background }}><RemoveRounded htmlColor={themeObj.chip.yellow} /></IconButton>
										<IconButton onClick={() => deleteFromCart(item)} sx={{ height: "fit-content", backgroundColor: themeObj.chip.red_background }}><DeleteOutlineRounded htmlColor={themeObj.chip.red} /></IconButton>
									</Stack>
								</Stack>
							))}
							<Divider sx={{ borderWidth: "0.5px", borderColor: themeObj.subtitle }} />
							<Stack>
								<Typography textAlign="right" sx={{ color: themeObj.title }} fontSize="1.2rem">Total: ₹ {cart.reduce((acc, item) => acc + item.total_price, 0)}</Typography>
							</Stack>
							<CustomButton fullWidth onClick={makeTransaction} sx={{ mt: 2 }}>Checkout</CustomButton>
						</Stack>
						:
						<Stack flexDirection="row" gap={2} sx={{ p: 2, mt: 2, borderColor: themeObj.subtitle, borderStyle: "dashed", borderRadius: 2, borderWidth: "2px" }}>
							<RemoveShoppingCartOutlined htmlColor={themeObj.primary} />
							<Typography sx={{ color: themeObj.title }}>No Items in Cart</Typography>
						</Stack>
					}
				</Stack>
			</SwipeableDrawer>
		</>
	);
};

export default Cart;
