import React, { useState, useRef, useEffect } from 'react';
import { Button, styled, IconButton } from '@mui/material';
import { Player } from '@lordicon/react';
import { useTheme } from '../../context/ThemeContext';
const MICROPHONE = require('../../data/microphone.json');

const CustomButton = styled(Button)(() => ({
	backgroundColor: '#F5B60B',
	color: '#F5B60B',
	borderRadius: '50%',
	boxShadow: 'none',
	width: 'min-content',
	'&:hover': {
		backgroundColor: '#F6AE09',
	},
	'&.Mui-disabled': {
		backgroundColor: '#D1D1D1',
		color: '#212121',
	}
}));

export default function AnimatedMic({ onClick, isRecording }) {

	const [state, setState] = useState('in-reveal');
	const [loaded, setLoaded] = useState(false);
	const { themeObj } = useTheme();

	const playerRef = useRef(null);

	useEffect(() => {
		playerRef.current?.playFromBeginning();
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (state === 'in-reveal' && loaded) {
			setState('loop-recording');
			playerRef.current?.playFromBeginning();
		}
		if (state === 'loop-recording' && loaded) {
			setState('in-reveal');
			playerRef.current?.play();
		}
	}, [isRecording]);

	return (
		<IconButton
			onClick={onClick}
			sx={{
				width: "fit-content",
				borderRadius: "50%",
			}}
		>
			<Player
				ref={playerRef}
				icon={MICROPHONE}
				state={state}
				size={50}
				colorize={themeObj.primary}
				onComplete={() => { if (state === 'loop-recording') playerRef.current?.playFromBeginning() }}
			/>
		</IconButton>
	);
}
