import { useState, useEffect, useRef } from 'react';
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

const useAudioRecorder = () => {
	const [isRecording, setIsRecording] = useState(false);
	const [recordingBlob, setRecordingBlob] = useState(null);
	const mediaRecorderRef = useRef(null);
	const streamRef = useRef(null);
	const audioChunksRef = useRef([]);

	const startRecording = async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			const mediaRecorder = new MediaRecorder(stream, { audioBitsPerSecond: 16000, mimeType: 'audio/wav' });

			streamRef.current = stream;

			mediaRecorder.ondataavailable = (event) => {
				audioChunksRef.current.push(event.data);
			};
			mediaRecorder.onstop = () => {
				const blob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
				setRecordingBlob(blob);
				audioChunksRef.current = [];
			};

			mediaRecorderRef.current = mediaRecorder;
			mediaRecorderRef.current.start();
			setIsRecording(true);
			setTimeout(() => {
				try {
					mediaRecorderRef.current.stop();
					[...streamRef.current.getTracks()].forEach(track => track.stop());
					setIsRecording(false);
				} catch (err) {
					console.log(err);
				}
			}, 60 * 1000);
		} catch (err) {
			console.error('Error accessing microphone', err);
		}
	};

	const stopRecording = () => {
		if (mediaRecorderRef.current && isRecording) {
			console.log(streamRef.current.getTracks());
			mediaRecorderRef.current.stop();
			[...streamRef.current.getTracks()].forEach(track => track.stop());
			setIsRecording(false);
		}
	};

	useEffect(() => {
		const registerEncoder = async () => {
			try {
				await register(await connect());
			} catch (err) {
				console.error('Error registering encoder', err);
			}
		};

		registerEncoder();

		return () => {
			if (streamRef.current) {
				streamRef.current.getTracks().forEach(track => track.stop());
			}
		};
	}, []);

	return {
		isRecording,
		startRecording,
		stopRecording,
		recordingBlob
	};
};

export default useAudioRecorder;
